import { DatePipe, TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { BankAccountPaymentItem } from '../../../../../../billing/payment-preferences/shared/models/bankaccount-item.model';
import { PaymentItem } from '../../../../../../billing/payment-preferences/shared/models/payment-item.model';
import { IsbSharedCommonHelperService } from '../../../../../../shared/isb-shared-common-helper.service';
import { BankcardRecapFormField } from '../../../../../../shared/payments/bankcard-recap-form-field/bankcard-recap-form-field.component';
import { CardType } from '../../../../../../shared/payments/card-utils/card-type.enum';
import { PaymentFormControls } from '../../../../../../shared/payments/payment-form-controls';
import { PaymentFormGroup } from '../../../../../../shared/payments/payment-form-group';
import { RecapFormField } from '../../../../../../shared/payments/recap-form-field/recap-form-field.component';
import { SessionService } from '../../../../../../shared/session/session.service';
import { SetupAutomaticPaymentsFlowService } from '../services/setup-automatic-payments-flow.service';
import { BankCardPaymentItem } from '../../../../shared/models/bankcard-item.model';
@Injectable()
export class SetupAutomaticPaymentsReview {
    considerThisDisplayable: boolean;

    // eslint-disable-next-line max-params
    constructor(
        private setupAutomaticPaymentsFlowService: SetupAutomaticPaymentsFlowService,
        private titleCasePipe: TitleCasePipe,
        private paymentFormControls: PaymentFormControls,
        private datePipe: DatePipe,
        private session: SessionService,
        private readonly isbSharedCommonHelperService: IsbSharedCommonHelperService
    ) { }

    initialize(): void {
        if (this.setupAutomaticPaymentsFlowService.flowInPlay) {
            this.considerThisDisplayable = this.setupAutomaticPaymentsFlowService.considerThisDisplayable;
            this.addEmailControlToForm();
            this.addEzPayAuthGroup();
        }
    }

    addEmailControlToForm(): void {
        if (this.setupAutomaticPaymentsFlowService.emailAddress) {
            this.setUpAutomaticPaymentsForm.group.addControl(
                'emailAddressGroup',
                this.paymentFormControls.newEmailFormGroup(this.setupAutomaticPaymentsFlowService.emailAddress)
            );
        }
    }

    addEzPayAuthGroup(): void {
        this.setUpAutomaticPaymentsForm.group.addControl(
            'ezPayAuthGroup',
            this.paymentFormControls.newEzPayAuthGroup()
        );
    }

    save(params: Partial<SetupAutomaticPaymentsFlowService>): void {
        this.setupAutomaticPaymentsFlowService.save(params);
    }

    get addEzPayAuthGroupControls(): AbstractControl {
        return this.setUpAutomaticPaymentsForm.group.get('ezPayAuthGroup');
    }

    get selectedPayMethod(): PaymentItem {
        return this.setupAutomaticPaymentsFlowService.selectedPayMethod;
    }

    get setUpAutomaticPaymentsForm(): PaymentFormGroup {
        return this.setupAutomaticPaymentsFlowService.setUpAutomaticPaymentsForm;
    }

    get firstName(): string {
        let firstName = '';
        if (this.setupAutomaticPaymentsFlowService.selectedPayMethod.isBankAccount) {
            firstName = (<BankAccountPaymentItem> this.setupAutomaticPaymentsFlowService.selectedPayMethod.item).firstName;
        } else if (this.setupAutomaticPaymentsFlowService.selectedPayMethod.isBankCard) {
            firstName = (<BankCardPaymentItem> this.setupAutomaticPaymentsFlowService.selectedPayMethod.item).firstName;
        }

        return firstName;
    }

    get middleInitial(): string {
        let middleInitial = '';
        if (this.setupAutomaticPaymentsFlowService.selectedPayMethod.isBankAccount) {
            middleInitial = (<BankAccountPaymentItem> this.setupAutomaticPaymentsFlowService.selectedPayMethod.item).middleInitial;
        } else if (this.setupAutomaticPaymentsFlowService.selectedPayMethod.isBankCard) {
            middleInitial = (<BankCardPaymentItem> this.setupAutomaticPaymentsFlowService.selectedPayMethod.item).middleInitial;
        }
        return middleInitial;
    }

    get lastName(): string {
        let lastName = '';
        if (this.setupAutomaticPaymentsFlowService.selectedPayMethod.isBankAccount) {
            lastName = (<BankAccountPaymentItem> this.setupAutomaticPaymentsFlowService.selectedPayMethod.item).lastName;
        } else if (this.setupAutomaticPaymentsFlowService.selectedPayMethod.isBankCard) {
            lastName = (<BankCardPaymentItem> this.setupAutomaticPaymentsFlowService.selectedPayMethod.item).lastName;
        }
        return lastName;
    }

    get payorName(): string {
        return this.middleInitial ?
            `${this.firstName} ${this.middleInitial} ${this.lastName}` :
            `${this.firstName} ${this.lastName}`;
    }

    get emailAddress(): string {
        return this.enrollingInPaperless === 'Yes' && this.setupAutomaticPaymentsFlowService.paperlessEmail ? this.setupAutomaticPaymentsFlowService.paperlessEmail : this.setUpAutomaticPaymentsForm.emailAddress;
    }

    get defaultPaymentMethod(): string {
        return this.setUpAutomaticPaymentsForm.makeDefaultPaymentMethod ? 'Yes' : 'No';
    }

    get savedCardType(): CardType {
        return <CardType> this.setupAutomaticPaymentsFlowService.selectedPayMethod.methodType;
    }

    get cvvLabel(): string {
        return this.cardType === CardType.AMERICAN_EXPRESS ? 'CID' : 'CVV';
    }

    get maskedCvv(): string {
        return this.cardType === CardType.AMERICAN_EXPRESS ? '****' : '***';
    }

    get cvv(): string {
        return (<BankCardPaymentItem> this.setupAutomaticPaymentsFlowService.selectedPayMethod.item).cvv;
    }

    get cardType(): CardType {
        return (<BankCardPaymentItem> this.setupAutomaticPaymentsFlowService.selectedPayMethod.item).cardType;
    }

    get bankCardNumber(): string {
        return (<BankCardPaymentItem> this.setupAutomaticPaymentsFlowService.selectedPayMethod.item).maskedCardNumber;
    }

    get cardNumberLastFour(): string {
        return (<BankCardPaymentItem> this.setupAutomaticPaymentsFlowService.selectedPayMethod.item).cardNumberLastFour;
    }

    get isNewPayMethod(): boolean {
        return this.setupAutomaticPaymentsFlowService.selectedPayMethod.isNewMethod;
    }

    get bankName(): string {
        return (<BankAccountPaymentItem> this.setupAutomaticPaymentsFlowService.selectedPayMethod.item).bankName;
    }

    get encryptedBankAccountNumber(): string {
        return this.selectedPayMethod.item instanceof BankAccountPaymentItem ? this.selectedPayMethod.item.encryptedAccountNumber : '';
    }

    get billingAccountHolderName(): string {
        return this.titleCasePipe.transform(this.setupAutomaticPaymentsFlowService.billingAccountHolderName);
    }

    get billingAccountNumber(): string {
        return this.setupAutomaticPaymentsFlowService.billingAccountNumber;
    }

    get nbpEffectiveDate(): string {
        let nextBill = '';
        if (this.session.isNBP) {
            if (this.setupAutomaticPaymentsFlowService.isLifeOnlyAccount) {
                nextBill = 'Next Bill';
            } else {
                nextBill = this.setupAutomaticPaymentsFlowService.nextBillDueDate ? this.datePipe.transform(this.setupAutomaticPaymentsFlowService.nextBillDueDate, 'MM/dd/yyyy') : 'Next Bill';
            }
        }
        return nextBill;
    }

    get effectiveDate(): string {
        return this.setupAutomaticPaymentsFlowService.billedStatus ? 'Current Bill' : 'Next Bill';
    }

    get billingCycleDueDate(): string {
        return this.datePipe.transform(this.setupAutomaticPaymentsFlowService.currentBillDueDate, 'MM/dd/yyyy');
    }

    get enrollingInEzPay(): string {
        const enrollInEasyPay = this.setUpAutomaticPaymentsForm.enrollInEasyPay;
        return enrollInEasyPay && this.titleCasePipe.transform(enrollInEasyPay) || 'No';
    }

    get enrollingInPaperless(): string {
        const enrollInPaperless = this.setUpAutomaticPaymentsForm.enrollInPaperless;
        return enrollInPaperless && this.titleCasePipe.transform(enrollInPaperless);
    }

    get considerThisReviewFields(): RecapFormField[] {
        const fieldsArray = [];
        const easyPay = {
            id: 'enroll-in-ez-pay-recap',
            label: 'Would you like to enroll in Automatic payments?',
            value: this.enrollingInEzPay
        };
        const paperLess = {
            id: 'enroll-in-paperless-recap',
            label: 'Would you like to enroll in paperless for all of your accounts and policies?',
            value: this.enrollingInPaperless
        };

        if (this.setupAutomaticPaymentsFlowService.isEligibleForEasyPay && this.setupAutomaticPaymentsFlowService.isPaperlessEligible) {
            fieldsArray.push(easyPay, paperLess);
        } else if (this.setupAutomaticPaymentsFlowService.isEligibleForEasyPay &&
            !this.setupAutomaticPaymentsFlowService.isPaperlessEligible) {
            fieldsArray.push(easyPay);
        } else if (this.setupAutomaticPaymentsFlowService.isPaperlessEligible) {
            fieldsArray.push(paperLess);
        }
        if (this.enrollingInPaperless === 'Yes') {
            fieldsArray.push({
                id: 'enroll-in-paperless-esdda',
                label: 'Paperless enrollment date',
                value: `${this.isbSharedCommonHelperService.getCurrentDateWithGivenFormatType('MM/dd/yyyy, hh:mm aa ')}ET`
            });
            fieldsArray.push({
                id: 'enroll-in-paperless-esdda',
                label: 'Paperless effective date',
                value: `${this.isbSharedCommonHelperService.getCurrentDateWithGivenFormatType('MM/dd/yyyy, hh:mm aa ')}ET`
            });
            fieldsArray.push({
                id: 'enroll-in-paperless-recap',
                label: 'Paperless and automatic payments email',
                value: this.setupAutomaticPaymentsFlowService.paperlessEmail || this.setupAutomaticPaymentsFlowService.emailAddress
            });
        }
        return fieldsArray;
    }

    get isBankAccountFlow(): boolean {
        return this.setupAutomaticPaymentsFlowService.selectedPayMethod.isBankAccount;
    }

    get isBankCardFlow(): boolean {
        return this.setupAutomaticPaymentsFlowService.selectedPayMethod.isBankCard;
    }

    get paymentMethodId(): string {
        return this.setupAutomaticPaymentsFlowService.selectedPayMethod.id;
    }

    get accountType(): string {
        return (<BankAccountPaymentItem> this.setupAutomaticPaymentsFlowService.selectedPayMethod.item).accountType;
    }

    get street(): string {
        let street = '';
        if (this.setupAutomaticPaymentsFlowService.selectedPayMethod.isBankAccount) {
            street = (<BankAccountPaymentItem> this.setupAutomaticPaymentsFlowService.selectedPayMethod.item).street;
        } else {
            street = this.setupAutomaticPaymentsFlowService.billingStreetLine1;
        }
        return street;
    }

    get apartmentNumber(): string {
        let apartmentNumber = '';
        apartmentNumber = (<BankAccountPaymentItem> this.setupAutomaticPaymentsFlowService.selectedPayMethod.item).apartmentNumber;
        return apartmentNumber;
    }

    get city(): string {
        let city = '';
        if (this.setupAutomaticPaymentsFlowService.selectedPayMethod.isBankAccount) {
            city = (<BankAccountPaymentItem> this.setupAutomaticPaymentsFlowService.selectedPayMethod.item).city;
        } else {
            city = this.setupAutomaticPaymentsFlowService.billingCity;
        }

        return city;
    }

    get zip(): string {
        let zip = '';
        if (this.setupAutomaticPaymentsFlowService.selectedPayMethod.isBankAccount) {
            zip = (<BankAccountPaymentItem> this.setupAutomaticPaymentsFlowService.selectedPayMethod.item).zip;
        } else if (this.setupAutomaticPaymentsFlowService.selectedPayMethod.isBankCard) {
            zip = (<BankCardPaymentItem> this.setupAutomaticPaymentsFlowService.selectedPayMethod.item).zipCode;
        }

        return zip;
    }

    get stateName(): string {
        return this.setUpAutomaticPaymentsForm.state || this.setupAutomaticPaymentsFlowService.billingState;
    }

    get routingNumber(): string {
        return (<BankAccountPaymentItem> this.setupAutomaticPaymentsFlowService.selectedPayMethod.item).routingNumber;
    }

    get maskedBankAccountNumber(): string {
        return (<BankAccountPaymentItem> this.setupAutomaticPaymentsFlowService.selectedPayMethod.item).maskedBankAccountNumber;
    }

    get bankAccountNumber(): string {
        return this.encryptedBankAccountNumber;
    }

    get bankAccountType(): string {
        return (<BankAccountPaymentItem> this.setupAutomaticPaymentsFlowService.selectedPayMethod.item).accountType;
    }

    get currentDate(): string {
        return this.datePipe.transform(new Date(), 'MM/dd/yyyy');
    }

    get cardExpirationMonth(): string {
        const expiryMonth = (<BankCardPaymentItem> this.setupAutomaticPaymentsFlowService.selectedPayMethod.item).cardExpiration.getMonth() + 1;
        const expiryMonthString = expiryMonth < 10 ? `0${expiryMonth.toString()}` : expiryMonth.toString();
        return expiryMonthString;
    }

    get cardExpirationYear(): string {
        return (<BankCardPaymentItem> this.setupAutomaticPaymentsFlowService.selectedPayMethod.item).cardExpiration.getFullYear().toString();
    }

    get cardExpirationDate(): string {
        return `${this.cardExpirationMonth}/${this.cardExpirationYear}`;
    }

    get paymentDetailsReviewFields(): RecapFormField[] | BankcardRecapFormField[] {
        const fields = [];

        if (this.isBankAccountFlow) {
            const selectedPayMethod: BankAccountPaymentItem = <BankAccountPaymentItem> this.setupAutomaticPaymentsFlowService.selectedPayMethod.item;

            fields.push({
                id: 'account-holders-name-recap',
                label: 'Account holder\'s name',
                value: this.payorName
            });

            fields.push({
                id: 'billing-account-number',
                label: 'Billing account number',
                value: this.billingAccountNumber
            });

            fields.push({
                id: 'transaction-authorized-by',
                label: 'Transaction authorized by',
                value: this.payorName
            });

            fields.push({
                id: 'enrollment-date',
                label: 'Enrollment date',
                value: this.currentDate
            });

            fields.push({
                id: 'payment-effective-date',
                label: 'Automatic payment effective date',
                value: this.session.isNBP ? this.nbpEffectiveDate : this.effectiveDate
            });

            fields.push({
                id: 'payment-method',
                label: 'Payment method',
                value: 'Bank account'
            });

            fields.push({
                id: 'account-type-recap',
                label: 'Account type',
                value: selectedPayMethod.accountType
            });

            fields.push({
                id: 'bank-routing-number-recap',
                label: 'Bank routing number',
                value: selectedPayMethod.routingNumber
            });

            fields.push({
                id: 'bank-name-recap',
                label: 'Bank name',
                value: selectedPayMethod.bankName
            });

            fields.push({
                id: 'account-number-recap',
                label: 'Account number',
                value: `*********${this.maskedBankAccountNumber}`
            });
        }

        if (this.isBankCardFlow) {
            const selectedPayMethod: BankCardPaymentItem = <BankCardPaymentItem> this.setupAutomaticPaymentsFlowService.selectedPayMethod.item;

            fields.push({
                id: 'enrollment-date',
                label: 'Enrollment date',
                value: this.currentDate
            });

            fields.push({
                id: 'payment-effective-date',
                label: 'Automatic payment effective date',
                value: this.session.isNBP ? this.nbpEffectiveDate : this.effectiveDate
            });

            fields.push({
                id: 'card-holder-name-recap',
                label: 'Card holder\'s name',
                value: this.payorName
            });

            fields.push({
                id: 'zip-recap',
                label: 'ZIP',
                value: this.zip
            });

            fields.push({
                id: 'card-number-recap',
                label: 'Card number',
                value: `****************${selectedPayMethod.cardNumberLastFour}`,
                cardType: this.isNewPayMethod ? this.cardType : this.savedCardType
            });

            fields.push({
                id: 'expiration-date-recap',
                label: 'Expiration date',
                value: this.cardExpirationDate
            });
        }

        return fields;
    }
}
