// TODO: This messaging is being added to Tridion and will need removed once the addition is complete.

export const WARNING_MESSAGES = {
    API_TIMEOUT: `This feature is temporarily unavailable. Please
  try again later. For immediate assistance please contact
  your agent or call 1-877-669-6877.
  Representatives are available Monday through
  Friday from 8AM to 9PM ET.`,

    API_FAILURE: 'We are unable to process your request. Please contact' +
        ' your local Nationwide Agent or call 1-877-669-6877. Representatives' +
        ' are available Monday through Friday from 8AM to 9PM ET.',

    WARNED_INELIGIBLE_EASYPAY: `Automatic payments cannot be set up at this time. Please check back later.
  If you need immediate assistance, please contact your local agent or call 1-877-669-6877.
  Representatives are available Monday through Friday from 8AM to 9PM ET.`,

    API_FAILURE_SHORT: 'We are unable to process your request. Please try again later.',
    REMOVE_EASYPAY_SAVED_PAYMENT_METHOD: `This payment method cannot be removed, it is attached
  to an automatic payment. To remove this payment method please update your automatic payments payment method.`,

    EZ_PAY_ENROLLED_CHANGES_CANNOT_BE_MADE: `Changes to Automatic Payments (Easy Pay) cannot be made at this time. Please check back after your payment due date.
  If you need immediate assistance, please contact your local agent or call 1-877-669-6877.`,

    GENERIC_417_ERROR: `We are having an issue processing your request with this payment method at this time.
        Please select another payment method and try again.`
};
