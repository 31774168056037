/* eslint-disable max-params */
/* eslint-disable prefer-template */
import { OAuthConfig } from '@nationwide/angular-oauth-module';
import { TridionDownConfig } from '../app/error/tridion-down/tridion-down.config';
import { BillingTab, BillingTabIcons } from '../app/shared/billing-tabs/billing-tab.model';
import { SECONDS_IN_MINUTE } from '../app/shared/constants/common.constants';
import { ISBRouteParams } from '../app/shared/routing/isb-route.model';
import { ANIMATIONS } from './animations';

const PRODUCTION = false;

const CLIENT_ID = 'c68fN5C5AGkQlysNOCLuzGGv0dvmAVD3';

const MAKE_A_PAYMENT = true;

const APP_COOKIE = 'testuser';

const NW_APP_ID = 'NW-CSS';

const TRANSACTION_ID = 'X-Nw-Transaction-Id';

const LOG_OUT_URL = 'https://st-myaccount.nationwide.nwie.net/myaccount/Logout.action';
const CONNECTION_TYPE = 'CIB1';
const IS_CONNECTION_TYPE_REQUIRED = true;

const questionsAndTipsPath = '/personal/billingMigration/questions-and-tips';
const billsAndPaymentsPath = '/personal/billingMigration/bills-and-payments';
const paymentPreferencesPath = '/personal/billingMigration/payment-preferences';
const billingDetailsPath = '/personal/billing/details';
const payBillLandingPath = '/personal/pay-bill/landing';
const payBillReviewPath = '/personal/pay-bill/review';
const payBillConfirmationPath = '/personal/pay-bill/confirmation';
const changeDueDatePath = '/personal/change-due-date';
const reviewDueDatePath = '/personal/change-due-date/review-due-date';
const submitDueDatePath = '/personal/change-due-date/confirmation';

const quickPayLandingPath = '/personal/quickpay/landing';
const quickPayReviewPath = '/personal/quickpay/review';
const quickPayConfirmationPath = '/personal/quickpay/confirmation';
const quickPaySearchPath = '/personal/quickpay/search';
const quickPayPickerPath = '/personal/quickpay/picker';
const billingTimelinePath = '/personal/billing/timeline';

const CYBERLIFE_QUICKPAY_ENABLED = true;

const NATIONWIDE_HOMEPAGE = 'https://www.nationwide.com';

const ADOBE_MBOXES = {
    PAPERLESS_BILLING: 'ab-paperless-billing-mbox',
    INCREASE_OVERPAYMENT: 'ab-increase-overpayment-mbox',
    PAYMENT_HISTORY: 'ab-add-payment-history-to-map',
    CONVERT_QUICKPAY_TO_REFT_V2: 'ab-convert-quickpay-reft-v2'
};

const DPIM_AGENCY_INFORMATION_URL_STATIC_PARAMS = `legacyIdentifierType=Legacy Individual Role Number&legacyAdministrativeSystem=AIMS`;

const QUERY_PARAMS = {
    userId: 'userId',
    billingAccount: 'bkey',
    policy: 'pkey',
    a2aSessionId: 'myAccountUserSessionId',
    currentBillPdfViewable: 'current-bill-pdf-viewable',
    referringPage: 'referringPage',
    appName: 'appName',
    guid: 'guid'
};

const SESSION_STORAGE = {
    BILLING_ACCOUNT: 'bkey',
    A2A_SESSION_ID: 'a2aSessionId',
    ACCESS_TOKEN: 'accessToken',
    JWT: 'jsonWebToken',
    CLEAR_TRUST: 'clearTrustSessionStorage',
    QUICK_PAY_PBS_RESPONSE: 'pbsResponse'
};

const DOCUMENTS = {
    apigeeEnvironment: 'https://api-test.nwie.net/',
    mobiusEndpoint: 'communication/mobiusdocumentmanagement/v1/documents/',
    repoId: 'RDCPROD',
    docusignApiEndpoint: 'policy-management/servicing-docusign-experience/v1/',
    filenetEndpoint: 'document-repository/filenetdocumentmanagement/v1/documents/',
    consumerId: 'ESIG',
    search: '#documents',
    documentOpenerEndpoint: 'policymanagement/open-documents/v1/document'
};

export function isbQueryParams(params: ISBRouteParams): string {
    return `?bkey=${params.accountNumber}` +
        `&myAccountUserSessionId=${params.sessionId}` +
        (params.policyNumber ? '&pkey=' + params.policyNumber : '') +
        (params.isCyberLife ? '&sourceSystem=cyberlife' : '') +
        (params.referringPage ? '&referringPage=review' : '') +
        (params.isPayMidTermChange ? '&payMidTermChange=true' : '') +
        (params.contextId ? '&contextId=' + params.contextId : '');
}

export function isbQuickPayQueryParams(params: ISBRouteParams): string {
    return params.referringPage ? `?referringPage=${params.referringPage}` : '';
}

const ISB = {
    HOST_URL: 'https://isb.t.nationwide.com',
    ENDPOINTS: {
        makePayment: (accountNumber, sessionId): string => `${ISB.HOST_URL}/#/personal/pay-bill/landing?bkey=${accountNumber}&myAccountUserSessionId=${sessionId}&current-bill-pdf-viewable=false`,
        makePaymentPolicylevel: (accountNumber, policyNumber, sessionId): string => `${ISB.HOST_URL}/#/personal/pay-bill/landing?bkey=${accountNumber}&myAccountUserSessionId=${sessionId}&pkey=${policyNumber}&current-bill-pdf-viewable=false`,
        billsAndPayments: (accountNumber, sessionId): string => `${ISB.HOST_URL}/#/personal/billingMigration/bills-and-payments?bkey=${accountNumber}&myAccountUserSessionId=${sessionId}&current-bill-pdf-viewable=false`,
        billingTimeline: (accountNumber, sessionId): string => `${ISB.HOST_URL}/#/personal/billing/timeline?bkey=${accountNumber}&myAccountUserSessionId=${sessionId}&current-bill-pdf-viewable=false`,
        RELATIVE: {
            billingDetails: (params: ISBRouteParams = {}): string => `${billingDetailsPath}${isbQueryParams(params)}`,
            billsAndPayments: (params: ISBRouteParams = {}): string => `${billsAndPaymentsPath}${isbQueryParams(params)}`,
            billingTimeline: (params: ISBRouteParams = {}): string => `${billingTimelinePath}${isbQueryParams(params)}`,
            payBillLanding: (params: ISBRouteParams = {}): string => `${payBillLandingPath}${isbQueryParams(params)}`,
            payBillReview: (params: ISBRouteParams = {}): string => `${payBillReviewPath}${isbQueryParams(params)}`,
            payBillConfirmation: (params: ISBRouteParams = {}): string => `${payBillConfirmationPath}${isbQueryParams(params)}`,
            paymentPreferences: (params: ISBRouteParams): string => `${paymentPreferencesPath}${isbQueryParams(params)}`,
            changeDueDate: (params: ISBRouteParams = {}): string => `${changeDueDatePath}${isbQueryParams(params)}`,
            reviewDueDate: (params: ISBRouteParams = {}): string => `${reviewDueDatePath}${isbQueryParams(params)}`,
            submitDueDate: (params: ISBRouteParams = {}): string => `${submitDueDatePath}${isbQueryParams(params)}`
        },
        QUICK_PAY: {
            quickPaySearch: (params: ISBRouteParams = {}): string => `${quickPaySearchPath}${isbQuickPayQueryParams(params)}`,
            quickPayPicker: (params: ISBRouteParams = {}): string => `${quickPayPickerPath}${isbQuickPayQueryParams(params)}`,
            quickPayLanding: (params: ISBRouteParams = {}): string => `${quickPayLandingPath}${isbQuickPayQueryParams(params)}`,
            quickPayReview: (params: ISBRouteParams = {}): string => `${quickPayReviewPath}${isbQuickPayQueryParams(params)}`,
            quickPayConfirmation: (params: ISBRouteParams = {}): string => `${quickPayConfirmationPath}${isbQuickPayQueryParams(params)}`
        },
        MANAGE_PAYMENT_PREFERENCES: {
            paymentPreferences: (params: string): string => `${paymentPreferencesPath}?${params}`,
            AUTOMATIC_PAYMENTS: {
                SETUP: {
                    LANDING: (params: string): string => `${paymentPreferencesPath}/manage/automatic-payments/setup/landing?${params}`,
                    REVIEW: (params: string): string => `${paymentPreferencesPath}/manage/automatic-payments/setup/review?${params}`,
                    CONFIRMATION: (params: string): string => `${paymentPreferencesPath}/manage/automatic-payments/setup/confirmation?${params}`
                },
                EDIT: {
                    LANDING: (params: string): string => `${paymentPreferencesPath}/manage/automatic-payments/edit/landing?${params}`,
                    REVIEW: (params: string): string => `${paymentPreferencesPath}/manage/automatic-payments/edit/review?${params}`,
                    CONFIRMATION: (params: string): string => `${paymentPreferencesPath}/manage/automatic-payments/edit/confirmation?${params}`
                },
                CANCEL: {
                    LANDING: (params: string): string => `${paymentPreferencesPath}/manage/automatic-payments/cancel/landing?${params}`,
                    CONFIRMATION: (params: string): string => `${paymentPreferencesPath}/manage/automatic-payments/cancel/confirmation?${params}`
                },
                SUSPEND: {
                    LANDING: (params: string): string => `${paymentPreferencesPath}/manage/automatic-payments/suspend/landing?${params}`,
                    CONFIRMATION: (params: string): string => `${paymentPreferencesPath}/manage/automatic-payments/suspend/confirmation?${params}`
                }
            },
            PAY_PLAN: {
                EDIT: {
                    LANDING: (params: string): string => `${paymentPreferencesPath}/manage/payment-plan/edit/landing?${params}`,
                    CONFIRMATION: (params: string): string => `${paymentPreferencesPath}/manage/payment-plan/edit/confirmation?${params}`
                }
            },
            SCHEDULED_FUTURE_PAYMENTS: {
                CANCEL: {
                    LANDING: (params: string): string => `${paymentPreferencesPath}/manage/scheduled-future-payments/cancel/landing?${params}`,
                    CONFIRMATION: (params: string): string => `${paymentPreferencesPath}/manage/scheduled-future-payments/cancel/confirmation?${params}`
                }
            },
            SAVED_REFUND_METHOD: {
                ADD: {
                    LANDING: (params: string): string => `${paymentPreferencesPath}/manage/refund-method/add/landing?${params}`,
                    REVIEW: (params: string): string => `${paymentPreferencesPath}/manage/refund-method/add/review?${params}`,
                    CONFIRMATION: (params: string): string => `${paymentPreferencesPath}/manage/refund-method/add/confirmation?${params}`
                },
                REMOVE: {
                    LANDING: (params: string): string => `${paymentPreferencesPath}/manage/refund-method/remove/landing?${params}`,
                    CONFIRMATION: (params: string): string => `${paymentPreferencesPath}/manage/refund-method/remove/confirmation?${params}`
                }
            }
        }
    }
};

const contentful = {
    space: 'nqy1g7ce3sxy',
    environment: 'test',
    accessToken: '5d80RdneQMwypIVJtYaQHwGOK9CMpfMxHjDUt74NIGQ',
    contentfulDeliveryAccessToken: '5d80RdneQMwypIVJtYaQHwGOK9CMpfMxHjDUt74NIGQ',
    contentfulPreviewAccessToken: 'oI1UTuGQfqj9bjpRIler8V86EPLMtMw2mjCUgFxVOAE'
};

const CIAM = {
    ENDPOINTS: {
        individualInformationPage: 'https://d1wr5kqdpdd8qz.cloudfront.net/registration/search/account-type?type=individual',
        personalRegistrationMfa: (contextId: string, otp: string): string => `https://d1wr5kqdpdd8qz.cloudfront.net/authorization/authorize?` +
            `systemId=ISBQuickPay&destination=personalRegMfa&method=context-cache&contextId=${contextId}&otp=${otp}`
    }
};

const ISC = {
    ENDPOINTS: {
        iscLandingUrl: (contextId: string): string => `https://dev-myplan.aws.e2.nwie.net/iApp/isc/app/deepLinkAuthorization.action?context_id=${contextId}`
    }
};

const PAYMENT_PREFERENCES = {
    ENDPOINT: {
        paymentPreferencesUrl: (accountNumber, sessionId): string => `/#/personal/billingMigration/payment-preferences?bkey=${accountNumber}&myAccountUserSessionId=${sessionId}`
    }
};

const CAM_LINK = 'https://st-myaccount.nationwide.nwie.net/myaccount/index.jsp';

const MAKE_PAYMENT_URL = {
    accountLevel: ISB.ENDPOINTS.makePayment,
    policyLevel: ISB.ENDPOINTS.makePaymentPolicylevel
};

const SMART_MILES = {
    ENDPOINTS: {
        TOKEN: (ctToken: string): string =>
            `https://srest.nwie.net/smartmiles?NWIESESSION=${ctToken}`
    }
};
const PERSONAL_LINES_BILLING_API_HOST = 'https://api-test.nwie.net';
const MONEY_PROCESSING_ROOT = 'https://api-test.nwie.net/billing-collections/money-processing/v1';
const MONEY_PROCESSING_PCI_ROOT = 'https://apipci-test.nwie.net/billing-collections/money-processing/v1';
const PERSONAL_BILLING_ROOT = '/billing-collections/personal-lines-billing/v2';

const BILLING = {
    PATHS: {
        agreement: (guid): string => `${PERSONAL_BILLING_ROOT}/billing-accounts/${guid}/prefill`,
        bills: (account): string => `${PERSONAL_BILLING_ROOT}/billing-accounts/${account}/bills`,
        relatedPolicies: (account): string => `${PERSONAL_BILLING_ROOT}/billing-accounts/${account}/related-policies`,
        policiesEvents: (account): string => `${PERSONAL_BILLING_ROOT}/billing-accounts/${account}/policy-events`,
        transactions: (account): string => `${PERSONAL_BILLING_ROOT}/billing-accounts/${account}/transactions`,
        billingAccount: (account): string => `${PERSONAL_BILLING_ROOT}/billing-accounts/${account}`,
        payPlan: (account, method): string => `${PERSONAL_BILLING_ROOT}/billing-accounts/${account}/pay-plans/current?request_type=${method}`,
        zeroAmountDue: (account): string => `${PERSONAL_BILLING_ROOT}/billing-accounts/${account}/balances`,
        patchPayPlan: (account): string => `${PERSONAL_BILLING_ROOT}/billing-accounts/${account}/pay-plans/current`,
        billCalculationInquiry: (account): string => `${PERSONAL_BILLING_ROOT}/billing-accounts/${account}/$metadata/bill-calculator?requestMethod=inquiry`,
        billingDueDates: (account): string => `${PERSONAL_BILLING_ROOT}/billing-accounts/${account}/$metadata/due-dates`,
        refundStatuses: (account, method): string => `${PERSONAL_BILLING_ROOT}/billing-accounts/${account}/refunds?refundMethod=${method}`
    },
    ENDPOINTS: {
        savedPaymentMethodsUrl: (ecn): string => `${MONEY_PROCESSING_ROOT}/saved-payment-methods?enterpriseCustomerNumber=${ecn}`,

        singleSavedPaymentMethodUrl: (payMethodId: string): string => `${MONEY_PROCESSING_ROOT}/saved-payment-methods/${payMethodId}`,

        saveNewPayMethodUrl: (ecn, accountNumber): string => `${MONEY_PROCESSING_ROOT}/saved-payment-methods?enterpriseCustomerNumber=${ecn}&billingAccountNumber=${accountNumber}`,

        retrieveBank: (routingNumber): string => `${MONEY_PROCESSING_ROOT}/banks/${routingNumber}`,

        money_processing_personal_payments_url: (account): string => `${MONEY_PROCESSING_PCI_ROOT}/personal-lines-accounts/${account}/payments`,

        updatePayMethodUrl: (payMethodId: string): string => `${MONEY_PROCESSING_ROOT}/saved-payment-methods/${payMethodId}`,

        billingAccountPaymentMethodUrl: (accountNumber): string => `${MONEY_PROCESSING_PCI_ROOT}/personal-lines-accounts/${accountNumber}/billing-account-paymentmethod`,

        bills_url: (account): string => {
            // TODO: Revert this back to normal bills url for monitoring account once billing makes fix for billType=FUTURE
            const monitoringAccountNumber = '9278642348';
            let url = `${PERSONAL_LINES_BILLING_API_HOST}${BILLING.PATHS.bills(account)}`;
            if (account === monitoringAccountNumber) {
                url += '?billType=PRIOR';
            }
            return url;
        },

        related_policies: (account): string => `${PERSONAL_LINES_BILLING_API_HOST}${PERSONAL_BILLING_ROOT}/billing-accounts/${account}/related-policies`,

        policies_events: (account): string => `${PERSONAL_LINES_BILLING_API_HOST}${PERSONAL_BILLING_ROOT}/billing-accounts/${account}/policy-events`,

        transactions: (account): string => `${PERSONAL_LINES_BILLING_API_HOST}${PERSONAL_BILLING_ROOT}/billing-accounts/${account}/transactions`,

        billing_account: (account): string => `${PERSONAL_LINES_BILLING_API_HOST}${PERSONAL_BILLING_ROOT}/billing-accounts/${account}`,

        payPlanUrl: (account, method): string => `${PERSONAL_LINES_BILLING_API_HOST}${PERSONAL_BILLING_ROOT}/billing-accounts/${account}/pay-plans/current?request_type=${method}`,

        eligiblePayPlanUrl: (account): string => `${PERSONAL_LINES_BILLING_API_HOST}${PERSONAL_BILLING_ROOT}/billing-accounts/${account}/eligible-payplan`,

        zero_amount_due_url: (account): string => `${PERSONAL_LINES_BILLING_API_HOST}${PERSONAL_BILLING_ROOT}/billing-accounts/${account}/balances`,

        patch_pay_plan: (account): string => `${PERSONAL_LINES_BILLING_API_HOST}${PERSONAL_BILLING_ROOT}/billing-accounts/${account}/pay-plans/current`,

        refund_statuses: (account, method): string => `${PERSONAL_LINES_BILLING_API_HOST}${PERSONAL_BILLING_ROOT}/billing-accounts/${account}/refunds?refundMethod=${method}`,

        retrieve_due_dates: (account): string => `${PERSONAL_LINES_BILLING_API_HOST}${PERSONAL_BILLING_ROOT}/billing-accounts/${account}/$metadata/due-dates`,
        update_due_dates: (account): string => `${PERSONAL_LINES_BILLING_API_HOST}${PERSONAL_BILLING_ROOT}/billing-accounts/${account}`,
        refunds: `https://api-test.nwie.net/billing-collections/covid19refunds/v1/refunds/me `,
        bill_calculation_inquiry: (account): string => `${PERSONAL_LINES_BILLING_API_HOST}${PERSONAL_BILLING_ROOT}/billing-accounts/${account}/$metadata/bill-calculator?requestMethod=inquiry`,
        retrieve_customer_billing_accounts_url: (ecn): string => `${PERSONAL_LINES_BILLING_API_HOST}${PERSONAL_BILLING_ROOT}/customer-billing-accounts/${ecn}`
    },

    REGION: 'B1'
};

const MASTERPASS_ENABLED = true;
const MASTERPASS_ROOT = 'https://sandbox.api.mastercard.com/masterpass';
const MASTERPASS_ISP_ROOT = 'https://api-dev.nwie.net/policymanagement/internetservicing-account/v1';
const WALLETPAY = {
    ENDPOINTS: {
        masterpassEncryptDataUrl: (account, checkoutId, cartId): string => `${MASTERPASS_ROOT}/encrypted-paymentdata/${account}?checkoutId=${checkoutId}&cartId=${cartId}`,
        masterpassPostbackUrl: (): string => `${MASTERPASS_ROOT}/postback`,
        masterpassISPEncryptDataUrl: (clientId): string => `${MASTERPASS_ISP_ROOT}/encryptedPaymentData-secure?client_id=${clientId}`,
        masterpassISPPostbackUrl: (clientId): string => `${MASTERPASS_ISP_ROOT}/postback-secure?client_id=${clientId}`
    }
};

const ECIM_ROOT = 'https://api-test.nwie.net/customer-information-management/enterprise-customer/v1';
const ECIM = {
    DEPTH: '1',
    URL: {
        getInternetRegistrationsUrl: (guid: string, clientId: string): string => `${ECIM_ROOT}/internetregistrations/${guid}?apikey=${clientId}`,
        getAgreementUrl: (accountNumber: string, clientId: string, sourceSystem: string): string => `${ECIM_ROOT}/agreements/${sourceSystem}/${accountNumber}?apikey=${clientId}`
    }
};

const PREFERENCECENTER_ROOT = 'https://api-test.nwie.net/customerrelationshipmanagement/preferencesmanagement/v2/customers';
const PREFERENCECENTER = {
    URL: {
        postPreferenceUrl: (ecn: string): string => `${PREFERENCECENTER_ROOT}/${ecn}/preferences`
    }
};

const PBS = {
    ENDPOINTS: {
        RCA: 'https://api-test.nwie.net/accountagreementmanagement/portfoliobusinessservice/v1/customeragreement',
        RCAWR: 'https://api-test.nwie.net/accountagreementmanagement/portfoliobusinessservice/v1/customeragreementwithrefresh',
        RCP: 'https://api-test.nwie.net/accountagreementmanagement/portfoliobusinessservice/v1/customerportfolio',
        SPLIT_PARTY_RCA: 'https://api-test.nwie.net/accountagreementmanagement/portfoliobusinessservice/split-parties/v1/customeragreementwithrefresh',
        SEARCH_AGREEMENT: 'https://api-test.nwie.net/accountagreementmanagement/portfoliobusinessservice/quick-auth/v1/searchAgreement',
        SEARCH_PHONE_NUMBER: 'https://api-test.nwie.net/accountagreementmanagement/portfoliobusinessservice/quick-auth/v1/searchPhone'
    },

    HEADERS: {
        AUTH: `Basic ${CLIENT_ID}`
    },

    VERSION: '4.6'
};

const NFCIM = {
    cimEndpoint: (agreementNumber): string => `https://api-int-test.nwie.net/policy-management/nf-customer-information-management/v1/customerprofiles/${agreementNumber}-CYBERLIFE`,

    HEADERS: {
        AUTH: `Basic ${CLIENT_ID}`
    }
};

// FOLLOWING CONSTANT MUST BE LOWERCASE FOR TRIDION SERVICE TO CONFIGURE
const tridion = {
    endpoint: '/tridion',
    directEndpoint: '/tridion'
};

// FOLLOWING CONSTANT MUST BE LOWERCASE FOR TRIDION SERVICE TO CONFIGURE
const tridionProperties = [
    {
        types: ['billingNavigation', 'policyNavigation'],
        file: '/group2.json'
    },
    {
        types: ['Billing Details', 'BillingQuestionsTipsContainer', 'savedPayMethodToRefundMethod'],
        file: '/group5.json'
    }
];

const BILLING_MIGRATION_TABS = [
    new BillingTab('Billing details',
        ({ accountNumber, sessionId }: ISBRouteParams) =>
            `${billingTimelinePath}?bkey=${accountNumber}&myAccountUserSessionId=${sessionId}&appName=ISB`,
        true,
        BillingTabIcons.BILL
    ),
    new BillingTab(
        'Bills & payments',
        ({ accountNumber, sessionId }: ISBRouteParams) =>
            `${billsAndPaymentsPath}?bkey=${accountNumber}&myAccountUserSessionId=${sessionId}`,
        true,
        BillingTabIcons.DOLLAR_SIGN
    ),
    new BillingTab(
        'Payment preferences',
        ({ accountNumber, sessionId }: ISBRouteParams) =>
            `${paymentPreferencesPath}?bkey=${accountNumber}&myAccountUserSessionId=${sessionId}`
        ,
        true,
        BillingTabIcons.GEARS
    ),
    new BillingTab(
        'Questions & tips',
        ({ accountNumber, sessionId }: ISBRouteParams) =>
            `${questionsAndTipsPath}?bkey=${accountNumber}&myAccountUserSessionId=${sessionId}`,
        true,
        BillingTabIcons.QUESTION_MARK)
];

const NW_NAV_LIFE_LINK = {

    navigationLinksForLife: (routeParams: ISBRouteParams): any => [
        {
            text: 'Bills & Payments',
            url: `${billsAndPaymentsPath}?bkey=${routeParams.accountNumber}&myAccountUserSessionId=${routeParams.sessionId}`
        },
        {
            text: 'Payment Preferences',
            url: `${paymentPreferencesPath}?bkey=${routeParams.accountNumber}&myAccountUserSessionId=${routeParams.sessionId}`
        },
        {
            text: 'Questions & Tips',
            url: `${questionsAndTipsPath}?bkey=${routeParams.accountNumber}&myAccountUserSessionId=${routeParams.sessionId}`
        },
        {
            text: 'Contact Us',
            url: 'https://st-myaccount.nationwide.nwie.net/myaccount/portfolio/RetrieveContactDirectory.action'
        }
    ],
    navigationLinksForCyberLife: (routeParams: ISBRouteParams): any => [
        {
            text: 'Contact Us',
            url: 'https://st-myaccount.nationwide.nwie.net/myaccount/portfolio/RetrieveContactDirectory.action'
        }
    ]
};

const MAKE_PAYMENT_CANCEL_BUTTON_LINK = (routeParameters: ISBRouteParams, isQuickpay: boolean, canAccessBillingPages: boolean): any => {
    let url;

    if (isQuickpay) {
        url = NATIONWIDE_HOMEPAGE;
    } else {
        url = canAccessBillingPages ? `${billsAndPaymentsPath}?bkey=${routeParameters.accountNumber}&myAccountUserSessionId=${routeParameters.sessionId}` : CAM_LINK;
    }

    return url;
};

const NW_HEADER = {
    logout: LOG_OUT_URL,
    personal_information: 'https://st-cam-modern.apps.nwie.net/myaccount/profile',
    preferences: 'https://st-cam-modern.apps.nwie.net/myaccount/preferences',
    manageAuthorizedUsers: 'https://st-cam-modern.apps.nwie.net/myaccount/manageUsers',
    contactUs: 'https://st-cam-modern.apps.nwie.net/myaccount/contact',
    unauthenticatedContactUs: 'https://www.nationwide.com/personal/contact/',
    allAccounts: 'https://st-myaccount.nationwide.nwie.net/myaccount/index.jsp',
    navigationLinks: (routeParams: ISBRouteParams): any => [
        {
            text: 'Billing Details',
            url: `${billingDetailsPath}?bkey=${routeParams.accountNumber}&myAccountUserSessionId=${routeParams.sessionId}&appName=ISB`
        },
        {
            text: 'Bills & Payments',
            url: `${billsAndPaymentsPath}?bkey=${routeParams.accountNumber}&myAccountUserSessionId=${routeParams.sessionId}`
        },
        {
            text: 'Payment Preferences',
            url: `${paymentPreferencesPath}?bkey=${routeParams.accountNumber}&myAccountUserSessionId=${routeParams.sessionId}`
        },
        {
            text: 'Questions & Tips',
            url: `${questionsAndTipsPath}?bkey=${routeParams.accountNumber}&myAccountUserSessionId=${routeParams.sessionId}`
        },
        {
            text: 'Contact Us',
            url: 'https://st-myaccount.nationwide.nwie.net/myaccount/portfolio/RetrieveContactDirectory.action'
        }
    ],
    navigationLinksNonOwner: [
        {
            text: 'Contact Us',
            url: 'https://st-myaccount.nationwide.nwie.net/myaccount/portfolio/RetrieveContactDirectory.action'
        }
    ]
};

export const billingDetailsLink = ({ accountNumber, sessionId }: ISBRouteParams = {}): string =>
    `/#${billsAndPaymentsPath}?bkey=${accountNumber}&myAccountUserSessionId=${sessionId}`;

const BREAD_CRUMBS = {
    billing_details: [{
        name: 'Billing Details',
        link: CAM_LINK
    }],
    billing_picker: [{
        name: 'Billing Picker',
        link: CAM_LINK
    }],
    CYBERLIFE_PAY_BILL: [{
        name: 'Make A Payment',
        link: CAM_LINK
    }],
    PAY_BILL: ({ accountNumber, sessionId }: ISBRouteParams = {}): any =>
        [{
            name: 'Billing Details',
            link: ISB.HOST_URL + billingDetailsLink({ accountNumber, sessionId })
        }],
    CHANGE_DUE_DATE: ({ accountNumber, sessionId }: ISBRouteParams = {}): any =>
        [{
            name: 'Billing Details',
            link: ISB.HOST_URL + billingDetailsLink({ accountNumber, sessionId })
        }],
    MANAGE_PAYMENT_PREFERENCES: ({ accountNumber, sessionId }: ISBRouteParams = {}): any =>
        [{
            name: `Billing Details`,
            link: ISB.HOST_URL + billingDetailsLink({ accountNumber, sessionId })
        }],
    MAKE_ANOTHER_PAYMENT: [{
        name: 'All Accounts',
        link: CAM_LINK
    }]
};

const BILLING_NAVIGATION_TABS = [
    {
        text: 'Billing details',
        url: (url: ISBRouteParams = {}): string => window.location.href,
        active: true
    },
    {
        text: 'Bills & payments',
        url: ({ accountNumber, sessionId }: ISBRouteParams = {}): string =>
            `/#${billsAndPaymentsPath}?bkey=${accountNumber}&myAccountUserSessionId=${sessionId}`,
        active: false
    },
    {
        text: 'Payment preferences',
        url: ({ accountNumber, sessionId }: ISBRouteParams = {}): string =>
            `/#${paymentPreferencesPath}?bkey=${accountNumber}&myAccountUserSessionId=${sessionId}`,
        active: false
    },
    {
        text: 'Questions & tips',
        url: ({ accountNumber, sessionId }: ISBRouteParams = {}): string =>
            `/#${questionsAndTipsPath}?bkey=${accountNumber}&myAccountUserSessionId=${sessionId}`,
        active: false
    }
];

const CONTACT_US_URL = 'https://myaccount.nationwide.com/myaccount/portfolio/RetrieveContactDirectory.action';

const NW_FOOTER = {
    forBusinessLink: 'https://www.nationwide.com/small-business-insurance.jsp',
    contactUsLink: 'https://st-cam-modern.apps.nwie.net/myaccount/contact',
    unauthenticatedContactUsLink: 'https://www.nationwide.com/personal/contact/',
    logout: LOG_OUT_URL
};

const TIMEOUT = {
    apiCallDuration: {
        currentBill: 30000,
        billingHistory: 30000,
        paymentHistory: 30000,
        payBillLanding: 20000,
        submitPayment: 15000,
        postPayment: 20000,
        submitChangeBillingDueDate: 15000,
        paymentPreferences: 10000,
        pbs: 15000,
        getClientIp: 5000
    },
    pollIntervalSeconds: 10,
    sessionLogOutUrl: 'https://iam-st-cnp.apps.nwie.net/access/web/sessionExpired.htm',
    logOutUrl: LOG_OUT_URL,
    logInUrl: 'https://iam-st-cnp.apps.nwie.net/access/web/login.htm',
    inactivityThresholdMinutes: 5,
    minutesToTimeoutWarning: 3,
    excludedPaths: [
        '/stub/login',
        '/stub/prod',
        '/stub/mcr'
    ]
};
const LOGGING_API_HOST = 'https://api-test.nwie.net';
const LOGGING_ENDPOINT_URL = '/it-management/client-logging/v1/client-logs';
const LOGGING = {
    clientLoggingEnabled: false,
    pushLength: 10,
    clientId: CLIENT_ID,
    endpoint: `${LOGGING_API_HOST}${LOGGING_ENDPOINT_URL}-cloud`,
    splunkToken: '147AC8FC-E181-4B05-8E3F-4D5FB64EADAA',
    loggingJson: 'OBJECTS',
    loggingLevel: 'debug',
    appName: 'dgs-internet-servicing-billing',
    envName: 'dev',
    maxLogLength: 200000,
    retryDelay: 0,
    maxRetryAttempts: 5,
    retryLogCurrentContentsTime: 500
};

const CELEBRUS_SESSION_NUMBER = 'nwcsasn';

const DOCUMENT_TILE_OPTIONS = {
    accessToken: '',
    app: LOGGING.appName,
    clientId: CLIENT_ID,
    environment: 'test',
    pcEnvironment: 'pls19',
    policyNumber: '',
    policyType: 'auto',
    sessionId: '',
    splunkToken: LOGGING.splunkToken,
    vehicleId: ''
};

const MOBIUS_DOCUMENT_MANAGEMENT = {
    documentSearchUrl: 'https://api-test.nwie.net/communication/mobiusviewapi/v1/search'
};

const EBI = {
    ebiEndPoint: 'https://api-test.nwie.net/businessintelligence/enterprisebusinessintelligence/v1/log'
};

const CTM = {
    ctmEndPoint: 'https://api-test.nwie.net/customerinformationmanagement/contactmanagement/v1/interactions'
};

const MAP_SAVED_PAYMENT_METHODS_API = {
    url: `https://api-test.nwie.net/billing-collections/money-processing/v1/saved-payment-methods`
};

const EUA_API_HOST = 'https://api-test.nwie.net';
const EUA_ENDPOINT_URL = '/security-processing/enterprise-user-auth/v2/authorize';
const OAUTH_CONFIG: OAuthConfig = {
    oauthEndpoint: `${EUA_API_HOST}${EUA_ENDPOINT_URL}`,
    client_id: CLIENT_ID,
    nonce: 'myNonce',
    responseType: 'id_token token',
    scope: 'test%20pci',
    realm: 'member',
    tokenStorage: SESSION_STORAGE.ACCESS_TOKEN,
    idTokenStorage: SESSION_STORAGE.JWT,
    excludedRoutes: [
        '/',
        '/stub/login',
        '/stub/mcr',
        '/stub/prod',
        '/configure',
        '/personal/error/404',
        '/personal/error/system-down'
    ],
    authTimeout: 20,
    debug: true,
    refreshThresholdSeconds: TIMEOUT.minutesToTimeoutWarning * SECONDS_IN_MINUTE,
    auth_method: 'ping',
    runningNestedIframes: false,
    useValidator: false,
    ttl: 1200
};

const RENEWAL_TOKEN_ENDPOINT = 'https://identity.nwie.com/bsa-login-refresh/refresh?instance=BSAOAuthB2CNW&resumeUrl=';
const OAUTH_REDIRECT_HANDLER_PATH = '/assets/html/eua-callback.html';

const QUICK_AUTH_CONFIG: OAuthConfig = {
    oauthEndpoint: `${EUA_API_HOST}${EUA_ENDPOINT_URL}`,
    client_id: CLIENT_ID,
    nonce: 'myNonce',
    responseType: 'id_token token',
    scope: 'test%20pci',
    realm: 'unidentified',
    tokenStorage: SESSION_STORAGE.ACCESS_TOKEN,
    idTokenStorage: SESSION_STORAGE.JWT,
    excludedRoutes: [
        '/stub/login',
        '/stub/mcr',
        '/stub/prod',
        '/configure',
        '/personal/error-404',
        '/personal/error/system-down',
        '/personal/billing',
        '/personal/billingMigration',
        '/personal/pay-bill',
        '/personal/billing-timeline'
    ],
    authTimeout: 20,
    debug: true,
    refreshThresholdSeconds: TIMEOUT.minutesToTimeoutWarning * SECONDS_IN_MINUTE,
    auth_method: 'nw-express',
    runningNestedIframes: false,
    useValidator: false,
    ttl: 1200
};

const EBI_EVENT_ID = {
    BILLING_DETAILS_ID: '406800076',
    QUESTIONS_AND_TIPS_ID: '406800301',
    PAYMENT_PREFERENCES_PAGE_LOAD_NO_SAVED_PAYMENT_METHODS_ID: '406800082',
    PAYMENT_PREFERENCES_PAGE_LOAD_SAVED_PAYMENT_METHODS_ID: '406800083',
    DEVICE_DETAILS_ID: '406800211',
    VIEW_PDF_STATEMENT_ID: '406800078',
    LOAD_PDF_STATEMENT_ID: '406800303',
    PRINTER_LINK_CLICKED_ID: '406800080',
    ON_CLICK_MAKE_PAYMENT_BTN: '406800077',
    ON_CLICK_CONTINUE_MAKE_PAYMENT_PAGE: '406800118',
    ON_CLICK_OF_PAYMENT_REVIEW_PAGE: '406800120',
    PRINT_LINK_CONFIRMATION_CLICKED: '406800122',
    ON_LOAD_MAKE_PAYMENT_PAGE: '406800116',
    ON_PAYMENT_AMOUNT_NOTIFICATION: '406800250',
    BILLING_TIMELINE_LOAD: '406800267',
    ON_SMART_MILES_LINK_CLICK: '406800276',
    ON_PAYMENT_CAP_ERROR: '406800298',
    ON_LOAD_MAKE_PAYMENT_PAGE_WITH_PMOF: '406800117',
    ON_LOAD_EDIT_AUTOMATIC_PAYMENTS_LANDING_PAGE: '406800104',
    ON_CLICK_RECURRING_PATMENT_BTN: '406800373',
    ON_LOAD_PAYMENT_CONFIRMATION_PAGE_WITH_SCHEDULED_FUTURE_PAYMENT: '406800231',
    ON_LAND_CONFIRMATION_PAGE_OF_THE_MAKE_A_PAYMENT_FLOW: '406800121',
    ON_LOAD_OF_QUICK_PAY_SEARCH_PAGE: '406800232',
    ON_SUBMIT_OF_QUICK_PAY_SEARCH_PAGE: '406800242',
    LOG_NUMBER_OF_USERS_COMING_IN_BILLING_VIA_THE_BILLING_TIMELINE_URL: '406800340',
    ON_OPEN_TIMELINE_EVENTS_MODAL: '406800331',
    ON_OPEN_TIMELINE_REFUNDS_MODAL: '406800358',
    PP_ADD_PAYMENT_METHOD_LANDING_ON_CONTINUE: '406800084',
    PP_ADD_PAYMENT_METHOD_ON_REVIEW_LOAD: '406800085',
    PP_ADD_PAYMENT_METHOD_CONFIRMATION_LOAD: '406800086',
    PP_ADD_PAYMENT_REVIEW_ON_CLICK_SUBMIT: '406800087',
    PP_ADD_PAYMENT_CONFIRMATION_PRINT: '406800088',
    PP_ADD_PAYMENT_CONFIRMATION_HARDFALL: '406800164',
    PP_REMOVE_NON_DEFAULT_PAYMENT_METHOD_CONFIRMATION: '406800089',
    PP_REMOVE_DEFAULT_PAYMENT_METHOD_CONFIRMATION: '406800090',
    PP_REMOVE_PAYMENT_METHOD_CONFIRMATION: '406800091',
    PP_ADD_REFUND_METHOD: '406800248',
    ON_LOAD_OF_EDIT_PAYMENT_METHOD_LANDING_PAGE: '406800093',
    ON_CLICK_OF_SUBMIT_EDIT_PAYMENT_METHOD_REVIEW_PAGE: '406800094',
    ON_LOAD_OF_EDIT_PAYMENT_METHOD_REVIEW_PAGE: '406800095',
    ON_LOAD_OF_EDIT_PAYMENT_METHOD_CONFIRMATION_PAGE: '406800096',
    ON_CLICK_OF_EDIT_PAYMENT_METHOD_CONFIRMATION_PRINT_BUTTON: '406800097',
    ON_CLICK_OF_AUTHORIZATION_AGREEMENT_LINK: '406800098',
    ON_LOAD_AUTOMATIC_PAYMENTS_LANDING_PAGE: '406800099',
    ON_LOAD_AUTOMATIC_PAYMENTS_REVIEW_PAGE: '406800100',
    ON_LOAD_AUTOMATIC_PAYMENTS_REVIEW_PAGE_AUTH_AGREEMENT: '406800124',
    ON_COMPLETION_OF_EASYPAY_ENROLLMENT: '406800101',
    ON_CLICK_OF_SUBMIT_BTN_EDIT_AUTOMATIC_PAYMENTS_REVIEW_PAGE: '406800107',
    ON_LOAD_OF_EDIT_AUTOMATIC_PAYMENTS_CONFIRMATION_PAGE: '406800108',
    ON_CLICK_OF_PRINT_BTN_EDIT_AUTOMATIC_PAYMENTS_CONFIRMATION_PAGE: '406800109',
    ON_SUCCESS_OR_FAIL_EASYPAY_ENROLLMENT: '406800103',
    ON_CLICK_PRINT_BTN_AUTOMATIC_PAYMENTS_CONFIRMATION_PAGE: '406800102',
    ON_PAGE_LOAD_OF_SUSPEND_EASY_PAY_LANDING_PAGE: '406800206',
    ON_CLICK_OF_CANCEL_IN_SUSPEND_EASY_PAY_LANDING_PAGE: '406800311',
    ON_PAGE_LOAD_OF_SUSPEND_EASY_PAY_CONFIRMATION_PAGE: '406800309',
    ON_CLICK_OF_PRINT_BTN_SUSPEND_EASY_PAY_CONFIRMATION_PAGE: '406800310',
    ON_CLICK_OF_PAY_LATER_OR_MAP_BTN_SUSPEND_EASY_PAY_CONFIRMATION_PAGE: '406800207',
    ON_EDIT_OF_PAYMENT_METHOD_ON_AUTOMATIC_PAYMENT_LANDING_PAGE: '406800106',
    ON_LOAD_OF_CANCEL_AUTOMATIC_PAYMENT_LANDING_PAGE: '406800110',
    ON_LOAD_OF_CANCEL_AUTOMATIC_PAYMENT_CONFIRMATION_PAGE: '406800112',
    ON_CLICK_SUBMIT_CANCEL_AUTOMATIC_PAYMENTS_LANDING: '406800111',
    ON_CLICK_OF_PRINT_BTN_CANCEL_EASY_PAY_CONFIRMATION_PAGE: '406800113',
    ON_LOAD_OF_QUICK_PAY_PICKER_PAGE: '406800259',
    ON_CLICK_CONTINUE_OF_QUICK_PAY_PICKER_PAGE: '406800260',
    ON_LOAD_CONFIRMATION_PAGE_CANCEL_SCHEDULED_PAYMENT: '406800230',
    ON_CLICK_CONTINUE_SAVE_PAYMENT_METHOD_AS_REFUND_METHOD_LANDING_PAGE: '406800351',
    ON_INCOMPLETE_FORM_ELEMENTS_IN_EFT_AUTH_FORM: '406800349',
    LOG_NUMBER_OF_TIMES_HP_EXTREME_SERVICE_INVOKING: '406800350',
    ON_CLICK_OF_SUBMIT_BTN_REMOVE_REFUND_METHOD_LANDING_PAGE: '406800249',
    ON_CLICK_OF_REMOVE_REFUND_METHOD_BTN: '406800247',
    ON_CONFIRMATION_LOAD_SAVE_PAYMENT_METHOD_AS_REFUND_METHOD: '406800352',
    ON_CLICK_OF_ADD_REFUND_METHOD_BTN: '406800246',
    ON_BANK_ACCOUNT_VERIFICATION_ERROR: '406800360',
    ON_CLICK_OF_GLOBAL_NAV_BILLS_CHILD_OPTION: '406800344',
    ON_CLICK_OF_GLOBAL_NAV_POLICIES_CHILD_OPTION: '406800345',
    ON_CLICK_OF_GLOBAL_NAV_BILLS_OPTION: '406800347',
    ON_LOAD_CHANGE_DUE_DATE_CONFIRMATION_LANDING_PAGE: '406800367',
    ON_LOAD_CHANGE_DUE_DATE_REVIEW_AND_SUBMIT_PAGE: '406800366',
    ON_LOAD_CHANGE_DUE_DATE_LANDING_PAGE: '406800365',
    ON_CLICK_OF_CONTACT_US_LINK_LOGS_OUT_OF_ISB: '406800363',
    ON_SELECT_OF_THE_CONTACT_US_BUTTON_IN_THE_PAY_PLAN_SECTION: '406800371',
    ON_SELECT_OF_THE_EDIT_PAYMENT_PLAN_BUTTON: '406800370',
    ON_DISPLAY_OF_THE_PAYMENT_PLAN_SECTION: '406800369',
    ON_SUBMIT_OF_FULLPAY_PAYMENT_PLAN: '406800372'
};

const VIRTUAL_TOKEN_ENDPOINT = (key: string): string => 'https://api-int-test.nwie.net/tommy-test/?flow=iam-token';

const TRIDION_DOWN_CONFIG: TridionDownConfig = {
    contents: {
        Pages: {
            privacySecurityHeader: {
                Content: '<p>Website privacy &amp; security</p>'
            },
            privacySecurity: {
                Content: '<p>We take your privacy and online security very seriously. <a href="http://www.nationwide.com/privacy-security.jsp" target="_blank">Learn more</a></p>'
            },
            contactUsHeader: {
                Content: ''
            },
            popularLinksHeader: {
                Content: '<p>Popular links</p>'
            },
            popularLinks: {
                Content: '<ul>\n<li><a href="https://myaccount.nationwide.com/myaccount/portfolio/RetrievePortfolio.action" target="_blank">All accounts</a> - Get a summary of all your Nationwide accounts.</li>\n<li><a href="https://myaccount.nationwide.com/myaccount/profile/RetrieveUserContactInformation.action" target="_blank">Profile</a> - View/Update your preferences and personal information.</li>\n<li><a href="http://agency.nationwide.com/" target="_blank">Find an agent</a> - Find an insurance agent near you.</li>\n<li><a href="https://www.nationwide.com" target="_blank">Get a quote</a> - Get an online insurance quote.</li>\n</ul>'
            },
            warning400: {
                Content: '<p>The page you\'re trying to reach has moved or is no longer active.</p>'
            },
            warning500: {
                Content: '<p>An error occurred while accessing this page. Please try again later. If the problem persists, <a href="\\&quot;https://myaccount.nationwide.com/myaccount/portfolio/RetrieveContactDirectory.action\\&quot;" target="\\&quot;_blank\\&quot;">contact us</a> for assistance.</p>'
            }
        },
        Components: {
            contactUsBilling: {
                ConsiderThisSections: {
                    column1: {
                        Content: '<p>Billing &amp; policy support</p>'
                    },
                    column2: {
                        Content: '<p><a href="https://myaccount.nationwide.com/myaccount/portfolio/RetrieveContactDirectory.action" target="_blank">Contact us</a></p>'
                    }
                }
            },
            contactUsClaims: {
                ConsiderThisSections: {
                    column1: {
                        Content: '<p>Claims support</p>'
                    },
                    column2: {
                        Content: '<p>1-800-421-3535</p>'
                    }
                }
            },
            contactUsEmail: {
                ConsiderThisSections: {
                    column1: {
                        Content: '<p>Send us your questions</p>'
                    },
                    column2: {
                        Content: '<p>\n              <a href="https://www.nationwide.com/contact-us/mailform.x?language=en?" target="_blank">Email us</a>\n            </p>'
                    }
                }
            },
            contactUsGeneral: {
                ConsiderThisSections: {
                    column1: {
                        Content: '<p>General questions</p>'
                    },
                    column2: {
                        Content: '<p><a href="https://myaccount.nationwide.com/myaccount/portfolio/RetrieveContactDirectory.action" target="_blank">Contact us</a></p>'
                    }
                }
            },
            contactUsTech: {
                ConsiderThisSections: {
                    column1: {
                        Content: '<p>Technical support</p>'
                    },
                    column2: {
                        Content: '<p>1-877-304-1065</p>'
                    }
                }
            }
        }
    }
};

const INTERNET_SERVICING_POLICY_ROOT = 'https://api-test.nwie.net/policymanagement/internetservicing-account/v1';
const ISP = {
    ENDPOINTS: {
        BILLS_AND_PAYMENTS: (accountNumber: string, clientId: string, startDate: string, endDate: string): string => `${INTERNET_SERVICING_POLICY_ROOT}/accounts/${accountNumber}/billsAndPayments?client_id=${clientId}&start_date=${startDate}&end_date=${endDate}`,
        RETREIVE_PAYMENT_HISTORY: (accountNumber, policyNumbers, clientId, startDate, endDate): string =>
                `${INTERNET_SERVICING_POLICY_ROOT}/accounts/${accountNumber}/payment-history` +
                `?${policyNumbers}` +
                `&client_id=${clientId}` +
                `&start_date=${startDate}&end_date=${endDate}`,
        PAYMENT_CONFIRMATION_EMAIL_URL: (clientId: string): string => `${INTERNET_SERVICING_POLICY_ROOT}/accounts/sendPaymentConfirmationEmail?client_id=${clientId}`,
        CHANGE_DUE_DATE_CONFIRMATION_EMAIL_URL: (clientId: string): string => `${INTERNET_SERVICING_POLICY_ROOT}/accounts/sendChangeDueDateConfirmationEmail?client_id=${clientId}`
    }
};

const CO_BROWSE = {
    enabled: true
};

const DOCUMENT_OPENER = {
    endpoint: 'https://api-test.nwie.net/billing-collections/internet-servicing-billing/v1/open-document'
};

const ZERO_AMOUNT_DUE_API_SERVICE = {
    isEnabled: true
};

const ONE_TIME_PASSWORD = {
    url: 'https://api-test.nwie.net/security-processing/one-time-password/v1/tokens'
};

const CARD_ENCRYPTION = {
    url: 'https://payments2-st.nwie.net/paymentCard/encrypted/4.0'
};

const CLICK_TO_CHAT = {
    enabled: true
};

const CYBERLIFE_ACCOUNT_LEVEL_PAYMENTS = {
    enabled: true
};

const ACCOUNT_VALIDATION = {
    url: 'https://api-test.nwie.net/billing-collections/bank-account-verification/v1/bank-accounts/verify',
    featureToggle: true
};

const BAMNR = 'assets/js/bam-nr/sb-st-bamnr.js';
const PET_PAYMENT_LINK = 'https://my.petinsurance.com/pets';
const AGENT_DIRECTORY_LINK = 'https://agency.nationwide.com/map';
const COMMERCIAL_QUICKPAY_URL = 'https://iscomm.t.nationwide.com/commercial/#/quickPay/search';
const CYBERLIFE_QUICKPAY = {
    eBISourceSystemFieldLengthConditionEnabled: false
};

const PLAID = {
    url: 'https://api-test.nwie.net/operations-management/internet-servicing/v1/plaid/exchangeToken',
    env: 'sandbox',
    public_key: 'fe9cc1c22b9a69c596ad1e19a2bac4'
};

const ISB_EXP_API_BASE_URL = 'https://api-test.nwie.net/billing-collections/internet-servicing-billing/v1';
const ISB_EXP_API = {
    moneyProcessing: {
        singleSavedPaymentMethodUrl: (methodId: string): string => `${ISB_EXP_API_BASE_URL}/money-processing/saved-payment-methods/${methodId}`,
        savedPaymentMethodsUrl: (): string => `${ISB_EXP_API_BASE_URL}/money-processing/saved-payment-methods`,
        paymentsUrl: (accountNumber: string): string => `${ISB_EXP_API_BASE_URL}/money-processing/personal-lines-accounts/${accountNumber}/payments`,
        banksUrl: (routingNumber: string): string => `${ISB_EXP_API_BASE_URL}/money-processing/banks/${routingNumber}`,
        cancelScheduledPaymentUrl: (agreementNumber: string, paymentId: string): string => `${ISB_EXP_API_BASE_URL}/money-processing/personal-lines-accounts/${agreementNumber}/payments/${paymentId}`
    },
    personalLinesBilling: {
        agreementUrl: (guid: string): string => `${ISB_EXP_API_BASE_URL}/personal-lines-billing/billing-accounts/${guid}/prefill`,
        billsUrl: (accountNumber: string): string => `${ISB_EXP_API_BASE_URL}/personal-lines-billing/billing-accounts/${accountNumber}/bills`,
        payPlanUrl: (accountNumber: string, requestType?: string): string =>
            `${ISB_EXP_API_BASE_URL}/personal-lines-billing/billing-accounts/${accountNumber}/pay-plans/current?request_type=${requestType || ''}`,
        eligiblePayPlanUrl: (accountNumber: string): string => `${ISB_EXP_API_BASE_URL}/personal-lines-billing/billing-accounts/${accountNumber}/eligible-payplan`,
        calculateDownPaymentUrl: (): string => `${ISB_EXP_API_BASE_URL}/personal-lines-billing/billing-accounts/1/down-payment`
    },
    customerEmails: {
        sendEnrollAutomaticPaymentsEmailUrl: (): string => `${ISB_EXP_API_BASE_URL}/customer-emails/automatic-payments-enroll`,
        sendEditAutomaticPaymentsEmailUrl: (): string => `${ISB_EXP_API_BASE_URL}/customer-emails/automatic-payments-edit`,
        sendCancelAutomaticPaymentsEmailUrl: (): string => `${ISB_EXP_API_BASE_URL}/customer-emails/automatic-payments-cancel`,
        sendRemoveRefundMethodEmailUrl: (): string => `${ISB_EXP_API_BASE_URL}/customer-emails/remove-refund-method`,
        sendAddRefundMethodEmailUrl: (): string => `${ISB_EXP_API_BASE_URL}/customer-emails/add-refund-method`,
        sendSuspendAutomaticPaymentsEmailUrl: (): string => `${ISB_EXP_API_BASE_URL}/customer-emails/suspend-recurring-payment`,
        sendAddSavedPaymentMethodEmailUrl: (): string => `${ISB_EXP_API_BASE_URL}/customer-emails/add-saved-payment-method`,
        sendPaymentConfirmationEmailUrl: (): string => `${ISB_EXP_API_BASE_URL}/customer-emails/sendPaymentConfirmationEmail`,
        sendChangeDueDateConfirmationEmailUrl: (): string => `${ISB_EXP_API_BASE_URL}/customer-emails/change-due-date`
    },
    contexts: {
        contextsUrl: (ecn: string): string => `${ISB_EXP_API_BASE_URL}/contexts/${ecn}`
    }
};

const CONTEXT_CACHE = {
    contextsUrl: (): string => 'https://api-test.nwie.net/security-processing/context-cache/v1/contexts/'
};

const CHASE_PROFILE_CREATION_URL = 'https://apipci-test.nwie.net/billing-collections/chase-profile-creation/v1/payment-card-profiles';
const AGENCY_INFORMATION = {
    url: (agreementNumber: string): string => `https://api-test.nwie.net/billing-collections/personal-lines-billing/v2/billing-accounts/${agreementNumber}/accountagencyinformation`
};
const DISTRIBUTED_DOCUMENTS = {
    url: 'https://api-test.nwie.net/documentation-management/distributed-documents/v1/documents?',
    targetEnvironment: 'SystemTest'
};
const DPIM_AGENCY_INFORMATION = {
    url: (agentNumber: string): string => `https://api-test.nwie.net/channel-distribution-partner-management/distribution-partner-management/v1/legacies/${agentNumber}/namecontactpoints?${DPIM_AGENCY_INFORMATION_URL_STATIC_PARAMS}`
};

const GETDISTRIBUTIONPARTNER_INFO = {
    url: (agentIdentifier: string, query: string): string => `https://api-test.nwie.net/operations-management/internet-servicing/v1/distribution-partner-management/distributionpartnerroles/${agentIdentifier}?${query}`
};

const GLOBAL_NAV = {
    APIs: {
        apigeeEnvironment: 'https://api-test.nwie.net/',
        experience: 'policymanagement/internetservicing-policy/v1/policies/',
        experienceAuto: 'policymanagement/internetservicing-policy/v1/auto-policies/policies/',
        personalLines: 'policymanagement/personallines/v1/',
        vinDetails: 'underwritingmanagement/vehicledetails/v1/vehicledetails/',
        vehicleMakes: 'underwritingmanagement/vehicleinquiry_1/v1/vehicleModelMakes',
        vehicleModelWithDescription: 'underwritingmanagement/vehicleinquiry_1/v1/vehicleModelsWithDescription',
        vehicleModelSeries: 'underwritingmanagement/vehicledetails/v1/vehicleSeriesOdbi/'
    },
    policyCenterHeaderURL: 'https://pls15.plpolicycenter.apps-test.aws.e1.nwie.net',
    policyCenterShortName: 'pls15',
    authorize: {
        client_id: CLIENT_ID
    }
};

const POLICY_REQUESTS = {
    APIs: {
        apigeeEnvironment: 'https://api-test.nwie.net/',
        experienceAuto: 'policymanagement/internetservicing-policy/v1/auto-policies/policies/'
    },
    policyCenterHeaderURL: 'https://pls19.plpolicycenter.apps-test.aws.e1.nwie.net',
    policyCenterShortName: 'pls19',
    authorize: {
        client_id: CLIENT_ID
    }
};

const childURLs = {
    isbBase: 'https://isb.t.nationwide.com/',
    icpBase: 'https://servicingst.nwie.net/',
    ispBase: 'https://www.isp.t.nationwide.com/',
    sreBase: 'https://www.sre.t.awspubliccloud.nationwide.com/'
};

const ispURLs = {
    documents: (policyNumber: string, sid: string): string => `${childURLs.ispBase}#/personal/policy/documents?pkey=${policyNumber}&sid=${sid}`
};

const claimsList = 'https://www.claims.t.nationwide.com/fileclaim/info/claim-list-page?pkey=POLICY_NUMBER&destinationPageName=claims-list-page#/redirect';
const propertyClaims = 'https://claimsst.apps.nwie.net/claims/index.html?pkey=POLICY_NUMBER&destinationPageName=property-claims-list#/redirect';
const CONTACT_US = {
    url: 'https://st-myaccount.nationwide.nwie.net/myaccount/portfolio/RetrieveContactDirectory.action'
};
const PERSONAL_LOGIN_HOME_PAGE = {
    url: 'https://www.nationwide.com/personal/login'
};

const WAYS_TO_PAY = {
    url: 'https://ways2pay-st.apps.nwie.net',
    savedPaymentMethodsEnabled: true,
    areBankAccountPaymentMethodsEnabled: true,
    areBankCardPaymentMethodsEnabled: true,
    shouldHideCVVField: false,
    isPlaidEnabled: true,
    inline: 'false'
};

const SEARCH_AGENT_DIRECTORY_LINK = {
    url: 'https://agency.nationwide.com/map'
};

const LOGIN_URL = 'https://iam-st-cnp.apps.nwie.net/access/web/login.htm';

const CELEBRUS_WINDOW_SESSION_IDENTIFIER = 'nwcsadevcontentKey';

export const environment = {
    MOCKING_APIS: false,
    PRODUCTION,
    CLIENT_ID,
    ADOBE_MBOXES,
    MAKE_A_PAYMENT,
    OAUTH_CONFIG,
    QUICK_AUTH_CONFIG,
    QUERY_PARAMS,
    BILLING,
    PBS,
    tridion,
    tridionProperties,
    NW_HEADER,
    BREAD_CRUMBS,
    BILLING_NAVIGATION_TABS,
    NW_FOOTER,
    SESSION_STORAGE,
    ISB,
    CIAM,
    ISC,
    PAYMENT_PREFERENCES,
    ANIMATIONS,
    TIMEOUT,
    VIRTUAL_TOKEN_ENDPOINT,
    LOGGING,
    CELEBRUS_SESSION_NUMBER,
    MOBIUS_DOCUMENT_MANAGEMENT,
    EBI,
    CTM,
    APP_COOKIE,
    NW_APP_ID,
    TRANSACTION_ID,
    BILLING_MIGRATION_TABS,
    EBI_EVENT_ID,
    TRIDION_DOWN_CONFIG,
    SMART_MILES,
    DOCUMENTS,
    ISP,
    NW_NAV_LIFE_LINK,
    CO_BROWSE,
    DOCUMENT_OPENER,
    MAKE_PAYMENT_CANCEL_BUTTON_LINK,
    CONNECTION_TYPE,
    IS_CONNECTION_TYPE_REQUIRED,
    MAKE_PAYMENT_URL,
    ZERO_AMOUNT_DUE_API_SERVICE,
    ONE_TIME_PASSWORD,
    CARD_ENCRYPTION,
    CAM_HOME: CAM_LINK,
    NFCIM,
    CLICK_TO_CHAT,
    DOCUMENT_TILE_OPTIONS,
    ECIM,
    PREFERENCECENTER,
    ACCOUNT_VALIDATION,
    WALLETPAY,
    CYBERLIFE_ACCOUNT_LEVEL_PAYMENTS,
    MASTERPASS_ENABLED,
    EUA_ENDPOINT_URL,
    LOGGING_ENDPOINT_URL,
    PERSONAL_LINES_BILLING_API_HOST,
    EUA_API_HOST,
    LOGGING_API_HOST,
    NATIONWIDE_HOMEPAGE,
    BAMNR,
    CYBERLIFE_QUICKPAY_ENABLED,
    PET_PAYMENT_LINK,
    AGENT_DIRECTORY_LINK,
    COMMERCIAL_QUICKPAY_URL,
    CYBERLIFE_QUICKPAY,
    ISB_EXP_API_BASE_URL,
    ISB_EXP_API,
    CONTEXT_CACHE,
    CHASE_PROFILE_CREATION_URL,
    PLAID,
    AGENCY_INFORMATION,
    DISTRIBUTED_DOCUMENTS,
    DPIM_AGENCY_INFORMATION,
    GETDISTRIBUTIONPARTNER_INFO,
    MAP_SAVED_PAYMENT_METHODS_API,
    GLOBAL_NAV,
    POLICY_REQUESTS,
    childURLs,
    ispURLs,
    claimsList,
    propertyClaims,
    contentful,
    CONTACT_US,
    CONTACT_US_URL,
    PERSONAL_LOGIN_HOME_PAGE,
    WAYS_TO_PAY,
    SEARCH_AGENT_DIRECTORY_LINK,
    LOGIN_URL,
    RENEWAL_TOKEN_ENDPOINT,
    OAUTH_REDIRECT_HANDLER_PATH,
    CELEBRUS_WINDOW_SESSION_IDENTIFIER
};
