import { DatePipe, TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { IsbSharedCommonHelperService } from '../../../../../../shared/isb-shared-common-helper.service';
import { BankcardRecapFormField } from '../../../../../../shared/payments/bankcard-recap-form-field/bankcard-recap-form-field.component';
import { CardType } from '../../../../../../shared/payments/card-utils/card-type.enum';
import { PaymentFormControls } from '../../../../../../shared/payments/payment-form-controls';
import { PaymentFormGroup } from '../../../../../../shared/payments/payment-form-group';
import { RecapFormField } from '../../../../../../shared/payments/recap-form-field/recap-form-field.component';
import { SessionService } from '../../../../../../shared/session/session.service';
import { EditAutomaticPaymentFlowService } from '../services/edit-automatic-payments-flow-service';
import { BankAccountPaymentItem } from '../../../../../payment-preferences/shared/models/bankaccount-item.model';
import { BankCardPaymentItem } from '../../../../../payment-preferences/shared/models/bankcard-item.model';
@Injectable()
export class EditAutomaticPaymentsReview {
    considerThisDisplayable: boolean;

    // eslint-disable-next-line max-params
    constructor(
        private editAutomaticPaymentsFlowService: EditAutomaticPaymentFlowService,
        private titleCasePipe: TitleCasePipe,
        private paymentFormControls: PaymentFormControls,
        private session: SessionService,
        private readonly datePipe: DatePipe,
        private readonly isbSharedCommonHelperService: IsbSharedCommonHelperService
    ) { }

    initialize(): void {
        if (this.editAutomaticPaymentsFlowService.flowInPlay) {
            this.considerThisDisplayable = this.editAutomaticPaymentsFlowService.considerThisDisplayable;
            this.addEmailControlToForm();
            this.addEzPayAuthGroup();
        }
    }

    addEmailControlToForm(): void {
        if (this.editAutomaticPaymentsFlowService.emailAddress) {
            this.editAutomaticPaymentForm.group.addControl(
                'emailAddressGroup',
                this.paymentFormControls.newEmailFormGroup(this.editAutomaticPaymentsFlowService.emailAddress)
            );
        }
    }

    addEzPayAuthGroup(): void {
        this.editAutomaticPaymentForm.group.addControl(
            'ezPayAuthGroup',
            this.paymentFormControls.newEzPayAuthGroup()
        );
    }

    get addEzPayAuthGroupControls(): AbstractControl {
        return this.editAutomaticPaymentForm.group.get('ezPayAuthGroup');
    }

    get emailAddress(): string {
        return this.enrollingInPaperless === 'Yes' && this.editAutomaticPaymentsFlowService.paperlessEmail ? this.editAutomaticPaymentsFlowService.paperlessEmail : this.editAutomaticPaymentForm.emailAddress;
    }

    get editAutomaticPaymentForm(): PaymentFormGroup {
        return this.editAutomaticPaymentsFlowService.editAutomaticPaymentForm;
    }

    get firstName(): string {
        let firstName = '';
        if (this.editAutomaticPaymentsFlowService.selectedPayMethod.isBankAccount) {
            firstName = (<BankAccountPaymentItem> this.editAutomaticPaymentsFlowService.selectedPayMethod.item).firstName;
        } else if (this.editAutomaticPaymentsFlowService.selectedPayMethod.isBankCard) {
            firstName = (<BankCardPaymentItem> this.editAutomaticPaymentsFlowService.selectedPayMethod.item).firstName;
        }

        return firstName;
    }

    get middleInitial(): string {
        let middleInitial = '';
        if (this.editAutomaticPaymentsFlowService.selectedPayMethod.isBankAccount) {
            middleInitial = (<BankAccountPaymentItem> this.editAutomaticPaymentsFlowService.selectedPayMethod.item).middleInitial;
        } else if (this.editAutomaticPaymentsFlowService.selectedPayMethod.isBankCard) {
            middleInitial = (<BankCardPaymentItem> this.editAutomaticPaymentsFlowService.selectedPayMethod.item).middleInitial;
        }
        return middleInitial;
    }

    get lastName(): string {
        let lastName = '';
        if (this.editAutomaticPaymentsFlowService.selectedPayMethod.isBankAccount) {
            lastName = (<BankAccountPaymentItem> this.editAutomaticPaymentsFlowService.selectedPayMethod.item).lastName;
        } else if (this.editAutomaticPaymentsFlowService.selectedPayMethod.isBankCard) {
            lastName = (<BankCardPaymentItem> this.editAutomaticPaymentsFlowService.selectedPayMethod.item).lastName;
        }
        return lastName;
    }

    get payorName(): string {
        return this.middleInitial === '' ?
            `${this.firstName} ${this.lastName}` :
            `${this.firstName} ${this.middleInitial} ${this.lastName}`;
    }

    get authorizedUser(): string {
        return `${this.session.firstName} ${this.session.lastName}`;
    }

    get defaultPaymentMethod(): string {
        return this.editAutomaticPaymentForm.makeDefaultPaymentMethod ? 'Yes' : 'No';
    }

    get cvvLabel(): string {
        return this.isAmexCard ? 'CID' : 'CVV';
    }

    get maskedCvv(): string {
        return this.isAmexCard ? '****' : '***';
    }

    get cvv(): string {
        return (<BankCardPaymentItem> this.editAutomaticPaymentsFlowService.selectedPayMethod.item).cvv;
    }

    get isAmexCard(): boolean {
        return this.cardType === CardType.AMERICAN_EXPRESS;
    }

    get cardType(): CardType {
        return (<BankCardPaymentItem> this.editAutomaticPaymentsFlowService.selectedPayMethod.item).cardType;
    }

    get bankName(): string {
        return (<BankAccountPaymentItem> this.editAutomaticPaymentsFlowService.selectedPayMethod.item).bankName;
    }

    get encryptedBankAccountNumber(): string {
        return this.editAutomaticPaymentsFlowService.selectedPayMethod.item instanceof BankAccountPaymentItem ? this.editAutomaticPaymentsFlowService.selectedPayMethod.item.encryptedAccountNumber : '';
    }

    get billingAccountHolderName(): string {
        return this.titleCasePipe.transform(this.editAutomaticPaymentsFlowService.billingAccountHolderName);
    }

    get billingAccountNumber(): string {
        return this.editAutomaticPaymentsFlowService.billingAccountNumber;
    }

    get nbpEffectiveDate(): string {
        let nextBill = '';
        if (this.session.isNBP) {
            if (this.editAutomaticPaymentsFlowService.isLifeOnlyAccount) {
                nextBill = 'Next Bill';
            } else {
                nextBill = this.editAutomaticPaymentsFlowService.nextBillDueDate ? this.datePipe.transform(this.editAutomaticPaymentsFlowService.nextBillDueDate, 'MM/dd/yyyy') : 'Next Bill';
            }
        }
        return nextBill;
    }

    get effectiveDate(): string {
        return this.editAutomaticPaymentsFlowService.billedStatus ? 'Current Bill' : 'Next Bill';
    }

    get enrollingInPaperless(): string {
        const enrollInPaperless = this.editAutomaticPaymentForm.enrollInPaperless;
        return enrollInPaperless && this.titleCasePipe.transform(enrollInPaperless);
    }

    get considerThisReviewFields(): RecapFormField[] {
        let considerThisReviewFields: RecapFormField[];
        const paperLess = {
            id: 'enroll-in-paperless-recap',
            label: 'Would you like to enroll in paperless for all of your accounts and policies?',
            value: this.enrollingInPaperless
        };
        if (this.editAutomaticPaymentsFlowService.isPaperlessEligible) {
            considerThisReviewFields = [paperLess];
        }
        if (this.enrollingInPaperless === 'Yes') {
            considerThisReviewFields.push({
                id: 'enroll-in-paperless-recap',
                label: 'Paperless enrollment date',
                value: `${this.isbSharedCommonHelperService.getCurrentDateWithGivenFormatType('MM/dd/yyyy, hh:mm aa ')}ET`
            });
            considerThisReviewFields.push({
                id: 'enroll-in-paperless-recap',
                label: 'Paperless effective date',
                value: `${this.isbSharedCommonHelperService.getCurrentDateWithGivenFormatType('MM/dd/yyyy, hh:mm aa ')}ET`
            });
            considerThisReviewFields.push({
                id: 'enroll-in-paperless-recap',
                label: 'Paperless and automatic payments email',
                value: this.editAutomaticPaymentsFlowService.paperlessEmail || this.editAutomaticPaymentsFlowService.emailAddress
            });
        }
        return considerThisReviewFields;
    }

    get isBankAccountFlow(): boolean {
        return this.editAutomaticPaymentsFlowService.selectedPayMethod.isBankAccount;
    }

    get isBankCardFlow(): boolean {
        return this.editAutomaticPaymentsFlowService.selectedPayMethod.isBankCard;
    }

    get paymentMethodId(): string {
        return this.editAutomaticPaymentsFlowService.selectedPayMethod.id;
    }

    get accountType(): string {
        return (<BankAccountPaymentItem> this.editAutomaticPaymentsFlowService.selectedPayMethod.item).accountType;
    }

    get street(): string {
        let street = '';
        if (this.editAutomaticPaymentsFlowService.selectedPayMethod.isBankAccount) {
            street = (<BankAccountPaymentItem> this.editAutomaticPaymentsFlowService.selectedPayMethod.item).street;
        } else {
            street = this.editAutomaticPaymentsFlowService.billingStreet1;
        }
        return street;
    }

    get apartmentNumber(): string {
        let apartmentNumber = '';
        apartmentNumber = (<BankAccountPaymentItem> this.editAutomaticPaymentsFlowService.selectedPayMethod.item).apartmentNumber;
        return apartmentNumber;
    }

    get city(): string {
        let city = '';
        if (this.editAutomaticPaymentsFlowService.selectedPayMethod.isBankAccount) {
            city = (<BankAccountPaymentItem> this.editAutomaticPaymentsFlowService.selectedPayMethod.item).city;
        } else {
            city = this.editAutomaticPaymentsFlowService.billingCity;
        }

        return city;
    }

    get zip(): string {
        let zip = '';
        if (this.editAutomaticPaymentsFlowService.selectedPayMethod.isBankAccount) {
            zip = (<BankAccountPaymentItem> this.editAutomaticPaymentsFlowService.selectedPayMethod.item).zip;
        } else if (this.editAutomaticPaymentsFlowService.selectedPayMethod.isBankCard) {
            zip = (<BankCardPaymentItem> this.editAutomaticPaymentsFlowService.selectedPayMethod.item).zipCode;
        }

        return zip;
    }

    get stateName(): string {
        return this.editAutomaticPaymentForm.state || this.editAutomaticPaymentsFlowService.billingState;
    }

    get routingNumber(): string {
        return (<BankAccountPaymentItem> this.editAutomaticPaymentsFlowService.selectedPayMethod.item).routingNumber;
    }

    get maskedBankAccountNumber(): string {
        return (<BankAccountPaymentItem> this.editAutomaticPaymentsFlowService.selectedPayMethod.item).maskedBankAccountNumber;
    }

    get bankAccountNumber(): string {
        return this.encryptedBankAccountNumber;
    }

    get bankAccountType(): string {
        return (<BankAccountPaymentItem> this.editAutomaticPaymentsFlowService.selectedPayMethod.item).accountType;
    }

    get accountNumberLastThree(): string {
        const bankAccountNumber = this.bankAccountNumber || this.maskedBankAccountNumber;
        const ACCOUNT_LAST_THREE = 3;
        return bankAccountNumber.slice(bankAccountNumber.length - ACCOUNT_LAST_THREE);
    }

    get currentDate(): string {
        return this.datePipe.transform(new Date(), 'MM/dd/yyyy');
    }

    get cardExpirationMonth(): string {
        const expiryMonth = (<BankCardPaymentItem> this.editAutomaticPaymentsFlowService.selectedPayMethod.item).cardExpiration.getMonth() + 1;
        const expiryMonthString = expiryMonth < 10 ? `0${expiryMonth.toString()}` : expiryMonth.toString();
        return expiryMonthString;
    }

    get cardExpirationYear(): string {
        return (<BankCardPaymentItem> this.editAutomaticPaymentsFlowService.selectedPayMethod.item).cardExpiration.getFullYear().toString();
    }

    get cardExpirationDate(): string {
        return `${this.cardExpirationMonth}/${this.cardExpirationYear}`;
    }

    get cardNumberLastFour(): string {
        const cardNumber = (<BankCardPaymentItem> this.editAutomaticPaymentsFlowService.selectedPayMethod.item).cardNumberLastFour ||
        (<BankCardPaymentItem> this.editAutomaticPaymentsFlowService.selectedPayMethod.item).displayNumbers;
        return cardNumber;
    }

    save(params: Partial<EditAutomaticPaymentFlowService>): void {
        this.editAutomaticPaymentsFlowService.save(params);
    }

    get paymentDetailsReviewFields(): RecapFormField[] | BankcardRecapFormField[] {
        const fields = [];

        fields.push({
            id: 'account-holders-name-recap',
            label: 'Account holder\'s name',
            value: this.billingAccountHolderName
        });

        fields.push({
            id: 'billing-account-number',
            label: 'Billing account number',
            value: this.billingAccountNumber
        });

        fields.push({
            id: 'transaction-authorized-by',
            label: 'Transaction authorized by',
            value: this.titleCasePipe.transform(this.authorizedUser)
        });

        if (this.isBankAccountFlow) {
            fields.push({
                id: 'enrollment-date',
                label: 'Enrollment date',
                value: this.currentDate
            });

            fields.push({
                id: 'payment-effective-date',
                label: 'Automatic payment effective date',
                value: this.session.isNBP ? this.nbpEffectiveDate : this.effectiveDate
            });

            fields.push({
                id: 'payment-method',
                label: 'Payment method',
                value: 'Bank account'
            });

            fields.push({
                id: 'account-type-recap',
                label: 'Account type',
                value: this.titleCasePipe.transform(this.accountType)
            });

            fields.push({
                id: 'bank-routing-number-recap',
                label: 'Bank routing number',
                value: this.routingNumber
            });

            fields.push({
                id: 'bank-name-recap',
                label: 'Bank name',
                value: this.bankName
            });

            fields.push({
                id: 'account-number-recap',
                label: 'Account number',
                value: `*********${this.accountNumberLastThree}`
            });
        }

        if (this.isBankCardFlow) {
            fields.push({
                id: 'enrollment-date',
                label: 'Enrollment date',
                value: this.currentDate
            });

            fields.push({
                id: 'payment-effective-date',
                label: 'Automatic payment effective date',
                value: this.session.isNBP ? this.nbpEffectiveDate : this.effectiveDate
            });

            fields.push({
                id: 'card-holder-name-recap',
                label: 'Card holder\'s name',
                value: this.payorName
            });

            fields.push({
                id: 'zip-recap',
                label: 'ZIP',
                value: this.zip
            });

            fields.push({
                id: 'card-number-recap',
                label: 'Card number',
                value: `************${this.cardNumberLastFour}`,
                cardType: this.cardType
            });

            fields.push({
                id: 'expiration-date-recap',
                label: 'Expiration date',
                value: this.cardExpirationDate
            });
        }

        return fields;
    }
}
