import { PaymentFormGroup } from '../../shared/payments/payment-form-group';
import { PaymentItem } from '../payment-preferences/shared/models/payment-item.model';
import { CardType } from '../../shared/payments/card-utils/card-type.enum';
import { BankAccountPaymentItem } from '../payment-preferences/shared/models/bankaccount-item.model';
import { BankCardPaymentItem } from '../payment-preferences/shared/models/bankcard-item.model';
export class CommonValuesPaymentFormGroupModel {
    constructor(
        readonly paymentFormGroup: PaymentFormGroup,
        readonly paymentItem: PaymentItem,
        readonly billingAccountHolderName: string
    ) { }

    get firstName(): string {
        let firstName = '';
        if (this.paymentItem.isBankAccount) {
            firstName = (<BankAccountPaymentItem> this.paymentItem.item).firstName;
        } else if (this.paymentItem.isBankCard) {
            firstName = (<BankCardPaymentItem> this.paymentItem.item).firstName;
        }

        return firstName;
    }

    get middleInitial(): string {
        let middleInitial = '';
        if (this.paymentItem.isBankAccount) {
            middleInitial = (<BankAccountPaymentItem> this.paymentItem.item).middleInitial;
        } else if (this.paymentItem.isBankCard) {
            middleInitial = (<BankCardPaymentItem> this.paymentItem.item).middleInitial;
        }
        return middleInitial;
    }

    get lastName(): string {
        let lastName = '';
        if (this.paymentItem.isBankAccount) {
            lastName = (<BankAccountPaymentItem> this.paymentItem.item).lastName;
        } else if (this.paymentItem.isBankCard) {
            lastName = (<BankCardPaymentItem> this.paymentItem.item).lastName;
        }
        return lastName;
    }

    get payorName(): string {
        // eslint-disable-next-line no-negated-condition
        return this.middleInitial !== '' ?
            `${this.firstName} ${this.middleInitial} ${this.lastName}` :
            `${this.firstName} ${this.lastName}`;
    }

    get defaultPaymentMethod(): string {
        return this.paymentFormGroup.makeDefaultPaymentMethod ? 'Yes' : 'No';
    }

    get cvvLabel(): string {
        return this.isAmexCard ? 'CID' : 'CVV';
    }

    get maskedCvv(): string {
        return this.isAmexCard ? '****' : '***';
    }

    get cvv(): string {
        return (<BankCardPaymentItem> this.paymentItem.item).cvv;
    }

    get isAmexCard(): boolean {
        return this.cardType === CardType.AMERICAN_EXPRESS;
    }

    get cardType(): CardType {
        return (<BankCardPaymentItem> this.paymentItem.item).cardType;
    }

    get bankName(): string {
        return (<BankAccountPaymentItem> this.paymentItem.item).bankName;
    }

    get bankAccountType(): string {
        return (<BankAccountPaymentItem> this.paymentItem.item).accountType;
    }

    get isBankAccountFlow(): boolean {
        return this.paymentItem.isBankAccount;
    }

    get isBankCardFlow(): boolean {
        return this.paymentItem.isBankCard;
    }

    get accountType(): string {
        return (<BankAccountPaymentItem> this.paymentItem.item).accountType;
    }

    get apartmentNumber(): string {
        let apartmentNumber = '';
        apartmentNumber = (<BankAccountPaymentItem> this.paymentItem.item).apartmentNumber;
        return apartmentNumber;
    }

    get zip(): string {
        let zip = '';
        if (this.paymentItem.isBankAccount) {
            zip = (<BankAccountPaymentItem> this.paymentItem.item).zip;
        } else if (this.paymentItem.isBankCard) {
            zip = (<BankCardPaymentItem> this.paymentItem.item).zipCode;
        }

        return zip;
    }

    get routingNumber(): string {
        return (<BankAccountPaymentItem> this.paymentItem.item).routingNumber;
    }

    get encryptedBankAccountNumber(): string {
        return this.paymentItem.item instanceof BankAccountPaymentItem ? this.paymentItem.item.encryptedAccountNumber : '';
    }

    get accountNumberLastThree(): string {
        return (<BankAccountPaymentItem> this.paymentItem.item).maskedBankAccountNumber;
    }

    get bankAccountNumber(): string {
        return this.encryptedBankAccountNumber;
    }

    get cardExpirationMonth(): string {
        const expiryMonth = (<BankCardPaymentItem> this.paymentItem.item).cardExpiration.getMonth() + 1;
        const expiryMonthString = expiryMonth < 10 ? `0${expiryMonth.toString()}` : expiryMonth.toString();
        return expiryMonthString;
    }

    get cardExpirationYear(): string {
        return (<BankCardPaymentItem> this.paymentItem.item).cardExpiration.getFullYear().toString();
    }

    get cardExpirationDate(): string {
        return `${this.cardExpirationMonth}/${this.cardExpirationYear}`;
    }

    get cardNumberLastFour(): string {
        return (<BankCardPaymentItem> this.paymentItem.item).cardNumberLastFour;
    }

    get cardNumber(): string {
        return this.paymentFormGroup.bankCardNumber;
    }
}
