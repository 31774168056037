import { Injectable, Inject } from '@angular/core';
import {
    PersonalBilling as Unmapped
} from '@nationwide/dgs-angular-billing-common';
import { PAY_PLAN_CONFIRMATION_PAGE_TEXTS } from '../../../../../../shared/constants/common.constants';
import { SessionService } from '../../../../../../shared/session/session.service';
import { LoggerService } from '../../../../../../shared/logger/logger.service';
import { HeaderDetails, RecordInfo, UpComingPaymentModel } from '../../../payment-plan/edit/confirmation/up-coming-payments.model';
import { PayPlanFlow } from './pay-plan-flow.service';
@Injectable()
export class PayPlanAdapter {

    constructor(
        public session: SessionService,
        private payPlanFlow: PayPlanFlow,
        @Inject('logger') private logger: LoggerService
    ) { }

    getRelatedPolicy(policyArray: Unmapped.Policy[], policyNumber: string): Unmapped.Policy {
        return policyArray.filter((policy) => this.areTwoValuesMatching(policy?.policyNumberAlternative, policyNumber) ||
            this.areTwoValuesMatching(policy?.policyNumber, policyNumber))[0];
    }

    getCurrentBill(billsArray: Unmapped.Bill[]): Unmapped.Bill {
        return billsArray?.filter((bill) => bill?.billType?.equalsIgnoreCase('Current'))[0];
    }

    getFirstFutureBill(bills: Unmapped.Bill[]): Unmapped.Bill {
        let bill: Unmapped.Bill = null;
        if (bills && bills.length > 1) {
            const sortedBills = [...bills].sort((a, b) => this.sortBills(a, b));
            const currentBill: Unmapped.Bill = this.getCurrentBill(sortedBills);
            if (sortedBills && sortedBills.length > 0) {
                bill = sortedBills[sortedBills.indexOf(currentBill) + 1];
            }
        }
        return bill?.billType?.equalsIgnoreCase('Next') ? bill : null;
    }

    isFutureBillExist(bills: Unmapped.Bill[]): boolean {
        const futureBills: Unmapped.Bill[] = bills.filter((bill) => bill?.billType?.equalsIgnoreCase('Next'));
        return futureBills && futureBills.length > 0;
    }

    isCurrentBillExist(bills: Unmapped.Bill[]): boolean {
        const currentBill: Unmapped.Bill = this.getCurrentBill(bills);
        return !!currentBill;
    }

    areNoCurrentAndFutureBills(bills: Unmapped.Bill[]): boolean {
        return !this.isCurrentBillExist(bills) && !this.isFutureBillExist(bills);
    }

    isCurrentBillPaid(currentBill: Unmapped.Bill): boolean {
        return currentBill?.indicators?.paid;
    }

    areTwoValuesMatching(value1: string, value2: string): boolean {
        return value1?.replace(/\s/g, '') === value2?.replace(/\s/g, '');
    }

    isEmptyBillResponse(billsArray: Unmapped.Bill[]): boolean {
        return billsArray === undefined || billsArray.length === 0;
    }

    sortBills(a: any, b: any): number {
        if (this.getTimeValue(a) - this.getTimeValue(b) === 0) {
            if (a?.billType?.equalsIgnoreCase('Prior') && b?.billType?.equalsIgnoreCase('Current') ||
                a?.billType?.equalsIgnoreCase('Current') && b?.billType?.equalsIgnoreCase('Next')) {
                return -1;
            } else if (b?.billType?.equalsIgnoreCase('Prior') && a?.billType?.equalsIgnoreCase('Current') ||
                b?.billType?.equalsIgnoreCase('Current') && a?.billType?.equalsIgnoreCase('Next')) {
                return 1;
            } else {
                return 0;
            }
        } else {
            return this.getTimeValue(a) - this.getTimeValue(b);
        }
    }

    getMinimumDueFromBills(bills: Unmapped.Bill[]): number {
        const currentBillIndex = bills.findIndex((bill) => bill.billType === 'Current');
        let minimumAmountDue = 0;
        if (currentBillIndex < 0) {
            const nextBillIndex = bills.findIndex((bill) => bill.billType === 'Next');
            minimumAmountDue = nextBillIndex >= 0 ? bills[nextBillIndex].minimumDue : minimumAmountDue;
        } else {
            minimumAmountDue = bills[currentBillIndex].minimumDue;
        }
        return minimumAmountDue;
    }

    getFullPayMonthlyDue(payPlanMethods: Unmapped.EligiblePayPlan, paymentMethod): number {
        const payMethods = {
            CPPDB: 'MONTHLY DIRECT',
            CPPRP: 'MONTHLY RECURRING BANKCARD',
            CPPEP: 'MONTHLY EFT'
        };
        let fullPayMonthlyDue = 0;
        const payMethodsMap = new Map(Object.entries(payMethods));
        payPlanMethods?.eligiblePayplansAndPayMethods?.forEach((payPlanMethod) => {
            if (payPlanMethod?.payMethod === payMethodsMap.get(paymentMethod) && payPlanMethod?.totalInstallmentWithFee) {
                fullPayMonthlyDue = Number(payPlanMethod.totalInstallmentWithFee);
            }
        });
        return fullPayMonthlyDue;
    }

    getPremiumFromPolicy(relatedPolicies: Unmapped.Policy[]): number {
        let premium = 0;
        const autoPolicy = relatedPolicies?.find((policy) => policy?.policyLabel === 'AUTO' && policy?.policyStatus !== 'Cancelled');
        premium = autoPolicy?.premium || 0;
        return premium;
    }

    private getTimeValue(bill: any): number {
        let numberOfMilliSeconds: number;
        if (bill?.dueDate) {
            numberOfMilliSeconds = new Date(bill.dueDate).getTime();
        }
        return numberOfMilliSeconds;
    }

    mapUpComingPayments(): UpComingPaymentModel {
        const upComingPaymentModel = new UpComingPaymentModel();
        const headerDetails: HeaderDetails = {
            billLabel: PAY_PLAN_CONFIRMATION_PAGE_TEXTS.UPCOMING_PAYMENTS.BILL,
            amountLabel: PAY_PLAN_CONFIRMATION_PAGE_TEXTS.UPCOMING_PAYMENTS.AMOUNT,
            dateLabel: this.payPlanFlow.isAutoPayEnrolled ? PAY_PLAN_CONFIRMATION_PAGE_TEXTS.UPCOMING_PAYMENTS.AUTOMATIC_PAYMENT_DATE : PAY_PLAN_CONFIRMATION_PAGE_TEXTS.UPCOMING_PAYMENTS.DUE_DATE
        };
        let recordInfoArray: RecordInfo[] = [];
        if (this.payPlanFlow.isEmptyBillResponse) {
            recordInfoArray.push(this.getRenewalRecordInfo(this.payPlanFlow.termExpirationDate));
        } else {
            recordInfoArray = this.buildNotEmptyResponseContent();
        }

        upComingPaymentModel.headerDetails = headerDetails;
        upComingPaymentModel.recordInfo = recordInfoArray;
        return upComingPaymentModel;
    }

    private buildNotEmptyResponseContent(): RecordInfo[] {
        return this.payPlanFlow.isCurrentBillPaid ? this.buildCurrentBillPaidContent() : this.buildCurrentBillNotPaidContent();
    }

    private buildCurrentBillPaidContent(): RecordInfo[] {
        const recordInfoArray: RecordInfo[] = [];
        if (this.payPlanFlow.fullPayAmount > 0) {
            recordInfoArray.push(this.getRecordInfo(PAY_PLAN_CONFIRMATION_PAGE_TEXTS.UPCOMING_PAYMENTS.NEXT, this.payPlanFlow.fullPayAmount?.toString(), this.payPlanFlow.nextDueDate));
            recordInfoArray.push(this.getRenewalRecordInfo(this.payPlanFlow.termExpirationDate));
        } else {
            recordInfoArray.push(this.getRenewalRecordInfo(this.payPlanFlow.termExpirationDate));
        }
        return this.removeEmptyObject(recordInfoArray);
    }

    private buildCurrentBillNotPaidContent(): RecordInfo[] {
        const recordInfoArray: RecordInfo[] = [];
        recordInfoArray.push(this.getRecordInfo(PAY_PLAN_CONFIRMATION_PAGE_TEXTS.UPCOMING_PAYMENTS.CURRENT_ISSUED, this.payPlanFlow.minimumDueAmount?.toString(), this.payPlanFlow.currentDueDate));
        recordInfoArray.push(this.getRecordInfo(PAY_PLAN_CONFIRMATION_PAGE_TEXTS.UPCOMING_PAYMENTS.NEXT, this.payPlanFlow.fullPayAmount?.toString(), this.payPlanFlow.nextDueDate));
        recordInfoArray.push(this.getRenewalRecordInfo(this.payPlanFlow.termExpirationDate));
        return this.removeEmptyObject(recordInfoArray);
    }

    private getRecordInfo(billType: string, amount: string, dateValue: Date): RecordInfo {
        const recordInfo: RecordInfo = {
            billType,
            amount,
            dateValue
        };
        return amount && dateValue ? recordInfo : null;
    }

    private getRenewalRecordInfo(dateValue: Date): RecordInfo {
        const recordInfo: RecordInfo = {
            billType: PAY_PLAN_CONFIRMATION_PAGE_TEXTS.UPCOMING_PAYMENTS.RENEWAL,
            amount: PAY_PLAN_CONFIRMATION_PAGE_TEXTS.UPCOMING_PAYMENTS.FULL_PREMIUM_TBD,
            dateValue
        };
        return dateValue ? recordInfo : null;
    }

    private removeEmptyObject(recordInfoArray: RecordInfo[]): RecordInfo[] {
        return recordInfoArray.filter((recordInfo) => recordInfo);
    }
}
