import { Component, Inject, OnInit, Self } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../../../../environments/environment';
import { BasePageComponent } from '../../../../../../shared/base/base-page.component';
import { RESPONSE_CODES } from '../../../../../../shared/constants/common.constants';
import { LoggerService } from '../../../../../../shared/logger/logger.service';
import { BankcardRecapFormField } from '../../../../../../shared/payments/bankcard-recap-form-field/bankcard-recap-form-field.component';
import { CCAuthorizationAgreementParams, EFTAuthorizationAgreementParams } from '../../../../../../shared/payments/ez-pay-authorization/easy-pay-authorization-agreement.service';
import { DistributedDocumentsResponseModel } from '../../../../../../shared/payments/ez-pay-authorization/eft-authorization-form/model/distributed-documents-response.model';
import { FormUtil } from '../../../../../../shared/payments/form-util';
import { PaymentFormGroup } from '../../../../../../shared/payments/payment-form-group';
import { RecapFormField } from '../../../../../../shared/payments/recap-form-field/recap-form-field.component';
import { PaymentMethodsData } from '../../../../../../shared/service/billing-api-common.service';
import { BillingEventService } from '../../../../../../shared/service/ebi-service/billing-events.service';
import { EBIEventType } from '../../../../../../shared/service/ebi-service/ebi-model';
import { SessionService } from '../../../../../../shared/session/session.service';
import { UrlUtil } from '../../../../../../shared/url-util/url-util.service';
import { ManagePaymentPreferencesPageTitle } from '../../../shared/models/manage-payment-preferences-page-title.constant';
import { SetupAutomaticPaymentsFlowService } from '../services/setup-automatic-payments-flow.service';
import { SetupAutomaticPaymentsReview } from './setup-automatic-payments-review.model';
import { SetupAutomaticPaymentsReviewService } from './setup-automatic-payments-review.service';

@Component({
    selector: 'app-setup-automatic-payments-review',
    templateUrl: './setup-automatic-payments-review.component.html',
    styleUrls: [
        './setup-automatic-payments-review.component.scss',
        '../landing/setup-automatic-payments-landing.component.scss'],
    providers: [SetupAutomaticPaymentsReview]
})
export class SetupAutomaticPaymentsReviewComponent extends BasePageComponent implements OnInit {
    pageName = ManagePaymentPreferencesPageTitle.automaticPayments.setup.review;
    considerThisReviewFields: RecapFormField[] = [];
    paymentDetailsReviewFields: RecapFormField[] | BankcardRecapFormField[] = [];
    loadComplete = false;
    authorizationAgreementParams: EFTAuthorizationAgreementParams | CCAuthorizationAgreementParams;
    acknowledgedCheckboxDisabled: boolean;
    distributedDocumentsResponseModel: DistributedDocumentsResponseModel;

    // eslint-disable-next-line max-params
    constructor(
        @Self() private review: SetupAutomaticPaymentsReview,
        router: Router,
        private setupAutomaticPaymentsFlowService: SetupAutomaticPaymentsFlowService,
        private eventService: BillingEventService,
        private setupAutomaticPaymentsReviewService: SetupAutomaticPaymentsReviewService,
        @Inject('window') private window: Window,
        private formUtils: FormUtil,
        protected session: SessionService,
        @Inject('logger') private logger: LoggerService,
        private urlUtil: UrlUtil
    ) {
        super(session, router);
    }

    ngOnInit(): void {
        try {
            super.ngOnInit();
            this.session.currentPage = this.pageName;
            this.review.initialize();
            this.paymentDetailsReviewFields = this.review.paymentDetailsReviewFields;
            this.considerThisReviewFields = this.review.considerThisReviewFields;
            this.loadComplete = true;
            if (this.setupAutomaticPaymentsFlowService.isPaperlessEligible) {
                this.logger.info('Setup Automatic Payment Review - Page load',
                    this.setupAutomaticPaymentsReviewService.logPageLoadForPaperless(this.paymentFormReview.enrollInPaperless)
                );
            }

            this.eventService.handleEvent(EBIEventType.ON_LOAD_AUTOMATIC_PAYMENTS_REVIEW_PAGE, {
                paymentMethod: this.getPaymentInfo(),
                paymentPlan: this.setupAutomaticPaymentsFlowService.paymentPlan
            });
            this.eventService.handleEvent(EBIEventType.ON_LOAD_AUTOMATIC_PAYMENTS_REVIEW_PAGE_AUTH_AGREEMENT, {
                paymentPlan: this.setupAutomaticPaymentsFlowService.paymentPlan
            });
            this.setAuthorizationAgreementInputParams();
        } catch (error) {
            this.logger.error('Setup Automatic Payments Review - load failed: ', {
                error: error.stack || error
            });
        }
    }

    getPaymentInfo(): PaymentMethodsData[] {
        const paymentMethodValueData = [];
        const firstName = this.review.firstName || this.session.firstName || '';
        const lastName = this.review.lastName || this.session.lastName || '';
        const defaultMethod = this.review.defaultPaymentMethod || '';
        const bankName = this.review.bankName || '';
        const accountType = this.review.bankAccountType || '';
        const creditCardVendor = this.review.setUpAutomaticPaymentsForm.bankCardNumber ? this.review.savedCardType || this.review.cardType : '';
        paymentMethodValueData.push({
            firstName,
            lastName,
            accountType,
            defaultMethod,
            bankName,
            creditCardVendor
        });

        return paymentMethodValueData;
    }

    navigateToSetupAutomaticPayments(): void {
        this.router.navigateByUrl(environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.AUTOMATIC_PAYMENTS.SETUP.LANDING(
            this.urlUtil.hashParamsString
        ));
    }

    navigateToPaymentPreferences(): void {
        this.router.navigateByUrl(environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.paymentPreferences(
            this.urlUtil.hashParamsString
        ));
    }

    navigateToConfirmationPage(): void {
        this.router.navigateByUrl(
            environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.AUTOMATIC_PAYMENTS.SETUP.CONFIRMATION(
                this.urlUtil.hashParamsString
            ));
    }

    onSubmit(): void {
        this.formUtils.touchAllFormControls(this.paymentFormReview.group);
        if (this.paymentFormReview.group.valid && (!this.ezPayAuthSectionDisplayable || !this.review.addEzPayAuthGroupControls.disabled)) {
            this.loadComplete = false;
            this.setupAutomaticPaymentsReviewService.createAutomaticPaymentReviewManager().subscribe(() => {
                if (this.responseHasVULCreditCardError()) {
                    const errorDetails = this.setupAutomaticPaymentsFlowService.errorDetails;
                    this.setupAutomaticPaymentsFlowService.clear();
                    this.setupAutomaticPaymentsFlowService.save({ hasVULCreditCardError: true, errorDetails });
                    this.navigateToSetupAutomaticPayments();
                } else {
                    this.navigateToConfirmationPage();
                }
            });
        } else {
            if (this.ezPayAuthSectionDisplayable) {
                this.review.addEzPayAuthGroupControls.markAllAsTouched();
            }
            this.window.scroll(0, 0);
        }
    }

    get paymentFormReview(): PaymentFormGroup {
        return this.review.setUpAutomaticPaymentsForm;
    }

    get considerThisSectionDisplayable(): boolean {
        return this.review.enrollingInPaperless === 'Yes' || this.setupAutomaticPaymentsFlowService.isPaperlessEligible;
    }
    get ezPayAuthSectionDisplayable(): boolean {
        return !!this.authorizationAgreementParams;
    }

    get paperlessSelection(): boolean {
        return this.review?.enrollingInPaperless !== 'Yes';
    }

    private setAuthorizationAgreementInputParams(): void {
        this.authorizationAgreementParams = this.setupAutomaticPaymentsFlowService.authorizationAgreementParams;
        this.distributedDocumentsResponseModel = this.setupAutomaticPaymentsFlowService.distributedDocumentsResponseModel;
        this.acknowledgedCheckboxDisabled = this.review.isBankAccountFlow;
    }

    sendOnClickOfAuthAgreementEbiEvent(): void {
        this.eventService.handleEvent(EBIEventType.ON_CLICK_OF_AUTHORIZATION_AGREEMENT_LINK, {
            paymentMethod: this.getPaymentInfo(),
            authAgreementUrl: 'Y',
            paymentPlan: this.setupAutomaticPaymentsFlowService.paymentPlan
        });
    }

    private responseHasVULCreditCardError(): boolean {
        const VULErrorResponseText = 'Credit cards cannot be used for variable life policies. Please use a different payment method.';
        return this.setupAutomaticPaymentsFlowService.errorDetails?.errorCode === RESPONSE_CODES.ERRORS.SAP.VUL_CREDIT_CARD &&
            this.setupAutomaticPaymentsFlowService.errorDetails?.errorMessage === VULErrorResponseText;
    }

    get emailAddress(): string {
        return this.paymentFormReview.emailAddress;
    }
}
