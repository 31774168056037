
import { Component, Inject, OnInit } from '@angular/core';
import { RemoveDuplicatesPipe } from '@nationwide/internet-servicing-angular-pipes';
import { forkJoin, of, map, mergeMap } from 'rxjs';
import { ContentfulISBData, ContentfulMapperService } from '@nationwide/dgs-angular-billing-common';
import { SessionService } from '../session/session.service';
import { EbiEvent, GlobalNav } from '@nationwide/internet-servicing-global-navigation';
import { environment } from '../../../environments/environment';
import { LoggerService } from '../logger/logger.service';
import { RetrieveVinService } from '@nationwide/dgs-internet-servicing-policy-requests';
import { BillingEventService } from '../service/ebi-service/billing-events.service';
import { GlobalNavWrapperContent } from './global-nav-wrapper-content.service';
import { HttpCachingService } from '../service/http-caching-service/http-caching.service';

@Component({
    selector: 'app-global-nav-wrapper',
    templateUrl: './global-nav-wrapper.component.html',
    styleUrls: ['./global-nav-wrapper.component.scss']
})
export class GlobalNavWrapperComponent implements OnInit {
    pbsErrorMessage = 'API ERROR: ISB GLOBAL NAV PBS RETRIEVE PROFILE ERROR';
    isGlobalNavTridionDataLoaded = false;
    isGlobalNavPolicyDetailsLoaded = false;
    isPBSDataLoaded = false;
    isGlobalNavLoadComplete = false;
    canShowGlobalNav = false;
    globalNavPolicyDetails: GlobalNav;
    contentfulGlobalNavData: any = {};
    pbsData: any = {};
    agreements: any = [];
    billingAgreements: any = [];
    ecn: string;

    // eslint-disable-next-line max-params
    constructor(
        private readonly removeDuplicatesPipe: RemoveDuplicatesPipe,
        private readonly httpCachingService: HttpCachingService,
        public session: SessionService,
        private readonly vinService: RetrieveVinService,
        private readonly eventService: BillingEventService,
        private readonly contentfulService: ContentfulMapperService,
        private readonly globalNavContentMapper: GlobalNavWrapperContent,
        @Inject('logger') private readonly logger: LoggerService
    ) { }

    ngOnInit(): void {
        this.loadGlobalNavTridionData();
        this.setGlobalNavData();
    }

    linkClicked($event): void {
        this.logMakeAPaymentButtonClick($event);
        window.open($event.url, '_self');
        window.location.reload();
    }

    logMakeAPaymentButtonClick($event): void {
        if ($event.url.includes('/personal/pay-bill/landing')) {
            this.logger.info('On click of global navigation Make a Payment link');
        }
    }

    handleEbiEvent(ebiEvent: EbiEvent): void {
        this.eventService.handleGlobalNavEvent(ebiEvent.eventId, { isGlobalNavOption: true, destinationURL: ebiEvent.destinationUrl, IDENTIFIER: ebiEvent.agreementNumber });
    }

    private setGlobalNavData(): void {
        this.isPBSDataLoaded = false;
        this.setPBSData();
    }

    private loadGlobalNavTridionData(): void {
        let billingNavigationIcons;
        let policyNavigationIcons;
        this.isGlobalNavTridionDataLoaded = false;
        const apisToCall = [
            this.contentfulService.getContentfulBillingNavigation(),
            this.contentfulService.getContentfulPolicyNavigation(),
            this.contentfulService.getContentfulFeatureToggles()
        ];
        forkJoin(apisToCall).subscribe({
            next: (forkResponse: ContentfulISBData[]) => {
                const routeToCoveragesInISP = forkResponse[2].coveragesHOToggle.content === 'TRUE';
                const billingNavigationMap = this.globalNavContentMapper.mapBillingNavigationResponse(forkResponse[0]);
                const policyNavigationMap = this.globalNavContentMapper.mapPolicyNavigationResponse(forkResponse[1]);
                billingNavigationIcons = billingNavigationMap?.Components;
                policyNavigationIcons = policyNavigationMap?.Components;
                this.canShowGlobalNav = forkResponse[2].globalNavToggle.content === 'TRUE';
                this.contentfulGlobalNavData = { billingNavIcons: billingNavigationIcons, policyNavIcons: policyNavigationIcons, routeToCoveragesInISP };
                this.isGlobalNavTridionDataLoaded = true;
            },
            error: (error) => {
                this.isGlobalNavTridionDataLoaded = false;
                this.handleError('API ERROR: RETRIEVE TRIDION GLOBAL NAV', error);
            }
        });
    }

    private setPBSData(): void {
        this.httpCachingService.fetchRetrieveCustomerAgreementResponse().subscribe({
            next: (response) => {
                try {
                    this.pbsData = response.retrieveCustomerAgreementResponse.return;
                    this.pbsData.profile.agreementGroup.agreement = this.removeDuplicatesPipe.transform(this.pbsData.profile.agreementGroup.agreement);
                    this.getGlobalNavPolicyDetails(this.pbsData);
                    this.isPBSDataLoaded = true;
                    this.isGlobalNavLoadComplete = true;
                } catch (error) {
                    this.handleError(this.pbsErrorMessage, error);
                }
            },
            error: (error) => {
                this.handleError(this.pbsErrorMessage, error);
            }
        });
    }

    private getGlobalNavPolicyDetails(pbsResponse): void {
        this.isGlobalNavPolicyDetailsLoaded = false;
        this.globalNavPolicyDetails = { policyDetails: {} };
        if (pbsResponse?.profile?.agreementGroup?.agreement?.length > 0) {
            const policyDetailCalls = pbsResponse.profile.agreementGroup.agreement.map((agreement: any) => {
                if (agreement.agreementLabel === 'Auto' && agreement.system === 'PolicyCenter') {
                    return this.vinService.retrieveAllVehiclesFromAnotherPolicy({
                        accessToken: this.session.accessToken,
                        camSessionId: this.session.a2aSessionId,
                        environment: environment.GLOBAL_NAV,
                        policyNumber: agreement.agreementNumber
                    }).pipe(
                        mergeMap((vehicles) => {
                            if (vehicles && !vehicles.error && vehicles.length > 0) {
                                const retrieveVinCalls = vehicles.map((vehicle) => this.vinService.retrieveVinInformationIndividual(vehicle.vin, {
                                    accessToken: this.session.accessToken,
                                    camSessionId: this.session.a2aSessionId,
                                    environment: environment.GLOBAL_NAV
                                }).pipe(
                                    map((vinResponse) => {
                                        if (vinResponse && !vinResponse.error) {
                                            vehicle = this.vinService.setYearMakeModel(vehicle, vinResponse);
                                        }
                                        const agreementNumberKey = typeof agreement.refId === 'string' ? agreement.refId : agreement.refId[0];
                                        this.globalNavPolicyDetails.policyDetails[agreementNumberKey] = vehicles;
                                        return vehicle;
                                    })));
                                return forkJoin(retrieveVinCalls);
                            }
                            return of({});
                        }));
                }
                return of({});
            });
            forkJoin(policyDetailCalls).subscribe(() => {
                this.isGlobalNavPolicyDetailsLoaded = true;
            });
        } else {
            this.isGlobalNavPolicyDetailsLoaded = true;
        }
    }

    private handleError(message: string, error: string): void {
        this.logger.error(message, { error });
        this.logger.logCurrentContents();
        this.isPBSDataLoaded = false;
        this.isGlobalNavLoadComplete = true;
    }
}
