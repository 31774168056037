import { Component, OnInit, Inject } from '@angular/core';
import { BasePageComponent } from '../../../../../../shared/base/base-page.component';
import { ManagePaymentPreferencesPageTitle } from '../../../shared/models/manage-payment-preferences-page-title.constant';
import { SessionService } from '../../../../../../shared/session/session.service';
import { Router } from '@angular/router';
import { Observable, of, catchError, map, tap } from 'rxjs';
import { AreYouSureFormGroup } from '../../../../../../shared/payments/are-you-sure-radio-buttons/are-you-sure-form-group';
import { ManagePaymentPrefsFormBuilder } from '../../../base/manage-payment-prefs-form-builder';
import { FormUtil } from '../../../../../../shared/payments/form-util';
import { PaymentPreferencesStateService } from '../../../../shared/services/payment-preferences-state/payment-preferences-state.service';
import { environment } from '../../../../../../../environments/environment';
import { PaymentPreferencesApiService } from '../../../../../../billing/payment-preferences/shared/services/payment-preferences-api/payment-preferences-api.service';
import { LoggerService } from '../../../../../../shared/logger/logger.service';
import { ManagePaymentPrefsAdapter } from '../../../shared/adapters/manage-payment-prefs-adapter';
import { SuspendAutomaticPaymentsLanding } from './suspend-automatic-payments-landing.model';
import { SuspendAutomaticPaymentsFlowService } from '../services/suspend-automatic-payments-flow.service';
import { UrlUtil } from '../../../../../../shared/url-util/url-util.service';
import { BillingCommonNavigationsService } from '../../../../../../billing/shared/billing-common-navigations.service';
import { BillingEventService } from '../../../../../../shared/service/ebi-service/billing-events.service';
import { EBIEventType } from '../../../../../../shared/service/ebi-service/ebi-model';
import { BillingEBIOptions } from '../../../../../../shared/service/billing-api-common.service';
import { isbExperienceServiceToken, IsbExperienceService } from '@nationwide/dgs-angular-billing-common';
import { HttpErrorResponse } from '@angular/common/http';
import { EasternTimeDatePipe } from '@nationwide/internet-servicing-angular-pipes';

import { DistributionPartnerRoleInfoService } from '../../../../../shared/distribution-partner-role-info/distribution-partner-role-info.service';
import { DistributionPartnerRoleInfoAdapter } from '../../../../../../billing/shared/distribution-partner-role-info/distribution-partner-role-info.adapter';
import { OmsResponse } from '@nationwide/dgs-angular-billing-common/api-response-types/internet-servicing-billing-experience';
@Component({
    selector: 'app-suspend-automatic-payments-landing',
    templateUrl: './suspend-automatic-payments-landing.component.html',
    styleUrls: ['./suspend-automatic-payments-landing.component.scss']
})
export class SuspendAutomaticPaymentsLandingComponent extends BasePageComponent implements OnInit {
    pageName = ManagePaymentPreferencesPageTitle.automaticPayments.suspend.landing;
    formGroup: AreYouSureFormGroup;
    pageLoadComplete = false;
    submitted = false;
    suspendAutomaticPaymentsLanding: SuspendAutomaticPaymentsLanding;
    paymentPlan: string;
    emailAddress: string;

    // eslint-disable-next-line max-params
    constructor(
        protected session: SessionService,
        protected router: Router,
        private formBuilder: ManagePaymentPrefsFormBuilder,
        private formUtil: FormUtil,
        private paymentPreferencesState: PaymentPreferencesStateService,
        private paymentPreferencesApiService: PaymentPreferencesApiService,
        private managePaymentPrefsAdapter: ManagePaymentPrefsAdapter,
        private suspendAutomaticPaymentsFlowService: SuspendAutomaticPaymentsFlowService,
        @Inject('logger') private logger: LoggerService,
        private urlUtil: UrlUtil,
        private billingCommonNavigationsService: BillingCommonNavigationsService,
        private eventService: BillingEventService,
        @Inject(isbExperienceServiceToken) private isbExperience: IsbExperienceService,
        private easternTime: EasternTimeDatePipe,
        private distributionPartnerRoleInfoService: DistributionPartnerRoleInfoService,
        private distributionPartnerRoleInfoAdapter: DistributionPartnerRoleInfoAdapter
    ) {
        super(session, router);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.fetchSuspendAutomaticPaymentsData();
        this.setupFormGroup();
    }

    setupFormGroup(): void {
        this.formGroup = this.formBuilder.newAreYouSureFormGroup();
    }

    onSubmit(): void {
        this.submitted = true;
        this.formUtil.touchAllFormControls(this.formGroup.group);
        if (this.formGroup.group.valid) {
            if (this.formGroup.selection === 'yes') {
                this.saveFormToFlow();
                this.submitApiRequest();
            } else if (this.formGroup.selection === 'no') {
                this.cancelSuspendEasy();
            }
        }
    }

    fetchSuspendAutomaticPaymentsData(): void {
        this.paymentPreferencesApiService.cancelAndSuspendAutomaticPaymentLanding().subscribe({
            next: (response) => {
                const { retrieveCustomerAgreement, automaticPayments, internetRegistrationResponse } = response;
                const billingAgreement = this.managePaymentPrefsAdapter.getBillingAgreement(retrieveCustomerAgreement);
                const billingAccountNumber = this.managePaymentPrefsAdapter.fetchBillingAccountNumber(retrieveCustomerAgreement);
                const amountDue = this.managePaymentPrefsAdapter.fetchBillingAccountAmountDue(retrieveCustomerAgreement);
                const amountDueDate = this.managePaymentPrefsAdapter.fetchBillingAccountAmountDueDate(retrieveCustomerAgreement);
                const nextDueDate = this.managePaymentPrefsAdapter.getNextPaymentDate(retrieveCustomerAgreement);
                const canPayNow = this.managePaymentPrefsAdapter.extractCanPayNow([billingAgreement], billingAccountNumber);
                this.paymentPlan = this.managePaymentPrefsAdapter.getPayPlanValueForEBI(retrieveCustomerAgreement);
                this.paymentPlan = this.managePaymentPrefsAdapter.getPayPlanValueForEBI(retrieveCustomerAgreement);
                this.emailAddress = this.managePaymentPrefsAdapter.fetchMostCurrentEmail(retrieveCustomerAgreement, internetRegistrationResponse);
                this.suspendAutomaticPaymentsLanding = new SuspendAutomaticPaymentsLanding(
                    billingAccountNumber,
                    amountDue,
                    amountDueDate,
                    nextDueDate,
                    canPayNow,
                    automaticPayments ? automaticPayments.recurringDraftDay : '',
                    this.paymentPlan,
                    this.emailAddress
                );
                this.pageLoadComplete = true;
            },
            error: (error) => {
                this.handleError(error);
                this.billingCommonNavigationsService.navigateToHardfall();
            },
            complete: () => this.sendOnPageLoadEbiEvent()
        });
    }

    saveFormToFlow(): void {
        this.suspendAutomaticPaymentsFlowService.save({
            suspendAutomaticPaymentsForm: this.formGroup,
            billingAccountNumber: this.suspendAutomaticPaymentsLanding.billingAccountNumber,
            amountDue: this.suspendAutomaticPaymentsLanding.amountDue,
            amountDueDate: this.suspendAutomaticPaymentsLanding.amountDueDate,
            nextDueDate: this.suspendAutomaticPaymentsLanding.nextDueDate,
            canPayNow: this.suspendAutomaticPaymentsLanding.canPayNow,
            paymentPlan: this.suspendAutomaticPaymentsLanding.paymentPlan,
            emailAddress: this.suspendAutomaticPaymentsLanding.email
        });
    }

    cancelSuspendEasy(): void {
        this.paymentPreferencesState.resetState();
        this.suspendAutomaticPaymentsFlowService.clear();
        this.billingCommonNavigationsService.navigateToPaymentPreferences();
        this.sendOnClickOfCancelButtonEbiEvent();
    }
    sendOnPageLoadEbiEvent(): void {
        this.eventService.handleEvent(EBIEventType.ON_PAGE_LOAD_OF_SUSPEND_EASY_PAY_LANDING_PAGE,
            this.getBillingEBIOptions());
    }

    sendOnClickOfCancelButtonEbiEvent(): void {
        this.eventService.handleEvent(EBIEventType.ON_CLICK_OF_CANCEL_IN_SUSPEND_EASY_PAY_LANDING_PAGE,
            this.getBillingEBIOptions());
    }

    mapDistributionPartnerRoleResponse(): void {
        this.distributionPartnerRoleInfoService.fetchDistributionPartnerRoleInfo().pipe(
            tap((response) => {
                this.distributionPartnerRoleInfoAdapter.mapDistributionPartnerRoleResponse(response);
            }),
            map((response) => ({
                distributionPartnerRoleInfoResponse: response
            })),
            catchError((err) => of(err))
        ).subscribe();
    }

    submitApiRequest(): void {
        this.mapDistributionPartnerRoleResponse();
        this.nextSub = this.isbExperience.suspendRecurring({
            accessToken: this.session.accessToken,
            billingSystem: this.session.billingSystem,
            agreementNumber: this.session.billingAccountNumber
        }).subscribe({
            next: (res) => {
                this.suspendAutomaticPaymentsFlowService.apiCallFailed = false;
                this.suspendAutomaticPaymentsFlowService.apiResponseCode = res ? `${res}` : '';
                this.sendSuspendAutomaticPaymentsEmail().subscribe();
                this.navigateToConfirmation();
            },
            error: (err: HttpErrorResponse) => {
                this.logger.error('API ERROR: SUSPEND AUTOMATIC PAYMENTS SUBMISSION', err);
                this.suspendAutomaticPaymentsFlowService.apiCallFailed = true;
                this.suspendAutomaticPaymentsFlowService.apiResponseCode = err.status ? `${err.status}` : '';
                this.navigateToConfirmation();
            }
        });
    }

    private sendSuspendAutomaticPaymentsEmail(): Observable<OmsResponse> {
        return this.isbExperience.sendSuspendAutomaticPaymentsEmail({
            accessToken: this.session.accessToken,
            billingSystem: this.session.billingSystem,
            updatedEmail: this.emailAddress,
            omsData: {
                omsServiceTransformationStrategyData: {
                    servicePlusIndicator: this.session.dpimServicePlusIndicator,
                    agentPhoneNumber: this.session.dpimAgentPhoneNumber
                }
            }
        }).pipe(
            catchError((err) => {
                this.logger.error('API ERROR: SUSPEND AUTOMATIC PAYMENTS EMAIL', err);
                return of(err);
            }));
    }

    navigateToConfirmation(): void {
        this.router.navigateByUrl(
            environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.AUTOMATIC_PAYMENTS.SUSPEND.CONFIRMATION(this.urlUtil.hashParamsString));
    }

    handleError(error: string): void {
        this.logger.error('HARDFALL: FETCH SUSPEND AUTOMATIC PAYMENTS DATA ERROR', { error });
        this.pageLoadComplete = false;
    }

    private getBillingEBIOptions(): BillingEBIOptions {
        return {
            paymentAmount: this.suspendAutomaticPaymentsLanding?.amountDue,
            firstName: this.session.firstName,
            lastName: this.session.lastName,
            paymentPlan: this.suspendAutomaticPaymentsLanding?.paymentPlan
        };
    }

    get displayMessageForForm(): string {
        return `Are you sure you want to stop your automatic payment scheduled for ${this.easternTime.transform(this.suspendAutomaticPaymentsLanding.amountDueDate, 'MM/dd/yyyy')} on your billing account ${this.suspendAutomaticPaymentsLanding.billingAccountNumber}?`;
    }

    get globalErrorDisplayable(): boolean {
        return !this.formGroup.group.valid && this.submitted;
    }
}
