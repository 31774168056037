import { Injectable } from '@angular/core';
import { SetupAutomaticPaymentsFlowService } from '../services/setup-automatic-payments-flow.service';
import { PaymentFormGroup } from '../../../../../../shared/payments/payment-form-group';
import { PaymentItem } from '../../../../../../billing/payment-preferences/shared/models/payment-item.model';
import { CtmEventCardTypeMap, CardType, CTMPaymentTypes } from '../../../../../../shared/payments/card-utils/card-type.enum';
import { SessionService } from '../../../../../../shared/session/session.service';
import { PaperlessEnrollmentBaseService } from '../../../../shared/services/paperless-enrollment-error.service';
import { BankCardPaymentItem } from '../../../../shared/models/bankcard-item.model';
import { BankAccountPaymentItem } from '../../../../shared/models/bankaccount-item.model';

@Injectable()
export class SetupAutomaticPaymentConfirmationService extends PaperlessEnrollmentBaseService {
    private UP_NEXT_TAB_LABELS = {
        PAPERLESS_ENROLLMENT: 'Paperless enrollment',
        PAYMENT_PREFERENCES: 'Payment preferences',
        MAKE_A_PAYMENT: 'Make a payment'
    };

    private MASKED_NUMBERS = {
        BANK_ACCOUNT: 3,
        BANK_CARD: 4
    };

    private _upNextLabel: string;
    constructor(private readonly setupPaymentFlow: SetupAutomaticPaymentsFlowService, protected readonly session: SessionService) {
        super(session);
    }

    setUpNextLabel(): void {
        if (this.setupPaymentMethodError) {
            this._upNextLabel = '';
        } else if (this.amountDue > 0 && this.isNbpUser) {
            this._upNextLabel = this.UP_NEXT_TAB_LABELS.MAKE_A_PAYMENT;
        } else {
            this._upNextLabel = '';
        }
    }

    fetchCtmPaymentMethod(paymentMethod: PaymentItem): CTMPaymentTypes {
        let output;
        if (paymentMethod.isMasterPass) {
            output = 'Masterpass';
        } else if (paymentMethod.isBankAccount) {
            output = 'Bank Account';
        } else if (paymentMethod.isBankCard) {
            if (paymentMethod.isNewMethod) {
                output = CtmEventCardTypeMap.get((<BankCardPaymentItem> this.setupPaymentFlow.selectedPayMethod.item).cardType);
            } else {
                output = CtmEventCardTypeMap.get(<CardType>paymentMethod.methodType);
            }
        } else {
            output = '';
        }

        return output;
    }

    get isNbpUser(): boolean {
        return this.session.sourceSystem === 'NBP';
    }

    get amountDue(): number {
        return Number(this.setupPaymentFlow.amountDue);
    }

    get amountDueDate(): Date {
        return this.setupPaymentFlow.currentBillDueDate;
    }
    get upNextLabel(): string {
        this.setUpNextLabel();
        return this._upNextLabel;
    }

    get navigateToPaperless(): boolean {
        return this.setupPaymentForm.enrollInPaperless === 'yes';
    }

    get setupPaymentForm(): PaymentFormGroup {
        return this.setupPaymentFlow.setUpAutomaticPaymentsForm;
    }

    get setupPaymentMethodError(): boolean {
        return !this.setupPaymentFlow.successfullyEnrolled;
    }

    get paymentType(): string {
        let paymentType;
        if (this.setupPaymentFlow.selectedPayMethod.isBankAccount) {
            paymentType = (<BankAccountPaymentItem> this.setupPaymentFlow.selectedPayMethod.item).accountType;
        } else if (this.setupPaymentFlow.selectedPayMethod.isBankCard) {
            paymentType = (<BankCardPaymentItem> this.setupPaymentFlow.selectedPayMethod.item).cardType;
        }
        return paymentType;
    }

    get maskedNumber(): string {
        let bankNumber;
        if (this.setupPaymentFlow.selectedPayMethod.isBankAccount) {
            bankNumber = (<BankAccountPaymentItem> this.setupPaymentFlow.selectedPayMethod.item).maskedBankAccountNumber;
            bankNumber = `*********${bankNumber}`;
        } else if (this.setupPaymentFlow.selectedPayMethod.isBankCard) {
            bankNumber = (<BankCardPaymentItem> this.setupPaymentFlow.selectedPayMethod.item).cvv;
            bankNumber = `************${bankNumber}`;
        }
        return bankNumber;
    }
}
