import { Injectable } from '@angular/core';
import { IsbExperience } from '@nationwide/dgs-angular-billing-common';
import { CardType, CardTypeShortHand } from '../../../../shared/payments/card-utils/card-type.enum';
import { BankAccountPaymentItem, BankAccountType } from '../models/bankaccount-item.model';
import { BankCardPaymentItem } from '../models/bankcard-item.model';
import { NewBankAccountMethod } from '../models/new-bank-account.model';
import { NewBankCardMethod } from '../models/new-bank-card.model';
import { PaymentItem } from '../models/payment-item.model';
import { NewMasterPassMethod } from '../models/new-masterpass.model';
import { BrowserUtilsService } from '@nationwide/internet-servicing-angular-services';
import { environment } from '../../../../../environments/environment';
import { BANK_ACCOUNT } from '../../../../shared/constants/common.constants';
import { WARNING_MESSAGES } from '../constants/warning-messages';
import { SavedPaymentMethods, PaymentMethodAccordion } from '../../view/saved-payment-methods/saved-payment-methods.model';
import { PAY_METHODS_STUB_UI } from '../../view/saved-payment-methods/stub-ui';
import { PlaceholderPaymentItem } from '../models/placeholder-item.model';
import { PaymentFormGroup } from '../../../../shared/payments/payment-form-group';
import { NewDebitCardMethod } from '../models/new-debit-card.model';
import { SavedPaymentMethod } from '@nationwide/dgs-angular-billing-common/api-response-types/internet-servicing-billing-experience';
import { TitleCasePipe } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class PaymentMethodsAdapter {
    private maxNumberOfPaymentMethods: number;

    constructor(
        private browserUtilService: BrowserUtilsService,
        private titleCasePipe: TitleCasePipe
    ) {
        this.maxNumberOfPaymentMethods = 20;
    }

    mapPaymentMethodsForPaymentPrefs(unmappedPayMethods: IsbExperience.SavedPaymentMethod[], filterCreditCards: boolean): SavedPaymentMethods {
        const mappedPaymentItems = this.mapSortPayMethods(unmappedPayMethods, { includePlaceholder: false, includeNewBankAccount: false, includeNewBankCard: false, filterCreditCards });
        let savedPaymentMethods: SavedPaymentMethods;

        if (this.retrievePayMethodErrorOccurred(unmappedPayMethods)) {
            savedPaymentMethods = SavedPaymentMethods.create({
                warnings: [WARNING_MESSAGES.API_TIMEOUT]
            });
        } else if (this.hasSavedPayMethods(mappedPaymentItems)) {
            const paymentAccordions = this.createPaymentAccordions(mappedPaymentItems);
            const canAddPayMethod = paymentAccordions.length < this.maxNumberOfPaymentMethods;

            savedPaymentMethods = SavedPaymentMethods.create({
                payMethodAccordions: paymentAccordions,
                canAddPayMethod
            });
        } else {
            savedPaymentMethods = PAY_METHODS_STUB_UI.NO_METHODS_ON_FILE;
        }

        return savedPaymentMethods;
    }

    createPaymentAccordions(mappedPayMethods: PaymentItem[]): PaymentMethodAccordion[] {
        return mappedPayMethods.map((payMethod) => PaymentMethodAccordion.create({
            header: payMethod.paymentPrefsDisplayName,
            payMethod,
            infoMessage: payMethod.recurringMethod ? WARNING_MESSAGES.REMOVE_EASYPAY_SAVED_PAYMENT_METHOD : ''
        })
        );
    }

    mapSortPayMethods(unmappedPayMethods: IsbExperience.SavedPaymentMethod[],
        { includeNewBankAccount = false, includeNewBankCard = false, includeNewDebitCard = false, includeMasterpass = false, alphabeticalSort = false, includePlaceholder = false, filterCreditCards = false }: Partial<MapSortPayMethodsOptions>
    ): PaymentItem[] {
        const methods = this.mapPaymentMethods(unmappedPayMethods, { includeNewBankAccount, includeNewBankCard, includeNewDebitCard, includePlaceholder, filterCreditCards, includeMasterpass });
        if (alphabeticalSort) {
            methods.sort(alphabetical);
        } else {
            methods.sort(byPaymentSelectionOrder);
        }
        return methods;
    }

    mapSinglePaymentMethod(unmappedPaymentMethod: IsbExperience.SavedPaymentMethod): PaymentItem {
        const mappedItem = this.mapPaymentMethods([unmappedPaymentMethod], { includeNewBankAccount: false, includeNewBankCard: false });

        return mappedItem && Array.isArray(mappedItem) && mappedItem.filter((paymentItem) => !paymentItem.isPlaceholder)[0];
    }

    mapPaymentMethods(unmappedPayMethods: IsbExperience.SavedPaymentMethod[], { includeNewBankAccount = false, includeNewBankCard = false, includeNewDebitCard = false, includeMasterpass = false, includePlaceholder = true, filterCreditCards = false }: Partial<MapPayMethodsOptions>): PaymentItem[] {
        const paymentMethodsList: PaymentItem[] = this.fetchNewPaymentMethodOptions({ includePlaceholder, includeNewBankAccount, includeNewBankCard, includeNewDebitCard, includeMasterpass });

        if (Array.isArray(unmappedPayMethods) && unmappedPayMethods.length > 0) {
            for (const paymentMethod of unmappedPayMethods) {
                if (filterCreditCards && paymentMethod.bankCard && paymentMethod.bankCard.cardType === 'Credit') {
                    continue;
                } else {
                    const id = this.extractMethodId(paymentMethod);
                    const paymentMethodType = paymentMethod.paymentMethodType;
                    // start - temporary mapping for payment methods not sent with name fields
                    if (!paymentMethod.payorInfo.lastName) {
                        const businessNamePieces = paymentMethod.payorInfo.firstName.split(' ');
                        paymentMethod.payorInfo.firstName = businessNamePieces[0];
                        businessNamePieces.shift();
                        paymentMethod.payorInfo.lastName = businessNamePieces.join(' ');
                    }
                    // end
                    if (paymentMethodType === 'ElectronicFundsTransfer.SavedPaymentMethod') {
                        paymentMethodsList.push(this.mapSavedPaymentMethodEFTToPaymentItem(paymentMethod, id));
                    } else if (paymentMethodType === 'BankCard.SavedPaymentMethod') {
                        paymentMethodsList.push(this.mapSavedPaymentMethodBankCardToPaymentItem(paymentMethod, id));
                    }
                }
            }
        }
        return paymentMethodsList;
    }

    extractMethodId(paymentMethod: IsbExperience.SavedPaymentMethod): any {
        const pathSegments = paymentMethod.href.split('/');
        const id = pathSegments[pathSegments.length - 1];
        return id;
    }

    retrieveBankAccountType(bankAccountType: string): BankAccountType {
        let output: BankAccountType;

        if (bankAccountType?.toLowerCase() === BANK_ACCOUNT.TYPE.CHECKING.toLowerCase() || bankAccountType?.toLowerCase() === BANK_ACCOUNT.TYPE.CHECKING_SHORTHAND.toLowerCase()) {
            output = <BankAccountType>BANK_ACCOUNT.TYPE.CHECKING;
        } else if (bankAccountType?.toLowerCase() === BANK_ACCOUNT.TYPE.SAVINGS.toLowerCase() || bankAccountType?.toLowerCase() === BANK_ACCOUNT.TYPE.SAVINGS_SHORTHAND.toLowerCase()) {
            output = <BankAccountType>BANK_ACCOUNT.TYPE.SAVINGS;
        } else {
            output = '';
        }

        return output;
    }

    retrieveBankCardType(bankCardType: string): CardType {
        let output: CardType;

        switch (bankCardType?.toLowerCase()) {
            case CardTypeShortHand.MASTERCARD.toLowerCase():
            case CardType.MASTERCARD.toLowerCase(): {
                output = CardType.MASTERCARD;
                break;
            }
            case CardTypeShortHand.VISA.toLowerCase():
            case CardType.VISA.toLowerCase(): {
                output = CardType.VISA;
                break;
            }
            case CardTypeShortHand.DISCOVER.toLowerCase():
            case CardType.DISCOVER.toLowerCase(): {
                output = CardType.DISCOVER;
                break;
            }
            case CardTypeShortHand.AMERICAN_EXPRESS.toLowerCase():
            case CardType.AMERICAN_EXPRESS_LONG_FORM.toLowerCase(): {
                output = CardType.AMERICAN_EXPRESS;
                break;
            }
            default: {
                output = CardType.INVALID;
            }
        }

        return output;
    }

    retrieveBankCardExpirationDate(bankCardExpirationDate: string): Date {
        const zeroBasedMonth = parseInt(bankCardExpirationDate.split('/')[0], 10) - 1;
        const year = parseInt(bankCardExpirationDate.split('/')[1], 10);
        return new Date(year, zeroBasedMonth);
    }

    convertPaymentFormGroupToPaymentItem(addRefundMethodFormGroup: PaymentFormGroup): PaymentItem {
        let paymentItem: PaymentItem;
        const lastThreeDigits = 3;
        const lastFourDigits = 4;
        if (addRefundMethodFormGroup.bankAccountNumber) {
            paymentItem = new PaymentItem(new BankAccountPaymentItem(
                addRefundMethodFormGroup.firstName,
                addRefundMethodFormGroup.lastName,
                addRefundMethodFormGroup.middleInitial,
                addRefundMethodFormGroup.street,
                addRefundMethodFormGroup.apartmentNumber,
                addRefundMethodFormGroup.city,
                addRefundMethodFormGroup.state,
                addRefundMethodFormGroup.zip,
                addRefundMethodFormGroup.bankName,
                <BankAccountType>addRefundMethodFormGroup.bankAccountType,
                addRefundMethodFormGroup.bankRoutingNumber,
                addRefundMethodFormGroup.bankAccountNumber.slice(addRefundMethodFormGroup.bankAccountNumber.length - lastThreeDigits),
                addRefundMethodFormGroup.bankAccountNumber
            ));
        } else if (addRefundMethodFormGroup.bankCardNumber) {
            const expirationDate = new Date(`${addRefundMethodFormGroup.cardExpirationMonth}/${addRefundMethodFormGroup.cardExpirationYear}`);
            paymentItem = new PaymentItem(new BankCardPaymentItem(
                addRefundMethodFormGroup.firstName,
                addRefundMethodFormGroup.lastName,
                addRefundMethodFormGroup.middleInitial,
                addRefundMethodFormGroup.bankCardCvv,
                addRefundMethodFormGroup.zip,
                addRefundMethodFormGroup.cardType,
                addRefundMethodFormGroup.bankCardNumber,
                expirationDate,
                addRefundMethodFormGroup.bankCardNumber.slice(
                    addRefundMethodFormGroup.bankAccountNumber.length - lastFourDigits
                )
            ));
        }
        return paymentItem;
    }

    mapSavedPaymentMethodEFTToPaymentItem({
        payorInfo,
        electronicFundsTransfer,
        preferredMethod,
        relatedPaymentMethodIDs,
        recurringMethod
    }: SavedPaymentMethod, id: string): PaymentItem {
        return new PaymentItem(new BankAccountPaymentItem(
            payorInfo.firstName?.trim(),
            payorInfo.lastName?.trim(),
            payorInfo.middleName || '',
            payorInfo.addressLine1,
            payorInfo.addressLine2 || '',
            payorInfo.city,
            payorInfo.state,
            payorInfo.postalCode,
            this.titleCasePipe.transform(electronicFundsTransfer.bankName),
            this.retrieveBankAccountType(electronicFundsTransfer.bankAccountType),
            electronicFundsTransfer.bankRoutingNumber,
            electronicFundsTransfer.maskedBankAccountNumber,
            electronicFundsTransfer.encryptedBankAccountNumber
        ),
            preferredMethod,
            id,
            relatedPaymentMethodIDs,
            recurringMethod
        );
    }

    mapSavedPaymentMethodBankCardToPaymentItem({
        payorInfo,
        bankCard,
        preferredMethod,
        relatedPaymentMethodIDs,
        recurringMethod
    }: SavedPaymentMethod, id: string): PaymentItem {
        return new PaymentItem(new BankCardPaymentItem(
            payorInfo.firstName?.trim(),
            payorInfo.lastName?.trim(),
            payorInfo.middleName || '',
            '',
            payorInfo.postalCode,
            this.retrieveBankCardType(bankCard.cardBrand),
            bankCard.ccLastFour,
            this.retrieveBankCardExpirationDate(bankCard.expirationDate),
            bankCard.ccLastFour,
            bankCard.cardType,
            bankCard.profileId
        ),
            preferredMethod,
            id,
            relatedPaymentMethodIDs,
            recurringMethod
        );
    }

    fetchNewPaymentMethodOptions({ includePlaceholder, includeNewBankAccount, includeNewBankCard, includeNewDebitCard, includeMasterpass }: NewPaymentMethodOptions): PaymentItem[] {
        const paymentMethodsList: PaymentItem[] = [];
        const browserType = this.browserUtilService.populateBrowserType(navigator.userAgent);

        if (includePlaceholder) {
            paymentMethodsList.push(new PaymentItem(new PlaceholderPaymentItem('Select an option')));
        }

        if (includeNewBankAccount) {
            paymentMethodsList.push(new PaymentItem(new NewBankAccountMethod()));
        }

        if (includeNewBankCard) {
            paymentMethodsList.push(new PaymentItem(new NewBankCardMethod()));
        }

        if (includeNewDebitCard) {
            paymentMethodsList.push(new PaymentItem(new NewDebitCardMethod()));
        }

        if (includeMasterpass && !(browserType === 'Internet Explorer' || browserType === 'Edge') && environment.MASTERPASS_ENABLED) {
            paymentMethodsList.push(new PaymentItem(new NewMasterPassMethod()));
        }

        return paymentMethodsList;
    }

    private retrievePayMethodErrorOccurred(unmappedPayMethods: IsbExperience.SavedPaymentMethod[]): boolean {
        return unmappedPayMethods === null;
    }

    private hasSavedPayMethods(unmappedPayMethods: PaymentItem[]): boolean {
        return unmappedPayMethods.length > 0;
    }
}

export function alphabetical(methodA: PaymentItem, methodB: PaymentItem): number {
    let sortNumber = 0;

    if (methodA.methodType < methodB.methodType) {
        sortNumber = -1;
    } else if (methodA.methodType > methodB.methodType) {
        sortNumber = 1;
    } else if (Number(methodA.displayNumbers) < Number(methodB.displayNumbers)) {
        sortNumber = -1;
    } else if (methodA.displayNumbers > methodB.displayNumbers) {
        sortNumber = 1;
    }

    return sortNumber;
}

export function byPaymentSelectionOrder(methodA: PaymentItem, methodB: PaymentItem): number {
    let sortNumber = 0;

    if (methodA.isMasterPass) {
        sortNumber = 1;
    } else if (methodB.isMasterPass) {
        sortNumber = -1;
    } else if (methodA.isDefault) {
        sortNumber = -1;
    } else if (methodB.isDefault) {
        sortNumber = 1;
    } else if (methodA.isBankAccount && methodB.isBankCard) {
        sortNumber = -1;
    } else if (methodA.isBankCard && methodB.isBankAccount) {
        sortNumber = 1;
    } else if (methodA.isBankCard && methodB.isBankCard && !methodA.isNewMethod && !methodB.isNewMethod) {
        sortNumber = sortBankCards(methodA, methodB);
    } else if (methodA.isBankAccount && methodB.isBankAccount && !methodA.isNewMethod && !methodB.isNewMethod) {
        sortNumber = sortBankAccounts(methodA, methodB);
    } else if (methodA.isNewBankAccount && methodB.isNewBankCard) {
        sortNumber = -1;
    } else if (methodA.isNewBankCard && methodB.isNewBankAccount) {
        sortNumber = 1;
    } else if (methodA.isNewMethod) {
        sortNumber = 1;
    } else if (methodB.isNewMethod) {
        sortNumber = -1;
    }

    return sortNumber;
}

export function sortBankCards(methodA: PaymentItem, methodB: PaymentItem): number {
    let sortNumber = 0;

    if (methodA.methodType < methodB.methodType) {
        sortNumber = -1;
    } else if (methodA.methodType > methodB.methodType) {
        sortNumber = 1;
    } else if (Number(methodA.displayNumbers) < Number(methodB.displayNumbers)) {
        sortNumber = -1;
    } else if (methodA.displayNumbers > methodB.displayNumbers) {
        sortNumber = 1;
    }

    return sortNumber;
}

export function sortBankAccounts(methodA: PaymentItem, methodB: PaymentItem): number {
    let sortNumber = 0;

    if (methodA.methodType === BANK_ACCOUNT.TYPE.CHECKING && methodB.methodType === BANK_ACCOUNT.TYPE.SAVINGS) {
        sortNumber = -1;
    } else if (methodA.methodType === BANK_ACCOUNT.TYPE.SAVINGS && methodB.methodType === BANK_ACCOUNT.TYPE.CHECKING) {
        sortNumber = 1;
    } else if (Number(methodA.displayNumbers) < Number(methodB.displayNumbers)) {
        sortNumber = -1;
    } else if (methodA.displayNumbers > methodB.displayNumbers) {
        sortNumber = 1;
    }

    return sortNumber;
}

export interface MapSortPayMethodsOptions extends MapPayMethodsOptions {
    alphabeticalSort: boolean;
}

export interface NewPaymentMethodOptions {
    includePlaceholder: boolean;
    includeNewBankAccount: boolean;
    includeNewBankCard: boolean;
    includeNewDebitCard: boolean;
    includeMasterpass: boolean;
}

interface MapPayMethodsOptions extends NewPaymentMethodOptions {
    filterCreditCards: boolean;
}
