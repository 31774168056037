import { Injectable } from '@angular/core';
import { QuickPayResponseModel } from '../../../../quick-pay/domain/quick-pay-response.model';
import { QuickPayResponse } from '@nationwide/dgs-angular-billing-common/api-response-types/portfolio-business-service';
import { BrowserUtilsService } from '@nationwide/internet-servicing-angular-services';
import { RoutingState } from '../../../../shared/service/routing-state.service';
import jwtDecode from 'jwt-decode';
import { SessionService } from '../../../../shared/session/session.service';
import { QuickPaySearchValues } from '../../../shared/services/quick-pay/quick-pay-search-values.model';
import { QUICK_PAY_CONSTANTS } from '../../../quick-pay.constants';
import { PrefillDashboardValuesModel } from '../../../../quick-pay/model/prefill-dashboard-values-model';
import { QuickPayPickerTileInfo } from '../../../../quick-pay/quick-pay-picker/quick-pay-picker-tile-info';
import dayjs from 'dayjs';

@Injectable()
export class QuickPayFlowService {
    searchType: 'agreement' | 'phone';
    agreementNumber: string;
    phoneNumber: string;
    zipCode: string;
    dob: string;
    responseCode: number;
    stateOfIssue: string;
    contextId: string;
    otp: string;
    guid: string;

    constructor(
        private browserUtilService: BrowserUtilsService,
        private routingState: RoutingState,
        private sessionService: SessionService
    ) { }

    get pbsResponse(): QuickPayResponse {
        return sessionStorage['pbsResponse'] && JSON.parse(sessionStorage['pbsResponse']);
    }

    set pbsResponse(model: QuickPayResponse) {
        sessionStorage['pbsResponse'] = JSON.stringify(model);
    }

    get isbProductIcons(): any {
        return sessionStorage['isbProductIcons'] && JSON.parse(sessionStorage['isbProductIcons']);
    }

    set isbProductIcons(contentFullIsbProductIcons: any) {
        sessionStorage['isbProductIcons'] = JSON.stringify(contentFullIsbProductIcons);
    }

    get quickPayResponseModel(): QuickPayResponseModel {
        return sessionStorage['quickPayResponseModel'] && new QuickPayResponseModel(JSON.parse(sessionStorage['quickPayResponseModel']));
    }

    set quickPayResponseModel(model: QuickPayResponseModel) {
        sessionStorage['quickPayResponseModel'] = JSON.stringify(model);
    }

    get billingSourceSystem(): string {
        return sessionStorage['billingAccountSourceSystem'] || sessionStorage['SOURCE_SYSTEM'] || sessionStorage['BILLING_SYSTEM'];
    }

    set billingSourceSystem(billingSourceSystem: string) {
        sessionStorage['billingAccountSourceSystem'] = billingSourceSystem;
        sessionStorage['SOURCE_SYSTEM'] = billingSourceSystem;
        sessionStorage['BILLING_SYSTEM'] = billingSourceSystem;
    }

    get selectedBillingAccount(): string {
        return sessionStorage.getItem('quickPayPostPaymentAgreementNumber') || sessionStorage.getItem('quickPayAgreementNumber');
    }

    set selectedBillingAccount(selectedBillingAccount: string) {
        sessionStorage['selectedBillingAccount'] = selectedBillingAccount;
        sessionStorage['quickPayAgreementNumber'] = selectedBillingAccount;
    }
    get quickPayPickerTileInfo(): QuickPayPickerTileInfo[] {
        return sessionStorage['quickPayPickerTileInfo'] && JSON.parse(sessionStorage['quickPayPickerTileInfo']);
    }

    set quickPayPickerTileInfo(quickPayPickerTileInfo: QuickPayPickerTileInfo[]) {
        sessionStorage['quickPayPickerTileInfo'] = JSON.stringify(quickPayPickerTileInfo);
    }

    get quickPayContentfulImages(): any {
        return sessionStorage['quickPayContentfulImages'] && JSON.parse(sessionStorage['quickPayContentfulImages']);
    }

    set quickPayContentfulImages(quickPayContentfulImages: any) {
        sessionStorage['quickPayContentfulImages'] = JSON.stringify(quickPayContentfulImages);
    }

    get payableAccountSize(): number {
        const quickPayPickerTileInfo = this.quickPayPickerTileInfo;
        return quickPayPickerTileInfo?.length;
    }

    getSearchFields(): QuickPaySearchFields {
        let output: QuickPaySearchFields = { zipCode: this.zipCode, dob: this.dob, identifier: '' };

        if (this.searchType === 'agreement') {
            output.agreementNumber = this.agreementNumber;
            output.identifier = this.agreementNumber;
            if (this.contextId && this.otp) {
                output.contextId = this.contextId;
                output.otp = this.otp;
            }
        } else if (this.searchType === 'phone') {
            output.phoneNumber = this.phoneNumber;
            output.identifier = this.phoneNumber;
            output.filter = 'quickPay';
        } else {
            output = null;
        }

        return output;
    }

    setupAgreementSearch(searchParams: AgreementSearchParams): void {
        this.agreementNumber = searchParams.agreementNumber;
        this.zipCode = searchParams.zipCode;
        this.dob = searchParams.dob;
        if (searchParams.contextId && searchParams.otp) {
            this.contextId = searchParams.contextId;
            this.otp = searchParams.otp;
        }
        this.searchType = 'agreement';
    }

    setupPhoneNumberSearch(phoneNumber: string, zipCode: string, dob: string): void {
        this.phoneNumber = phoneNumber;
        this.zipCode = zipCode;
        this.dob = dob;
        this.searchType = 'phone';
    }

    reset(): void {
        this.searchType = null;
        this.agreementNumber = null;
        this.phoneNumber = null;
        this.zipCode = null;
        this.dob = null;
    }

    get age(): number {
        return dayjs().diff(this.dob, 'years', false);
    }

    get isAgreementFlow(): boolean {
        return this.searchType === 'agreement';
    }

    get isPhoneNumberFlow(): boolean {
        return this.searchType === 'phone';
    }

    getQuickPayEcn(): string {
        return this.pbsResponse?.ecn || this.getEcnFromJWT() || '';
    }

    getEcnFromJWT(): string {
        if (sessionStorage.getItem('jsonWebToken')) {
            const jwt = sessionStorage.getItem('jsonWebToken');
            const decodedToken = <any>(jwtDecode(jwt));
            return decodedToken['sub'].split(':')[1];
        }
    }

    getPrefillLoggerData(description: string, prefillDashboardValuesModel: PrefillDashboardValuesModel): any {
        const userAgent = window.navigator.userAgent;
        const browserType = this.browserUtilService.populateBrowserType(userAgent);
        const operatingSystem = this.browserUtilService.populateOperatingSystem(userAgent);

        const ebiData = {
            BROWSER_TYPE: browserType,
            BROWSER_VERSION: this.browserUtilService.populateBrowserVersion(userAgent, browserType),
            CURRENT_URL: window.location.href,
            DESCRIPTION: description,
            DEVICE_TYPE: this.browserUtilService.populateDeviceAndOSVersion(userAgent, operatingSystem)['deviceType'],
            ECN: this.getQuickPayEcn() || 'Unknown',
            GUID: this.quickPayResponseModel?.guid || 'NA',
            IP_ADDRESS: this.sessionService.ipAddress || 'Unknown',
            IDENTIFIER: this.sessionService.billingAccountQuickPay || 'NA',
            JSESSION_ID: this.sessionService.a2aSessionId || 'NA',
            PREVIOUS_URL: this.routingState.getPreviousUrl() || 'NA',
            SOURCE_SYSTEM: this.sessionService.sourceSystem || 'NA',
            TEST_LEARN: 'B',
            USER_ACCESS_LVL: 'View'
        };
        const finalEbiData = prefillDashboardValuesModel ? this.buildExtraValuesPrefillDashboard(ebiData, prefillDashboardValuesModel) : ebiData;
        return finalEbiData;
    }

    buildExtraValuesPrefillDashboard(argEbiData: any, prefillDashboardValuesModel: PrefillDashboardValuesModel): any {
        const ebiData = argEbiData;
        const quickPaySearchValues: QuickPaySearchValues = this.sessionService.quickPaySearchValues;
        if (QUICK_PAY_CONSTANTS.PREFILL_DASHBOARD_AGREEMENT_NUMBER.equalsIgnoreCase(prefillDashboardValuesModel.inputValueIdentifier)) {
            ebiData['AGREEMENT_NUMBER'] = quickPaySearchValues.agreementNumber || prefillDashboardValuesModel.agreementNumber;
        } else if (QUICK_PAY_CONSTANTS.PREFILL_DASHBOARD_AGREEMENT_AND_QP_INPUT_VALUES.equalsIgnoreCase(prefillDashboardValuesModel.inputValueIdentifier)) {
            ebiData['AGREEMENT_NUMBER'] = quickPaySearchValues.agreementNumber;
            ebiData['DOB'] = quickPaySearchValues.dateOfBirth;
            ebiData['ZIP_CODE'] = quickPaySearchValues.zipCode;
            ebiData['ERROR_CODE'] = prefillDashboardValuesModel.errorCode;
        } else if (QUICK_PAY_CONSTANTS.PREFILL_DASHBOARD_MAKE_PAYMENT_COMPLETED_VALUES.equalsIgnoreCase(prefillDashboardValuesModel.inputValueIdentifier)) {
            ebiData['AMOUNT_PAID'] = prefillDashboardValuesModel.amountPaid;
            ebiData['CONFIRMATION_NUMBER'] = prefillDashboardValuesModel.confirmationNumber;
            ebiData['PAYMENT_METHOD'] = prefillDashboardValuesModel.paymentMethod;
            ebiData['SAVED_PAYMENT_METHOD_INDICATOR'] = prefillDashboardValuesModel.savedPayMethodIndicator;
        }
        return ebiData;
    }
}

export interface QuickPaySearchFields {
    agreementNumber?: string;
    phoneNumber?: string;
    identifier: string;
    zipCode: string;
    dob: string;
    contextId?: string;
    otp?: string;
    filter?: string;
}

export interface AgreementSearchParams {
    agreementNumber: string;
    zipCode: string;
    dob: string;
    contextId?: string;
    otp?: string;
}
