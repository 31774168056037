import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormGroupDirective } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentfulMapperService, IsbExperience } from '@nationwide/dgs-angular-billing-common';
import { EasternTimeDatePipe } from '@nationwide/internet-servicing-angular-pipes';
import { environment } from '../../../../../../../environments/environment';
import { PaymentItem } from '../../../../../../billing/payment-preferences/shared/models/payment-item.model';
import { FormUtil } from '../../../../../../shared/payments/form-util';
import { PaymentFormGroup } from '../../../../../../shared/payments/payment-form-group';
import { PaymentMethodsData } from '../../../../../../shared/service/billing-api-common.service';
import { BillingEventService } from '../../../../../../shared/service/ebi-service/billing-events.service';
import { EBIEventType } from '../../../../../../shared/service/ebi-service/ebi-model';
import { SessionService } from '../../../../../../shared/session/session.service';
import { UrlUtil } from '../../../../../../shared/url-util/url-util.service';
import { EasyPayAdapter } from '../../../../shared/adapters/easy-pay.adpater';
import { PaymentMethodsAdapter } from '../../../../shared/adapters/payment-methods.adapter';
import { PaymentPreferencesApiService } from '../../../../shared/services/payment-preferences-api/payment-preferences-api.service';
import { ManagePaymentPrefsFormBuilder } from '../../../base/manage-payment-prefs-form-builder';
import { ManagePaymentPrefsAdapter } from '../../../shared/adapters/manage-payment-prefs-adapter';
import { ManagePaymentPreferencesPageTitle } from '../../../shared/models/manage-payment-preferences-page-title.constant';
import { EditAutomaticPaymentFlowService } from '../services/edit-automatic-payments-flow-service';
import { BillingCommonComponent } from '../../../../../../billing/billing-common/billing-common.component';
import { CommonValuesPaymentFormGroupModel } from '../../../../../../billing/model/common-values-payment-form-group.model';
import { LoggerService } from '../../../../../../shared/logger/logger.service';
import { EasyPayAuthorizationAgreementService, EFTAuthorizationAgreementParams } from '../../../../../../shared/payments/ez-pay-authorization/easy-pay-authorization-agreement.service';
import { EftAuthorizationFlowService } from '../../../../../../shared/payments/ez-pay-authorization/eft-authorization-flow.service';
import { EftAuthorizationFormHelperService } from '../../../../../../shared/payments/ez-pay-authorization/eft-authorization-form-helper.service';
import { EftAuthorizationFormValidationService } from '../../../../../../shared/payments/ez-pay-authorization/eft-authorization-form-validation.service';
import { DistributedDocumentsResponseModel } from '../../../../../../shared/payments/ez-pay-authorization/eft-authorization-form/model/distributed-documents-response.model';
import { AccountPolicyHolderAddressElements } from './../../../../../../shared/payments/ez-pay-authorization/eft-authorization-form/form-elements/account-policy-holder-address-elements';
import { BankAccountPaymentItem } from '../../../../shared/models/bankaccount-item.model';
import { BankCardPaymentItem } from '../../../../shared/models/bankcard-item.model';

@Component({
    selector: 'app-edit-automatic-payments-landing',
    templateUrl: './edit-automatic-payments-landing.component.html',
    styleUrls: ['./edit-automatic-payments-landing.component.scss'],
    providers: []
})
export class EditAutomaticPaymentsLandingComponent extends BillingCommonComponent implements OnInit {
    @ViewChild(FormGroupDirective) editPaymentFormDirective: FormGroupDirective;
    declare accountValidationMessage: string;
    pageName = ManagePaymentPreferencesPageTitle.automaticPayments.edit.landing;
    loadComplete = false;
    apiCallInProgress = true;
    paymentItem: PaymentItem;
    editPaymentForm: PaymentFormGroup;
    automaticPayments: IsbExperience.GetPayPlanResponse;
    emailAddress: string;
    isPaperlessEligible: boolean;
    paymentMethod: PaymentItem;
    chosenPaymentMethod: PaymentItem;
    billingAccountNumber: string;
    billingAccountHolderName: string;
    amountDue: string;
    nextBillDueDate: Date;
    currentBillDueDate: Date;
    formValidated = false;
    automaticPaymentSuspended: boolean;
    isEFTBilled: boolean;
    isRBCBilled: boolean;
    isBilledStatus: boolean;
    billingStreet1: string;
    billingCity: string;
    billingState: string;
    NBP: boolean;
    isLifeOnlyAccount: boolean;
    declare distributedDocumentsResponseModel: DistributedDocumentsResponseModel;
    declare commonValuesPaymentFormGroupModel: CommonValuesPaymentFormGroupModel;
    canPayNow: boolean;
    esddaCheckBoxChecked = false;
    paperlessEsddaValidationError = false;
    hasCreditCardVulPolicyError = false;
    vulErrorMessage = 'Credit cards cannot be used for variable life policies. Please use a different payment method.';
    bankCardAutomaticPaymentsCapMessage: string;
    isAutoPayment = true;
    paymentPlan: string;
    isFullPay: boolean;

    // eslint-disable-next-line max-params
    constructor(
        router: Router,
        private activatedRoute: ActivatedRoute,
        session: SessionService,
        private urlUtil: UrlUtil,
        private easyPayAdapter: EasyPayAdapter,
        private paymentPrefsApiService: PaymentPreferencesApiService,
        private editPaymentFormBuilder: ManagePaymentPrefsFormBuilder,
        private paymentMethodsAdapter: PaymentMethodsAdapter,
        private formUtils: FormUtil,
        private easternTime: EasternTimeDatePipe,
        eventService: BillingEventService,
        @Inject('window') private window: Window,
        private managePaymentPrefsAdapter: ManagePaymentPrefsAdapter,
        private editPaymentFlow: EditAutomaticPaymentFlowService,
        private easyPayAuthorizationAgreementService: EasyPayAuthorizationAgreementService,
        eftAuthorizationFormHelperService: EftAuthorizationFormHelperService,
        private eftAuthorizationFormValidationService: EftAuthorizationFormValidationService,
        eftAuthorizationFlowService: EftAuthorizationFlowService,
        readonly contentfulService: ContentfulMapperService,
        @Inject('logger') private logger: LoggerService
    ) {
        super(session, router, eftAuthorizationFormHelperService, eventService, eftAuthorizationFlowService);
    }

    ngOnInit(): void {
        this.session.currentPage = this.pageName;
        this.setVariablesFromApiResponse();
        this.fetchBankCardAutomaticPaymentsCapMessage();
    }

    setVariablesFromApiResponse(): void {
        try {
            this.nextSub = this.paymentPrefsApiService.editAutomaticPaymentLanding({
                callContentful: this.editPaymentFlow.hasCreditCardVulPolicyError
            }).subscribe((response) => {
                const { retrieveCustomerAgreement, internetRegistrationResponse, automaticPayments, contentful } = response;
                const billingAgreement = this.easyPayAdapter.getBillingAgreementFromRCA(this.easyPayAdapter.getAgreementsFromRCA(retrieveCustomerAgreement));
                this.automaticPayments = automaticPayments;
                this.NBP = this.session.isNBP;
                this.isLifeOnlyAccount = this.managePaymentPrefsAdapter.hasLifeOnlyBusiness(retrieveCustomerAgreement);
                this.isBilledStatus = this.easyPayAdapter.getBilledStatus(billingAgreement);
                this.isEFTBilled = this.easyPayAdapter.getEFTBilledStatus(billingAgreement);
                this.isRBCBilled = this.easyPayAdapter.getRBCBilledStatus(billingAgreement);
                this.amountDue = this.managePaymentPrefsAdapter.fetchBillingAccountAmountDue(retrieveCustomerAgreement);
                this.nextBillDueDate = this.easyPayAdapter.nextDueDate(retrieveCustomerAgreement) ? new Date(this.easyPayAdapter.nextDueDate(retrieveCustomerAgreement)) : null;
                this.currentBillDueDate = this.managePaymentPrefsAdapter.fetchBillingAccountAmountDueDate(retrieveCustomerAgreement);
                this.billingAccountHolderName = this.easyPayAdapter.getFullName(automaticPayments);
                this.billingAccountNumber = this.managePaymentPrefsAdapter.fetchBillingAccountNumber(retrieveCustomerAgreement);
                this.isPaperlessEligible = this.managePaymentPrefsAdapter.isEligibleForPaperless(internetRegistrationResponse);
                this.emailAddress = this.managePaymentPrefsAdapter.fetchMostCurrentEmail(retrieveCustomerAgreement, internetRegistrationResponse);
                this.paymentItem = this.easyPayAdapter.getPaymentItem(automaticPayments);
                this.automaticPaymentSuspended = this.easyPayAdapter.isRecurringPaymentSuspended(retrieveCustomerAgreement);
                const address: AccountPolicyHolderAddressElements = this.managePaymentPrefsAdapter.addressFromInternetRegistrationResponse(internetRegistrationResponse);
                this.billingStreet1 = address?.AcctPolHolderAddr1;
                this.billingCity = address?.AcctPolHolderCity;
                this.billingState = address?.AcctPolHolderState;
                this.paymentPlan = this.managePaymentPrefsAdapter.getPayPlanValueForEBI(retrieveCustomerAgreement);
                this.canPayNow = this.managePaymentPrefsAdapter.canPay(billingAgreement.roleInAgreement.permission);
                this.isFullPay = this.managePaymentPrefsAdapter.isAccountFullPay(retrieveCustomerAgreement);
                this.paymentMethod = this.convertToPaymentMethod(automaticPayments);
                this.initSelectedPayMethod();
                this.setupEditPaymentForm();

                this.handleVulError();
                this.loadComplete = true;
                this.apiCallInProgress = false;
                this.sendOnPageLoadEbiEvent();
            });
        } catch (error) {
            this.logger.error('API ERROR: EDIT AUTOMATIC PAYMENTS LANDING PAGE', error);
        }
    }
    navigateToReview(): void {
        this.router.navigateByUrl(
            environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.AUTOMATIC_PAYMENTS.EDIT.REVIEW(
                this.removeParamFromUrl(this.urlUtil.hashParamsString, 'review')
            )
        );
    }

    onEsddaCheckboxClick(value): void {
        this.esddaCheckBoxChecked = value;
        if (this.esddaCheckBoxChecked) {
            this.paperlessEsddaValidationError = false;
        }
    }

    getEmailAddress(): string {
        if (this.editPaymentForm.enrollInPaperless === 'no') {
            this.editPaymentFlow.paperlessEmail = this.emailAddress;
        }
        return this.editPaymentFlow.paperlessEmail || this.emailAddress;
    }

    removeParamFromUrl(urlParameters: string, parameterName: string): string {
        return urlParameters.includes(parameterName) ?
            urlParameters.replace('&fromPage=review', '') :
            urlParameters;
    }

    setupEditPaymentForm(): void {
        const fromPage: string = this.activatedRoute.snapshot.queryParams['fromPage'];
        if (this.editPaymentFlow.flowInPlay ||
            this.editPaymentFlow.hasCreditCardVulPolicyError && fromPage && fromPage === 'review') {
            this.editPaymentForm = this.editPaymentFormBuilder.setupEasyPayReconstructFormGroup({
                oldForm: this.editPaymentFlow.editAutomaticPaymentForm.group
            });
        } else {
            this.editPaymentForm = this.editPaymentFormBuilder.newAutomaticPaymentFormGroup();
        }
        this.editPaymentFlow.editAutomaticPaymentForm = this.editPaymentForm;
    }

    onContinue(): void {
        this.formUtils.touchAllFormControls(this.editPaymentForm.group);
        this.formValidated = !!this.isAccountValidationReturnErrorCode;
        this.setDefaultPaymentMethodSelectionError(this.selectedPaymentMethod);
        if (this.isEsddaValidationFailure()) {
            this.paperlessEsddaValidationError = this.isEsddaValidationFailure();
            if (this.paperlessEsddaValidationError) {
                this.logger.info('Edit Automatic Payments Page - Failed page validation due to ESDDA after continue button is clicked', {
                    accountNumber: this.session.billingAccountNumber,
                    enterpriseCustomerNumber: this.session.ecn,
                    paperlessBillingDeliveryResponse: this.editPaymentForm.enrollInPaperless
                });
            }
        }
        if (this.editPaymentForm.group.valid && !this.paperlessEsddaValidationError) {
            this.sendOnPaymentMethodEditEbiEvent();
            this.determineNextAction();
        } else {
            this.window.scroll(0, 0);
        }
    }

    isEsddaValidationFailure(): boolean {
        return this.editPaymentForm.enrollInPaperless === 'yes' && !this.esddaCheckBoxChecked;
    }

    saveFormToFlow(): void {
        this.editPaymentFlow.save({
            editAutomaticPaymentForm: this.editPaymentForm,
            billedStatus: this.isEFTBilled || this.isRBCBilled || this.isBilledStatus,
            considerThisDisplayable: this.considerThisDisplayable,
            isPaperlessEligible: this.isPaperlessEligible,
            selectedPaperless: this.editPaymentForm.enrollInPaperless,
            emailAddress: this.emailAddress,
            selectedPayMethod: this.chosenPaymentMethod,
            billingAccountHolderName: this.billingAccountHolderName,
            billingAccountNumber: this.billingAccountNumber,
            amountDue: this.amountDue,
            currentBillDueDate: this.currentBillDueDate,
            nextBillDueDate: this.nextBillDueDate,
            currentBillingMethodType: this.automaticPayments.billingMethodType,
            automaticPaymentSuspended: this.automaticPaymentSuspended,
            billingStreet1: this.billingStreet1,
            billingCity: this.billingCity,
            billingState: this.billingState,
            distributedDocumentsResponseModel: this.distributedDocumentsResponseModel,
            authorizationAgreementParams: this.authorizationAgreementParams,
            isLifeOnlyAccount: this.isLifeOnlyAccount,
            canPayNow: this.canPayNow,
            hasCreditCardVulPolicyError: false,
            paymentPlan: this.paymentPlan
        });
    }

    initSelectedPayMethod(): void {
        this.chosenPaymentMethod = new PaymentItem(this.paymentMethod.item);
    }

    cancelEditPayment(): void {
        this.router.navigateByUrl(
            environment.ISB.ENDPOINTS.MANAGE_PAYMENT_PREFERENCES.paymentPreferences(this.urlUtil.hashParamsString)
        );
    }

    handleVulError(): void {
        this.hasCreditCardVulPolicyError = this.editPaymentFlow.hasCreditCardVulPolicyError || this.hasCreditCardVulPolicyError;
        this.vulErrorMessage = this.editPaymentFlow.errorDetails?.errorMessage || this.vulErrorMessage;
    }

    sendOnPageLoadEbiEvent(): void {
        this.eventService.handleEvent(EBIEventType.ON_LOAD_EDIT_AUTOMATIC_PAYMENTS_LANDING_PAGE, {
            paymentMethod: this.ebiPaymentMethodData,
            paymentPlan: this.paymentPlan || this.editPaymentFlow.paymentPlan
        });
    }

    sendOnPaymentMethodEditEbiEvent(): void {
        this.eventService.handleEvent(EBIEventType.ON_EDIT_OF_PAYMENT_METHOD_ON_AUTOMATIC_PAYMENT_LANDING_PAGE, {
            paymentMethod: this.ebiPaymentMethodData,
            paymentPlan: this.paymentPlan || this.editPaymentFlow.paymentPlan
        });
    }

    convertToPaymentMethod(automaticPayments: IsbExperience.GetPayPlanResponse): PaymentItem {
        const paymentMethod = automaticPayments.paymentMethod;
        const paymentMethodType = automaticPayments.paymentMethod.paymentMethodType;
        const lastFourDigits = 4;
        if (paymentMethodType === 'ElectronicFundsTransfer.PaymentMethod') {
            return new PaymentItem(new BankAccountPaymentItem(
                paymentMethod.payorInfo.firstName,
                paymentMethod.payorInfo.lastName,
                paymentMethod.payorInfo.middleName,
                '',
                '',
                '',
                '',
                '',
                paymentMethod.electronicFundsTransfer.bankName,
                this.paymentMethodsAdapter.retrieveBankAccountType(paymentMethod.electronicFundsTransfer.bankAccountType),
                paymentMethod.electronicFundsTransfer.bankRoutingNumber,
                paymentMethod.electronicFundsTransfer.bankAccountNumber.slice(paymentMethod.electronicFundsTransfer.bankAccountNumber.length - lastFourDigits),
                ''
            )
            );
        } else if (paymentMethodType === 'BankCard.PaymentMethod') {
            return new PaymentItem(new BankCardPaymentItem(
                paymentMethod.payorInfo.firstName,
                paymentMethod.payorInfo.lastName,
                paymentMethod.payorInfo.middleName,
                paymentMethod.bankCard.cvvCode,
                paymentMethod.payorInfo.postalCode,
                this.paymentMethodsAdapter.retrieveBankCardType(paymentMethod.bankCard.cardBrand),
                paymentMethod.bankCard.ccLastFour,
                this.paymentMethodsAdapter.retrieveBankCardExpirationDate(paymentMethod.bankCard.expirationDate),
                paymentMethod.bankCard.ccLastFour
            )
            );
        }
    }

    determineNextAction(): void {
        this.commonValuesPaymentFormGroupModel = new CommonValuesPaymentFormGroupModel(this.editPaymentForm, this.selectedPaymentMethod, this.billingAccountHolderName);
        this.setAuthorizationAgreementParams(false);
        if (this.selectedPaymentMethod?.isPlaceholder) {
            this.isGlobalErrorMessageReqDefaultMethod = true;
        } else if (this.selectedPaymentMethod?.isBankAccount && this.NBP) {
            this.fetchEFTAuthForms();
        } else {
            this.saveFormToFlow();
            this.navigateToReview();
        }
    }

    fetchEFTAuthForms(): void {
        this.apiCallInProgress = true;
        this.nextSub = this.easyPayAuthorizationAgreementService.fetchEFTAuthFormsForNewlyEnrolled(
            <EFTAuthorizationAgreementParams> this.authorizationAgreementParams,
            this.agencyInformationResponseModel).subscribe({
                next: (response) => {
                    this.apiCallInProgress = false;
                    this.distributedDocumentsResponseModel = response;
                    if (this.eftAuthorizationFormValidationService.validateDistributedDocumentResponse(this.distributedDocumentsResponseModel)) {
                        this.setHPExtremeServiceResponseStatus(this.distributedDocumentsResponseModel.returnCode, false);
                        this.saveFormToFlow();
                        this.triggerEbiEventToLogNumberOfTimesHPExtremeServiceInvoke();
                        this.navigateToReview();
                    } else {
                        this.setEFTAuthFormValidationValues();
                        this.sendIncompleteFormElementsOnEFTAuthFormEbiEvent();
                    }
                },
                error: (error: HttpErrorResponse) => {
                    this.apiCallInProgress = false;
                    this.logger.error('EFT Auth Form Failure - Edit Automatic Payments', error);
                    this.logger.logCurrentContents();
                    this.setHPExtremeServiceResponseStatus(this.getStausFromErrorResponse(error), true);
                    this.triggerEbiEventToLogNumberOfTimesHPExtremeServiceInvoke();
                    this.setEFTAuthFormValidationValues();
                }
            });
    }

    validateAgentInformation(): void {
        this.easyPayAuthorizationAgreementService.fetchAgencyInformation().subscribe({
            next: (response) => {
                this.agencyInformationResponseModel = response;
                if (!this.eftAuthorizationFormValidationService.validateAgentDetails(this.agencyInformationResponseModel)) {
                    this.setEFTAuthFormValidationValues();
                    this.sendIncompleteFormElementsOnEFTAuthFormEbiEvent();
                }
            },
            error: (error) => {
                this.logger.error('Validate Agent Information Failure - Edit Automatic Payments', error);
                this.logger.logCurrentContents();
                this.eftAuthorizationFormHelperService.buildEBIValueAgentInformationServiceDown();
                this.sendIncompleteFormElementsOnEFTAuthFormEbiEvent();
                this.setEFTAuthFormValidationValues();
            }
        });
    }

    retrieveBankCardType(cardName: string): string {
        return this.paymentMethodsAdapter.retrieveBankCardType(cardName);
    }

    get ebiPaymentMethodData(): PaymentMethodsData[] {
        const data = [];
        const payMethod = this.selectedPaymentMethod;
        const payPlan = this.automaticPayments;
        const accountType = payMethod.isBankAccount || !payMethod.isNewMethod && (payMethod.item as BankAccountPaymentItem).accountType ? (payMethod.item as BankAccountPaymentItem).accountType : '';
        const bankName = (payMethod.item as BankAccountPaymentItem).bankName || '';
        const creditCardVendor = payMethod.isBankCard || !payMethod.isNewMethod && this.automaticPayments.paymentMethod?.bankCard ? (payMethod.item as BankCardPaymentItem).cardType || this.retrieveBankCardType(payPlan.paymentMethod.bankCard.cardBrand) || this.automaticPayments.paymentMethod.bankCard.cardBrand : '';
        const paymentType = payMethod.isBankCard ? 'CC' : 'EFT';
        const paymentDate = this.billingPaymentDate;
        const nextPaymentAmount = this.amountDue;
        const stateEbi = this.editPaymentForm.state;
        data.push({
            accountType,
            bankName,
            creditCardVendor,
            paymentType,
            paymentDate,
            nextPaymentAmount,
            stateEbi
        });

        return data;
    }

    get billingPaymentDate(): string {
        return this.nextBillDueDate ? this.easternTime.transform(this.nextBillDueDate, 'MM/dd/yyyy') : 'NA';
    }

    get selectedPaymentMethod(): PaymentItem {
        return this.chosenPaymentMethod;
    }

    set selectedPaymentMethod(paymentMethod: PaymentItem) {
        this.chosenPaymentMethod = paymentMethod;
        if (this.selectedPaymentMethod?.isBankAccount && this.NBP) {
            this.validateAgentInformation();
        }
    }

    get globalErrorDisplayable(): boolean {
        return !this.formValidated && !this.editPaymentForm.group.valid &&
            this.submitted || this.isGlobalErrorMessageReqDefaultMethod;
    }

    get submitted(): boolean {
        return this.editPaymentFormDirective && this.editPaymentFormDirective.submitted;
    }

    get considerThisGroup(): AbstractControl {
        return this.editPaymentForm && this.editPaymentForm.group.get('considerThisGroup');
    }

    get considerThisDisplayable(): boolean {
        return this.isPaperlessEligible;
    }

    private fetchBankCardAutomaticPaymentsCapMessage(): void {
        const contentfulResponse = this.contentfulService.getContentfulBillingContent();
        contentfulResponse.subscribe((res) => {
            this.bankCardAutomaticPaymentsCapMessage = this.session.isSAP ? res.sapbankCardAutomaticPaymentsCapMessage.content : res.bankCardAutomaticPaymentsCapMessage.content;
        });
    }

    private hasDefaultMethod(paymentMethods: PaymentItem[]): boolean {
        return !!paymentMethods.find((method) => method.isDefault);
    }

    private hasSavedPaymentMethod(paymentMethods: PaymentItem[]): boolean {
        return !!paymentMethods.find((method) => !method.isNewMethod && !method.isPlaceholder);
    }
}
