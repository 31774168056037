import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SessionService } from '../session/session.service';
import { AbstractMetricsLogger } from '@nationwide/dgs-angular-billing-common';
import { LoggingLevel, LoggingJSON, SplunkService } from '@nationwide/client-logging';
import { BrowserUtilsService } from '@nationwide/internet-servicing-angular-services';
import { PaymentFlowType } from '../../pay-bill/shared/payment-flow-type.service';

@Injectable()
export class LoggerService extends AbstractMetricsLogger {
    clientLoggingEnabled = environment.LOGGING.clientLoggingEnabled;
    pushLength = environment.LOGGING.pushLength;
    clientId = environment.LOGGING.clientId;
    endpoint = environment.LOGGING.endpoint;
    splunkToken = environment.LOGGING.splunkToken;
    loggingJson = <LoggingJSON>environment.LOGGING.loggingJson;
    loggingLevel = <LoggingLevel>environment.LOGGING.loggingLevel;
    appName = environment.LOGGING.appName;
    envName = environment.LOGGING.envName;
    logToConsole = !environment.PRODUCTION;
    maxLogLength = environment.LOGGING.maxLogLength;
    retryDelay = environment.LOGGING.retryDelay;
    maxRetryAttempts = environment.LOGGING.maxRetryAttempts;
    retryLogCurrentContentsTime = environment.LOGGING.retryLogCurrentContentsTime;

    // eslint-disable-next-line max-params
    constructor(
        splunkService: SplunkService,
        private browserUtilsService: BrowserUtilsService,
        protected sessionService: SessionService,
        private paymentFlowType: PaymentFlowType
    ) {
        super(splunkService, sessionService);
    }

    debug(statement: string, json: object = {}): void {
        super.debug(statement, json, this.defaultParameters());
    }

    info(statement: string, json: object = {}): void {
        super.info(statement, json, this.defaultParameters());
    }

    error(statement: string, json: object = {}): void {
        super.error(statement, json, this.defaultParameters());
    }

    event(statement: string, json: object = {}): void {
        super.info(statement, json, this.defaultParameters({ type: 'event' }));
        super.logCurrentContents();
    }

    warn(statement: string, json: object = {}): void {
        super.warn(statement, json, this.defaultParameters());
    }

    trace(statement: string, json: object = {}): void {
        super.trace(statement, json, this.defaultParameters());
    }

    generateUniqueId(): void {
        const oneThousand = 1000;
        this.sessionService.sessionUniqueId = `${new Date().toISOString()}-${Math.floor(Math.random() * oneThousand)}`;
    }

    getAuthToken(): string {
        return this.sessionService.accessToken;
    }

    getUser(): string {
        const userId = this.sessionService.userId;
        return userId || 'UNAVAILABLE';
    }

    getSessionId(): string {
        return this.sessionService.a2aSessionId;
    }

    private defaultParameters(additionalParameters?): any {
        additionalParameters = additionalParameters || {};
        return {
            accountNumber: this.getDataOrUnavailable(this.sessionService.billingAccountNumber),
            baseState: this.getDataOrUnavailable(this.sessionService.state),
            billingSystem: this.getDataOrUnavailable(this.sessionService.billingSystem),
            browserInformation: this.getBrowserInformation(),
            channel: this.getFlowName(),
            ecn: this.getDataOrUnavailable(this.sessionService.ecn),
            entryPoint: 'UNAVAILABLE',
            guid: this.getDataOrUnavailable(this.sessionService.guid),
            hasLifePolicy: this.getDataOrUnavailable(this.sessionService.hasLifePolicy),
            ipAddress: this.getDataOrUnavailable(this.sessionService.ipAddress),
            isBrowserBackAfterLogOut: this.getDataOrUnavailable(this.sessionService.isBrowserBackAfterLogOut),
            isCyberLifePolicyQuickPayFlow: this.getDataOrUnavailable(this.sessionService.isCyberLifePolicyQuickPayFlow),
            isLifeBillingNavigationFlow: this.getDataOrUnavailable(this.sessionService.isLifeBillingNavigationFlow),
            isPayMidTermChange: this.getDataOrUnavailable(this.sessionService.isPayMidTermChange),
            page: this.getDataOrUnavailable(this.sessionService.referringPageUrl),
            pageName: this.getDataOrUnavailable(this.sessionService.currentPage),
            policyHolderName: this.getDataOrUnavailable(this.sessionService.fullName),
            policyNumber: this.getDataOrUnavailable(this.sessionService.policyNumber),
            previousPage: this.getDataOrUnavailable(this.sessionService.referringPage),
            productType: this.getDataOrUnavailable(this.sessionService.productType),
            sessionUniqueId: this.getDataOrUnavailable(this.sessionService.sessionUniqueId),
            userAccessLevel: 'UNAVAILABLE',
            userName: this.getDataOrUnavailable(this.sessionService.userId),
            ...additionalParameters
        };
    }

    private getBrowserInformation(): string {
        if (window?.navigator) {
            const userAgent = window.navigator.userAgent;
            const browserType = userAgent ? this.browserUtilsService.populateBrowserType(userAgent) : '';
            const osType = userAgent ? this.browserUtilsService.populateOperatingSystem(userAgent) : '';
            const deviceTypeAndOSVersion = this.browserUtilsService.populateDeviceAndOSVersion(userAgent, osType);
            return `${deviceTypeAndOSVersion?.['deviceType']} ${browserType}`;
        }
        return 'userAgent not available';
    }

    private getFlowName(): string {
        if (this.paymentFlowType.isQuickpay) {
            return 'Quick Pay';
        } else {
            return 'Authenticated Member';
        }
    }

    private getDataOrUnavailable(data: any): any {
        if (data == null || data === '') {
            return 'UNAVAILABLE';
        } else {
            return data;
        }
    }
}
