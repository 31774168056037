import { Injectable } from '@angular/core';
import { PaymentItem } from '../../../../billing/payment-preferences/shared/models/payment-item.model';
import { PaymentFormGroup } from '../../../../shared/payments/payment-form-group';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { PaymentFormControls } from '../../../../shared/payments/payment-form-controls';
import { AreYouSureFormGroup } from '../../../../shared/payments/are-you-sure-radio-buttons/are-you-sure-form-group';
@Injectable()
export class ManagePaymentPrefsFormBuilder {
    constructor(
        private formBuilder: UntypedFormBuilder,
        private paymentFormControls: PaymentFormControls
    ) { }

    reconstructFormGroup(
        params: ManagePaymentFormParams & { oldForm?: UntypedFormGroup }
    ): PaymentFormGroup {
        const previousValues = params.oldForm && params.oldForm.value || {};
        const group = this.formBuilder.group({
            paymentMethodGroup: params.selectedPayMethod.isNewMethod ?
                this.paymentFormControls.newPaymentPreferencesPaymentMethodGroup(
                    params.selectedPayMethod,
                    previousValues.paymentMethodGroup,
                    params.hasSavedPayments
                ) :
                this.paymentFormControls.savedPaymentPreferencesPaymentMethodGroup(
                    params.selectedPayMethod,
                    previousValues.paymentMethodGroup
                ),
            considerThisGroup: this.paymentFormControls.newConsiderThisFormGroup(
                previousValues.considerThisGroup
            )
        });

        return new PaymentFormGroup(group);
    }

    newPaymentFormGroup({
        selectedPayMethod,
        hasSavedPayments
    }: ManagePaymentFormParams): PaymentFormGroup {
        const group = this.formBuilder.group({
            paymentMethodGroup: selectedPayMethod.isNewMethod ?
                this.paymentFormControls.newPaymentPreferencesPaymentMethodGroup(
                    selectedPayMethod,
                    {
                        makeDefaultPaymentMethod: false
                    },
                    hasSavedPayments
                ) :
                this.paymentFormControls.savedPaymentPreferencesPaymentMethodGroup(
                    selectedPayMethod,
                    {
                        makeDefaultPaymentMethod: false
                    }
                ),
            considerThisGroup: this.paymentFormControls.newConsiderThisFormGroup()
        });
        return new PaymentFormGroup(group);
    }

    newAutomaticPaymentFormGroup(): PaymentFormGroup {
        const group = this.formBuilder.group({
            considerThisGroup: this.paymentFormControls.newConsiderThisFormGroup()
        });
        return new PaymentFormGroup(group);
    }

    newPaymentPreferenceFormGroup({
        selectedPayMethod,
        hasSavedPayments
    }: ManagePaymentFormParams): PaymentFormGroup {
        const isEasyPayFlow = true;
        const group = this.formBuilder.group({
            paymentMethodGroup: this.paymentFormControls.newPaymentPreferencesPaymentMethodGroup(
                selectedPayMethod,
                {
                    makeDefaultPaymentMethod: false
                },
                hasSavedPayments,
                isEasyPayFlow
            ),
            considerThisGroup: this.paymentFormControls.newConsiderThisFormGroup(),
            ezPayAuthGroup: this.paymentFormControls.newEzPayAuthGroup()
        });
        return new PaymentFormGroup(group);
    }

    setupEasyPayReconstructFormGroup(
        params: ManagePaymentFormParams & { oldForm?: UntypedFormGroup }
    ): PaymentFormGroup {
        const previousValues = params.oldForm && params.oldForm.value || {};
        const group = this.formBuilder.group({
            considerThisGroup: this.paymentFormControls.newConsiderThisFormGroup(
                previousValues.considerThisGroup
            )
        });

        return new PaymentFormGroup(group);
    }

    newAreYouSureFormGroup(): AreYouSureFormGroup {
        const group = this.formBuilder.group({
            areYouSureFormGroup: this.paymentFormControls.newAreYouSureFormGroup()
        });

        return new AreYouSureFormGroup(group);
    }

    newConsiderThisFormGroup(): PaymentFormGroup {
        const group = this.formBuilder.group({
            considerThisGroup: this.paymentFormControls.newConsiderThisFormGroup()
        });
        return new PaymentFormGroup(group);
    }

    newReconstructFormGroup(
        params: ManagePaymentFormParams & { oldForm?: UntypedFormGroup }
    ): PaymentFormGroup {
        const previousValues = params.oldForm && params.oldForm.value || {};
        const group = this.formBuilder.group({
            considerThisGroup: this.paymentFormControls.newConsiderThisFormGroup(
                previousValues.considerThisGroup
            )
        });

        return new PaymentFormGroup(group);
    }
}

export interface ManagePaymentFormParams {
    selectedPayMethod?: PaymentItem;
    hasSavedPayments?: boolean;
}