import { Inject, Injectable } from '@angular/core';
import { IsbExperienceService, isbExperienceServiceToken, MoneyProcessingService, PayorInfo } from '@nationwide/dgs-angular-billing-common';
import { BankCard } from '@nationwide/dgs-angular-billing-common/services/internet-servicing-billing-experience/isb-personal-lines-billing-request-params';
import { catchError, forkJoin, map, mergeMap, Observable, of, tap, timeout } from 'rxjs';
import { environment } from '../../../../../../../environments/environment';
import { DistributionPartnerRoleInfoAdapter } from '../../../../../../billing/shared/distribution-partner-role-info/distribution-partner-role-info.adapter';
import { DistributionPartnerRoleInfoService } from '../../../../../../billing/shared/distribution-partner-role-info/distribution-partner-role-info.service';
import { PreferenceCenterPaperlesService } from '../../../../../../pay-bill/shared/preference-center-paperless.service';
import { LoggerService } from '../../../../../../shared/logger/logger.service';
import { submitPaymetCardTypeMap } from '../../../../../../shared/payments/card-utils/card-type.enum';
import { SessionService } from '../../../../../../shared/session/session.service';
import { PaperlessEnrollmentBaseService } from '../../../../shared/services/paperless-enrollment-error.service';
import { SetupAutomaticPaymentsFlowService } from '../services/setup-automatic-payments-flow.service';
import { SetupAutomaticPaymentsReview } from './setup-automatic-payments-review.model';
import { BankCardPaymentItem } from '../../../../shared/models/bankcard-item.model';
@Injectable()
export class SetupAutomaticPaymentsReviewService extends PaperlessEnrollmentBaseService {
    // eslint-disable-next-line max-params
    constructor(
        @Inject(isbExperienceServiceToken) private isbExperience: IsbExperienceService,
        private moneyProcessing: MoneyProcessingService,
        protected sessionService: SessionService,
        private setupAutomaticPaymentFlowService: SetupAutomaticPaymentsFlowService,
        private readonly setupAutomaticPaymentReview: SetupAutomaticPaymentsReview,
        private distributionPartnerRoleInfoService: DistributionPartnerRoleInfoService,
        private distributionPartnerRoleInfoAdapter: DistributionPartnerRoleInfoAdapter,
        @Inject('logger') private readonly logger: LoggerService,
        @Inject(PreferenceCenterPaperlesService) private preferencePaperlesService: PreferenceCenterPaperlesService
        // @Inject(PreferenceCenterAdapter) private preferenceCenterAdapter: PreferenceCenterAdapter
    ) {
        super(sessionService);
    }

    createAutomaticPaymentReviewManager(): Observable<SetupPaymentResponse> {
        return this.requestPlaceHolder().pipe(
            mergeMap(() =>
                forkJoin({
                    createAutomaticPaymentMethod: this.createAutomaticPaymentMethod(),
                    distributionPartnerRoleInfoResponse: this.distributionPartnerRoleInfoService.fetchDistributionPartnerRoleInfo()
                })
            ),
            tap((responses) => {
                this.distributionPartnerRoleInfoAdapter.mapDistributionPartnerRoleResponse(responses.distributionPartnerRoleInfoResponse);
            }),
            mergeMap((previousCalls) =>
                forkJoin({
                    createAutomaticPaymentMethod: of(previousCalls.createAutomaticPaymentMethod),
                    nbpEmailSentSuccessfully: this.sessionService.billingSystem === 'NBP' ? this.sendNbpEmail() : this.requestPlaceHolder(),
                    esddaPreferences: this.setupAutomaticPaymentReview.enrollingInPaperless === 'Yes' || this.setupAutomaticPaymentReview.enrollingInPaperless === 'No' ? this.enrollInPaperless() : this.requestPlaceHolder()
                })
            )
        );
    }

    enrollInPaperless(): Observable<boolean> {
        this.setupAutomaticPaymentReview.save({ paperlessEnrollmentAttempted: true });
        return this.preferencePaperlesService.saveEsddaPreferences(this.setupAutomaticPaymentReview.emailAddress, this.setupAutomaticPaymentReview.enrollingInPaperless).pipe(
            tap((response) => {
                if (response) {
                    this.setupAutomaticPaymentReview.save({
                        savedPaperlessEsddaPrefs: true, enrolledInPaperless: true,
                        setupPaymentResponse: {
                            esddaPreferences: true
                        }
                    });
                } else {
                    this.setupAutomaticPaymentReview.save({
                        savedPaperlessEsddaPrefs: false, enrolledInPaperless: false,
                        setupPaymentResponse: {
                            esddaPreferences: false
                        }
                    });
                }
            }),
            catchError(() => of(false))
        );
    }

    createAutomaticPaymentMethod(): Observable<number> {
        const {
            isBankAccountFlow,
            bankName,
            routingNumber,
            bankAccountNumber,
            bankAccountType,
            maskedBankAccountNumber,
            isBankCardFlow,
            billingCycleDueDate,
            isNewPayMethod,
            paymentMethodId
        } = this.setupAutomaticPaymentReview;

        return this.billingService.patchPayPlan({
            billingSystem: this.sessionService.billingSystem,
            accessToken: this.sessionService.accessToken,
            agreementNumber: this.sessionService.billingAccountNumber,
            payload: {
                billingPayload: {
                    accountDueDate: billingCycleDueDate,
                    action: 'add',
                    requestType: 'BillingMethodChange.PayPlanUpdate',
                    enterpriseCustomerNumber: this.sessionService.ecn,
                    currentBillingMethodType: 'Direct',
                    newBillingMethodType: isBankAccountFlow ? 'Recurring EFT' : 'Recurring Bankcard',
                    paymentMethodId: paymentMethodId || undefined,
                    paymentMethod: {
                        preferredMethod: this.setupAutomaticPaymentFlowService.selectedPayMethod.isDefault,
                        description: 'TEST',
                        payorInfo: this.sessionService.billingSystem === 'SAP Billing' || this.setupAutomaticPaymentReview.isBankAccountFlow ? undefined : this.payorInfo,
                        paymentMethodType: isBankAccountFlow ? 'ElectronicFundsTransfer.PaymentMethod' : 'BankCard.PaymentMethod',
                        electronicFundsTransfer: isBankAccountFlow ? {
                            bankName,
                            bankRoutingNumber: routingNumber,
                            bankAccountNumber,
                            bankAccountType,
                            maskedBankAccountNumber: isNewPayMethod ? maskedBankAccountNumber : undefined
                        } : undefined,
                        bankCard: isBankCardFlow ? this.getBankCard() : undefined
                    }
                }
            }
        }).pipe(
            tap(() => this.setupAutomaticPaymentFlowService.save({ successfullyEnrolled: true })),
            timeout(environment.TIMEOUT.apiCallDuration.submitPayment),
            catchError((error) => {
                this.setupAutomaticPaymentFlowService.save({ successfullyEnrolled: false, errorDetails: { errorMessage: error.error?.developerMessage, errorCode: error.status } });
                this.logger.error('API ERROR: SETUP AUTOMATIC PAYMENTS SUBMISSION', error);
                return of(error.status);
            })
        );
    }

    sendNbpEmail(): Observable<boolean> {
        let response: Observable<boolean>;

        if (this.setupAutomaticPaymentFlowService.successfullyEnrolled) {
            response = this.isbExperience.sendEnrollAutomaticPaymentsEmail({
                accessToken: this.sessionService.accessToken,
                billingSystem: this.sessionService.billingSystem,
                omsData: {
                    omsServiceTransformationStrategyData: {
                        servicePlusIndicator: this.sessionService.dpimServicePlusIndicator,
                        agentPhoneNumber: this.sessionService.dpimAgentPhoneNumber
                    }
                }
            }).pipe(
                map(() => true),
                catchError(() => of(false))
            );
        } else {
            response = this.requestPlaceHolder();
        }

        return response;
    }

    private getBankCard(): BankCard {
        const bankCard: BankCard = {
            encryptedPan: undefined,
            encryptedPanKey: undefined,
            encryptedPanPubKeyFingerPrint: undefined,
            expirationDate: this.fetchExpirationDate(this.setupAutomaticPaymentReview.cardExpirationYear, this.setupAutomaticPaymentReview.cardExpirationMonth),
            ccLastFour: (<BankCardPaymentItem> this.setupAutomaticPaymentFlowService.selectedPayMethod.item).cardNumberLastFour,
            cardBrand: <'MasterCard' | 'American Express' | 'Visa' | 'Discover'>submitPaymetCardTypeMap.get(this.setupAutomaticPaymentReview.cardType)
        };
        if (this.sessionService.billingSystem === 'NBP') {
            bankCard.profileId = (<BankCardPaymentItem> this.setupAutomaticPaymentFlowService.selectedPayMethod.item).profileId;
            bankCard.encryptedPan = '';
        }
        return bankCard;
    }

    private requestPlaceHolder(): Observable<null> {
        return of(null);
    }

    private fetchExpirationDate(cardExpirationYear: string, cardExpirationMonth: string): string {
        return this.sessionService.billingSystem === 'SAP Billing' ?
            `${cardExpirationYear}${cardExpirationMonth}` : `${cardExpirationYear}-${cardExpirationMonth}`;
    }

    private get billingService(): IsbExperienceService | MoneyProcessingService {
        let service: IsbExperienceService | MoneyProcessingService;
        if (this.sessionService.billingSystem === 'NBP') {
            service = this.moneyProcessing;
        } else {
            service = this.isbExperience;
        }
        return service;
    }

    private get payorInfo(): PayorInfo {
        return {
            firstName: this.setupAutomaticPaymentReview.firstName,
            middleName: this.setupAutomaticPaymentReview.middleInitial,
            lastName: this.setupAutomaticPaymentReview.lastName,
            fullName: this.setupAutomaticPaymentReview.payorName,
            addressLine1: this.setupAutomaticPaymentReview.street,
            addressLine2: this.setupAutomaticPaymentReview.apartmentNumber,
            city: this.setupAutomaticPaymentReview.city,
            state: this.setupAutomaticPaymentReview.stateName,
            postalCode: this.setupAutomaticPaymentReview.zip
        };
    }
}

export interface SetupPaymentResponse {
    createAutomaticPaymentMethod?: number;
    updateEmailAddress?: boolean | null;
    nbpEmailSentSuccessfully?: boolean | null;
    esddaPreferences?: boolean | null;
}
