import { Injectable } from '@angular/core';
import { ReplaySubject, Observable } from 'rxjs';
import { PaymentPreferencesCachedApiCalls } from './payment-preferences-cached-api-calls.model';
import { PersonalBilling, ECIM, IsbExperience } from '@nationwide/dgs-angular-billing-common';
import { SessionService } from '../../../../../shared/session/session.service';
@Injectable({
    providedIn: 'root'
})
export class PaymentPreferencesStateService {
    private eligibility;
    private cachedApiResponses: PaymentPreferencesCachedApiCalls;
    private _autoCheckPaperless = false;
    private _pageTitle = new ReplaySubject<string>();

    constructor(
        private session: SessionService
    ) {
        this.initializeState();
    }

    getSingleSavedPaymentMethod(paymentMethodId: string): IsbExperience.SavedPaymentMethod {
        let paymentMethod: IsbExperience.SavedPaymentMethod;

        if (Array.isArray(this.cachedApiResponses.moneyProcessing.savedPaymentMethods)) {
            this.cachedApiResponses.moneyProcessing.savedPaymentMethods.forEach((cachedMethod) => {
                if (this.getPayMethodIdFromUrl(cachedMethod.href) === paymentMethodId) {
                    paymentMethod = cachedMethod;
                }
            });
        }

        return paymentMethod;
    }

    updateSingleSavedPaymentMethod(updatedMethod: IsbExperience.SavedPaymentMethod): void {
        const updatedMethodId = this.getPayMethodIdFromUrl(updatedMethod.href);

        if (this.getSingleSavedPaymentMethod(updatedMethodId)) {
            const index = this.cachedApiResponses.moneyProcessing.savedPaymentMethods.findIndex((cachedMethod) =>
                this.getPayMethodIdFromUrl(cachedMethod.href) === updatedMethodId
            );

            this.cachedApiResponses.moneyProcessing.savedPaymentMethods[index] = updatedMethod;
        } else {
            if (!Array.isArray(this.cachedApiResponses.moneyProcessing.savedPaymentMethods)) {
                this.cachedApiResponses.moneyProcessing.savedPaymentMethods = [];
            }
            this.cachedApiResponses.moneyProcessing.savedPaymentMethods.push(updatedMethod);
        }
    }

    setPageTitle(newPageTitle: string): void {
        this._pageTitle.next(newPageTitle);
    }

    currentPageTitle(): Observable<string> {
        return this._pageTitle.asObservable();
    }

    resetState(): void {
        this.initializeState();
    }

    private initializeState(): void {
        this.eligibility = {
            refundMethod: true,
            automaticPayments: true
        };

        this.cachedApiResponses = {
            moneyProcessing: {
                savedPaymentMethods: null
            },
            personalBilling: {
                automaticPayments: null,
                refundMethod: null,
                paymentsHistory: null,
                bills: null,
                relatedPolicies: null,
                eligiblePayPlan: null
            },
            ecim: {
                internetRegistrationResponse: null,
                agreementResponse: null
            }
        };
    }

    set currentPaymentMethodId(methodId: string) {
        this.session.manageSavedPaymentMethodsId = methodId;
    }

    get currentPaymentMethodId(): string {
        return this.session.manageSavedPaymentMethodsId;
    }

    set eligibleForRefundMethod(value: boolean) {
        this.eligibility.refundMethod = value;
    }

    get eligibleForRefundMethod(): boolean {
        return this.eligibility.refundMethod;
    }

    set eligibleForAutomaticPayments(value: boolean) {
        this.eligibility.automaticPayments = value;
    }

    get eligibleForAutomaticPayments(): boolean {
        return this.eligibility.automaticPayments;
    }

    set autoCheckPaperless(value: boolean) {
        this._autoCheckPaperless = value;
    }

    get autoCheckPaperless(): boolean {
        return this._autoCheckPaperless;
    }

    get savedPaymentMethods(): IsbExperience.SavedPaymentMethod[] {
        return this.cachedApiResponses.moneyProcessing.savedPaymentMethods;
    }

    set savedPaymentMethods(value: IsbExperience.SavedPaymentMethod[]) {
        this.cachedApiResponses.moneyProcessing.savedPaymentMethods = value;
    }

    get internetRegistrationResponse(): ECIM.EnterpriseCustomerInformationManagementResponse {
        return this.cachedApiResponses.ecim.internetRegistrationResponse;
    }

    set internetRegistrationResponse(value: ECIM.EnterpriseCustomerInformationManagementResponse) {
        this.cachedApiResponses.ecim.internetRegistrationResponse = value;
    }

    get agreementResponse(): ECIM.Agreement {
        return this.cachedApiResponses.ecim.agreementResponse;
    }

    set agreementResponse(value: ECIM.Agreement) {
        this.cachedApiResponses.ecim.agreementResponse = value;
    }

    get automaticPayments(): IsbExperience.GetPayPlanResponse {
        return this.cachedApiResponses.personalBilling.automaticPayments;
    }

    set automaticPayments(value: IsbExperience.GetPayPlanResponse) {
        this.cachedApiResponses.personalBilling.automaticPayments = value;
    }

    get paymentsHistory(): IsbExperience.RetrievePaymentsResponse {
        return this.cachedApiResponses.personalBilling.paymentsHistory;
    }

    set paymentsHistory(value: IsbExperience.RetrievePaymentsResponse) {
        this.cachedApiResponses.personalBilling.paymentsHistory = value;
    }

    get refundMethod(): PersonalBilling.RefundMethod {
        return this.cachedApiResponses.personalBilling.refundMethod;
    }

    set refundMethod(value: PersonalBilling.RefundMethod) {
        this.cachedApiResponses.personalBilling.refundMethod = value;
    }

    get payPlanEligible(): IsbExperience.PayPlanEligibleResponse {
        return this.cachedApiResponses.personalBilling.payPlanEligible;
    }

    set payPlanEligible(value: IsbExperience.PayPlanEligibleResponse) {
        this.cachedApiResponses.personalBilling.payPlanEligible = value;
    }

    get calculateDownPayment(): IsbExperience.CalculateDownPaymentResponse {
        return this.cachedApiResponses.personalBilling.calculateDownPayment;
    }

    set calculateDownPayment(value: IsbExperience.CalculateDownPaymentResponse) {
        this.cachedApiResponses.personalBilling.calculateDownPayment = value;
    }

    get bills(): PersonalBilling.Bill[] {
        return this.cachedApiResponses.personalBilling.bills;
    }

    set bills(value: PersonalBilling.Bill[]) {
        this.cachedApiResponses.personalBilling.bills = value;
    }

    get relatedPolicies(): PersonalBilling.Policy[] {
        return this.cachedApiResponses.personalBilling.relatedPolicies;
    }

    set relatedPolicies(value: PersonalBilling.Policy[]) {
        this.cachedApiResponses.personalBilling.relatedPolicies = value;
    }

    get eligiblePayPlan(): any {
        return this.cachedApiResponses.personalBilling.eligiblePayPlan;
    }

    set eligiblePayPlan(value: any) {
        this.cachedApiResponses.personalBilling.eligiblePayPlan = value;
    }

    private getPayMethodIdFromUrl(url: string): string {
        if (url) {
            const urlSegments = url.split('/');
            if (urlSegments && Array.isArray(urlSegments) && urlSegments.length > 1) {
                return urlSegments[urlSegments.length - 1];
            }
        }
    }
}
